<template>
  <div class="homework">
    <div class="mt-3 mx-4">
      <TitleHomework :help-link="$t('url.helps.homeworkList')" />
    </div>
    <div class="container-fluid">
      <div class="row justify-content-center mb-4">
        <div class="col-auto">
          <ButtonBorderCircleMedium
            :label-name="$t('buttons.createHomework')"
            color="layout-theme-light"
            :label-color="colorLayoutTheme"
            :border-color="colorLayoutTheme"
            @click.native="createHomework"
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col">
          <div v-if="homeworkItems.length > 0">
            <TableHomework
              :homework-list="homeworkItems"
              @delete-homework="deleteHomework"
            />
          </div>
        </div>
      </div>
    </div>
    <LoadingScreen v-if="isLoading" />

    <ModalConfirmOkOnly
      v-if="showComfirm"
      :message="comfirmMessage"
      @close-confirm-modal-ok-only="showComfirm = false"
    />

    <ModalConfirmOkOnly
      v-if="sessionDisconnect"
      :message="$t('messages.error.overLimitSessionToken')"
      @close-confirm-modal-ok-only="onSessionDisconnect()"
    />
  </div>
</template>

<script>
/**
 * 宿題管理 - 宿題一覧
 */
import { db } from "@/dbs/indexedDb"
import { mapMutations } from "vuex"
import mixin from "../mixins/mixin"
import TitleHomework from "@/components/molecules/TitleHomework.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import TableHomework from "@/components/organisms/TableHomework.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import homeworkRepository from "../repositories/homework"
import { apiResponseCode } from "../constant/network"

export default {
  name: "Homework",
  components: {
    TitleHomework,
    ButtonBorderCircleMedium,
    TableHomework,
    ModalConfirmOkOnly,
    LoadingScreen,
  },

  mixins: [mixin],
  props: {
    deleteParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  data: () => ({
    colorLayoutTheme: "#ff7f27",
    // 宿題一覧
    homeworkItems: [],
    showComfirm: false,
    comfirmMessage: "",
    isLoading: false,
  }),

  async mounted() {
    // store をクリアする
    this.clearHeaderInfo()
    this.clearHomeworkDetailItems()
    // this.clearHomeworkFileHistoryItems()
    this.clearHomeworkSetItems()

    // セッションチェック
    await this.checkSession()

    // 宿題一覧を取得
    await this.getHomeworkList()

    await this.init()

    // 宿題作成から遷移した際にエラーの元となる宿題を削除する
    if (Object.keys(this.deleteParams).length) {
      await this.deleteHomework(
        this.deleteParams.schoolId,
        this.deleteParams.homeworkKey
      )
    }
  },

  methods: {
    /**
     * 初期処理
     */
    async init() {
      await this.onInitCheckGroups()
    },
    /**
     * セッションが切れた際のログアウト処理
     */
    async onSessionDisconnect() {
      await this.setSessionDisconnectFalse()
      await this.clearSessionInfo()
      await this.clearSelectedStreamGroupMemberItems()
      await this.$router.push({ name: "Login" })
    },
    ...mapMutations("homework", [
      "clearHeaderInfo",
      "clearHomeworkDetailItems",
      "clearHomeworkFileHistoryItems",
      "clearHomeworkSetItems",
      "deleteCheckedStudentList",
    ]),

    /**
     * 配信先の選択済リストを初期化
     */
    async clearSelectedStreamGroupMemberItems() {
      await db.selectedStreamGroupMember.put({
        id: 0,
        groupMemberItems: [],
        isStreamMySelf: false,
      })
    },

    /**
     * 宿題一覧を取得する
     */
    async getHomeworkList() {
      this.isLoading = true
      try {
        const data = await homeworkRepository.getHomeworkList(
          this.loginUserInfo.accountId,
          this.loginUserInfo.schoolId,
          this.loginUserInfo.lmsApiToken
        )
        this.homeworkItems = data
      } catch (error) {
        if (error.status === apiResponseCode.internalServerError) {
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    /**
     * 宿題の新規作成
     */
    createHomework: function () {
      if (
        homeworkRepository.isLimitHomeworkCount(
          this.homeworkItems,
          this.paramCreateHomeworkMaximumLimitItems
        )
      ) {
        this.showConfirmDialog(this.$t("messages.error.overLimitHomeworkCount"))
        return
      }
      this.$router.push({ name: "HomeworkRegister" })
    },
    /**
     * 宿題を1件削除する
     * @param {Number} homeworkKey 宿題キー
     * @param {String} schoolId 学校 ID
     */
    async deleteHomework(schoolId, homeworkKey) {
      this.isLoading = true
      // セッション期間が有効かチェックする
      await this.checkSession()

      await homeworkRepository
        .deleteHomework(
          this.loginUserInfo.accountId,
          schoolId,
          homeworkKey,
          this.loginUserInfo.lmsApiToken
        )
        .then(() => {
          // 処理が成功している場合は、response.data は null

          // Storeに保持している進捗確認情報も削除
          this.deleteCheckedStudentList(homeworkKey)

          // 宿題一覧を再表示
          this.getHomeworkList()
        })
        .catch((error) => {
          this.isLoading = false
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        })
    },
    /**
     * 確認ダイアログを表示する
     */
    showConfirmDialog: function (message) {
      this.comfirmMessage = message
      this.showComfirm = true
    },
    /**
     * グループが削除されている宿題を自動で削除する
     */
    async onInitCheckGroups() {
      let deleteItems = []
      this.homeworkItems.map((item) => {
        const groupCheck = !this.loginUserInfo.groupIds.some(
          (group) => group.groupId === item.groupId
        )
        const statusCheck = item.status === 2 || item.status === 3
        if (groupCheck && statusCheck) {
          deleteItems.push({
            schoolId: item.schoolId,
            homeworkKey: item.homeworkKey,
          })
        }
      })
      if (deleteItems.length > 0) {
        deleteItems.map(async (item) => {
          await this.deleteHomework(item.schoolId, item.homeworkKey)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
