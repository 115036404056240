<template>
  <div class="table-homework-progress-students">
    <table>
      <thead>
        <tr>
          <template v-for="(headerArray, arrayIndex) in setHeaderItems">
            <th
              v-for="(header, index) in headerArray"
              :key="`header-${arrayIndex}-${index}`"
              class="col"
              :class="{
                first: index === 0,
                last: index === headerArray.length - 1,
              }"
            >
              <div class="text-center pre-line">
                {{ header }}
              </div>
            </th>
          </template>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, index) in viewItems"
          :key="index"
          class="text-center"
        >
          <!-- 行番号 -->
          <!-- <th>{{ index + 1 }}</th> -->
          <td
            v-if="showStudentName"
            class="studentName text-center"
          >
            {{ item.accountName }}
          </td>
          <td
            v-else
            class="studentName text-center"
          >
            {{ item.anonymousAccountName }}
          </td>
          <td class="status py-1">
            <BadgeStatus
              v-if="item.isInGroup"
              :label-text="item.statusDisplayName"
              :color="item.statusColor"
            />
          </td>
          <!-- スタンプ画像のサイズは固定 -->
          <td class="stamp">
            <img
              v-if="item.isInGroup"
              :src="
                item.stamp === null
                  ? gradeStamps[gradeStamps.length - 1].stamp
                  : gradeStamps[item.stamp - 1].stamp
              "
              width="50"
            >
          </td>
          <!-- 回答率 -->
          <td class="response-rate">
            <div
              v-if="item.isInGroup"
              class="d-flex justify-content-end"
            >
              {{ !item.responseRate ? 0 : item.responseRate }}
              <div class="align-self-end percent">
                %
              </div>
            </div>
          </td>
          <!-- 正答率（／回答） -->
          <td class="correct-answer-rate">
            <div
              v-if="item.isInGroup"
              class="d-flex justify-content-end"
            >
              {{ !item.responseCorrectRate ? 0 : item.responseCorrectRate }}
              <div class="align-self-end percent">
                %
              </div>
            </div>
          </td>
          <!-- 正答率（／全体） -->
          <td class="response-correct-rate">
            <div
              v-if="item.isInGroup"
              class="d-flex justify-content-end"
            >
              {{ !item.correctAnswerRate ? 0 : item.correctAnswerRate }}
              <div class="align-self-end percent">
                %
              </div>
            </div>
          </td>
          <!-- 確認ボタン -->
          <td class="check">
            <div v-if="item.isInGroup">
              <div
                v-if="
                  item.status === handedStatus.notProgress ||
                    item.status === handedStatus.progressing ||
                    item.status === handedStatus.waitHand
                "
              />
              <!-- 「提出済」以降のステータスでボタンを表示する -->
              <div
                v-else-if="item.status === handedStatus.handed"
                @click="checkStudentData(index)"
              >
                <ButtonProgressUnconfirm />
              </div>
              <div
                v-else
                @click="checkStudentData(index)"
              >
                <ButtonProgress />
              </div>
            </div>
          </td>
          <!-- 子問題を集計して正誤記号表示 -->
          <template v-for="(resultArray, arrayIndex) in item.results">
            <td
              v-for="(result, childIndex) in resultArray"
              :key="`${arrayIndex}-${childIndex}`"
              class="col"
              :class="[
                {
                  first: childIndex === 0,
                  last: childIndex === resultArray.length - 1,
                },
                !result || result.disableFlg
                  ? 'child-question bg-disable'
                  : 'child-question bg-layout-theme-light',
              ]"
            >
              <div v-if="result && !result.disableFlg">
                <div v-if="result.result === correctResult.notAnswer">
                  <IconDash width="25px" />
                </div>
                <div v-else-if="result.result === correctResult.correct">
                  <IconCircle
                    width="25px"
                    :color-style="correctColor"
                  />
                </div>
                <div v-else-if="result.result === correctResult.incorrect">
                  <IconClose
                    width="25px"
                    :color-style="incorrectColor"
                  />
                </div>
              </div>
              <div v-else />
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <ModalStudentHandedData
      v-if="showStudentDataModal"
      :student-data="viewItems"
      :stamps="gradeStamps"
      :student-num="studentNum"
      :submit-index-list="submitIndexList"
      :show-student-name="showStudentName"
      :teacher-comment="teacherComment"
      :haishin-start-date="haishinStartDate"
      :homework-details="homeworkDetails"
      :ques-image-list.sync="quesImageList"
      :config-page-teacher.sync="configPageTeacher"
      :cond-teacher.sync="condTeacher"
      v-on="{
        close: closeModalStudentHandedData,
        'on-click-return-of': onClickReturnOf,
        'on-click-send-back': onClickSendBack,
        'on-click-student-data-transfer': checkStudentData,
        'on-change-show-student-name': ($event) => {
          $emit('on-change-show-student-name', $event)
        },
        'on-reload': onReload,
      }"
    />
  </div>
</template>

<script>
import mixin from "../../mixins/mixin"
import { mapGetters } from "vuex"
import BadgeStatus from "@/components/atoms/BadgeStatus.vue"
import ButtonProgress from "@/components/atoms/buttons/ButtonProgress.vue"
import ButtonProgressUnconfirm from "@/components/atoms/buttons/ButtonProgressUnconfirm.vue"
import IconCircle from "@/components/atoms/icons/IconCircle.vue"
import IconClose from "@/components/atoms/icons/IconClose.vue"
import IconDash from "@/components/atoms/icons/IconDash.vue"
import ModalStudentHandedData from "@/components/organisms/modal/ModalStudentHandedData.vue"
import homeworkRepository from "@/repositories/homework"
import { correctResult, handedStatus } from "@/constant/homework"

export default {
  name: "TableHomeworkProgressStudents",
  components: {
    BadgeStatus,
    ButtonProgress,
    ButtonProgressUnconfirm,
    IconCircle,
    IconClose,
    IconDash,
    ModalStudentHandedData,
  },

  mixins: [mixin],
  props: {
    headerItems: {
      type: Array,
      default: function () {
        return []
      },
    },
    items: {
      type: Array,
      default: function () {
        return []
      },
    },
    viewItems: {
      type: Array,
      default: function () {
        return []
      },
    },
    gradeStamps: {
      type: Array,
      default: function () {
        return []
      },
    },
    progressParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
    teacherComment: {
      type: String,
      default: function () {
        return ""
      },
    },
    haishinStartDate: {
      type: String,
      default: function () {
        return ""
      }
    },
    showStudentName: { type: Boolean },
    homeworkDetails: {
      type: Object,
      default: function () {
        return {}
      },
    },
    quesImageList: {
      type: Array,
      default: function () {
        return {}
      },
    },
    configPageTeacher: {
      type: Object,
      default: function(){
        return {}
      },
    },
    condTeacher: {
      type: Object,
      default: function(){
        return {}
      },
    },
  },

  data: () => ({
    showStudentDataModal: false,
    showImageDetailModal: false,
    //生徒のindex番号
    studentNum: 0,
    headers: [],
    showed: false,
    // 正解の色
    correctColor: "#f00",
    // 不正解の色
    incorrectColor: "#0070c0",

    // template側で定数を使用するための定義
    correctResult: correctResult,
    handedStatus: handedStatus,
  }),

  computed: {
    ...mapGetters("nameConversions", ["gradeStampItems"]),

    setHeaderItems: function () {
      // 固定ヘッダ項目を初期設定
      (()=>{
        this.headers.length = 0
        this.headers.push(
          // 行番号
          // '',
          ["生徒名"],
          ["提出状況"],
          ["スタンプ"],
          ["回答率"],
          ["正答率\n(／回答)"],
          ["正答率\n(／全体)"],
          ["確認"]
        )
      }).apply()

      // ヘッダーに子問題タイトルを追加する
      this.headerItems.forEach((x) => {
        this.headers.push(x)
      })
      return this.headers
    },

    submitIndexList: function () {
      return this.items
        .map(function (item, index) {
          if (item.status >= handedStatus.handed && item.isInGroup) return index
        })
        .filter((item) => item === 0 || item)
    },
  },

  methods: {
    // コメントを親画面に返す
    onClickReturnOf(studentIndex, stampCode, message, noteDrawList, callback) {
      this.items[this.studentNum].status = handedStatus.returned
      this.items[this.studentNum].statusColor =
        homeworkRepository.getProgressStatusColor(handedStatus.returned)
      this.$emit("on-click-return-of", studentIndex, stampCode, message, noteDrawList, callback)
    },
    onClickSendBack(studentIndex, stampCode, message, noteDrawList, callback) {
      this.items[this.studentNum].status = handedStatus.sendBack
      this.items[this.studentNum].statusColor =
        homeworkRepository.getProgressStatusColor(handedStatus.sendBack)
      this.$emit("on-click-send-back", studentIndex, stampCode, message, noteDrawList, callback)
    },
    closeModalStudentHandedData() {
      this.showStudentDataModal = !this.showStudentDataModal
    },
    checkStudentData(index) {
      this.showStudentDataModal = false
      this.showStudentDataModal = true
      this.studentNum = index
      this.$emit("on-checked-student-data", this.studentNum)
    },
    onReload() {
      this.$emit("on-reload")
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  width: max-content;

  .pre-line {
    white-space: pre-line;
  }

  thead {
    border-top: 1px solid #b3b3b3;

    tr {
      height: 40px;
      background: var(--bs-table-header);
      color: var(--bs-layout-theme);

      &:first-child {
        border-bottom: 1px solid #b3b3b3;

        th {
          border-bottom: none;
        }
      }

      th {
        border-left: 1px solid #b3b3b3;
        border-right: 1px solid #b3b3b3;
        border-bottom: 1px solid #e1e1e1;
      }
    }
  }

  tbody {
    tr {
      height: 50px;

      &:last-child {
        border-bottom: 1px solid #b3b3b3;

        td {
          border-bottom: none;
        }
      }

      td {
        height: 3.08rem;
        padding: 0.15em 1em;
        border-left: 1px solid #b3b3b3;
        border-right: 1px solid #b3b3b3;
        border-bottom: 1px solid #e1e1e1;

        &.bg-layout-theme-light {
          background: var(--bs-layout-theme-light);
        }

        &.bg-disable {
          background: var(--bs-table-row-disable);
        }

        &.studentName {
          width: 10rem;
        }
        &.status {
          width: 13rem;

          ::v-deep .badge {
            width: 100%;
            font-size: 0.9rem;
          }
        }
        &.stamp {
          width: 5rem;
          padding: 0;
        }
        &.response-rate {
          width: 4rem;
          font-size: 1.3rem;
          padding-left: 0.2rem;
          padding-right: 0.3rem;

          .percent {
            font-size: 1rem;
          }
        }
        &.correct-answer-rate {
          width: 4rem;
          font-size: 1.3rem;
          padding-left: 0.2rem;
          padding-right: 0.3rem;

          .percent {
            font-size: 1rem;
          }
        }
        &.response-correct-rate {
          width: 4rem;
          font-size: 1.3rem;
          padding-left: 0.2rem;
          padding-right: 0.3rem;

          .percent {
            font-size: 1rem;
          }
        }
        &.check {
          width: 4rem;
          padding-left: 0;
          padding-right: 0;
        }
        &.child-question {
          width: 4rem;
        }
      }
    }
  }
}

table .col {
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}
table .col.first {
  border-left: 1px solid #b3b3b3;
}
table .col.last {
  border-right: 1px solid #b3b3b3;
}
</style>
