<template>
  <div class="check-box">
    <!-- TODO: 返却するイベントも「change」に合わした方がいい -->
    <input
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @change="$emit('input', $event.target.checked)"
    >
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    label: {
      type: String,
      default: function () {
        return ""
      },
    },
    initialChecked: { type: Boolean },
    disabled: { type: Boolean, default: false },
  },

  data: function () {
    return {
      checked: this.initialChecked,
    }
  },

  mounted: function () {
    // 初期値を呼び出し元に返す
    this.$emit("input", this.checked)
  },
  methods: {
    /**
     * チェック状態を更新
     */
    setChecked: function (checked) {
      this.checked = checked
    }
  },
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}
</style>
