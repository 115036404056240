/**
 * セッション関連 Repository
 */
import { apiResponseCode } from "../constant/network"
import sessionApi from "../api/session"

export default {
  /**
   * Create 系
   */

  /**
   * Read 系
   */

  /**
   * システム日時を取得する
   * @param {String} accountId ID
   * @param {String} apiToken API トークン
   * @returns システム日時
   */
  async getSystemDateTime(accountId, apiToken) {
    const promise = await sessionApi.getSystemDateTime(accountId, apiToken)

    // API ステータスが200番以外でも then に処理がくるのでステータスを確認
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    return promise
  },

  /**
   * 有効なセッション期間か？
   * @param {String} accountId ID
   * @param {String} apiToken API トークン
   * @returns Bool 型（true: 有効なセッション, false: セッション切れ）
   */
  async validSessionTimeSpan(accountId, apiToken) {
    // 現在のシステム日時を取得
    const systemDateTimePromise = await this.getSystemDateTime(
      accountId,
      apiToken
    )
    // システム日時を取得できない場合はセッション切れとする
    return systemDateTimePromise.status === apiResponseCode.ok
  },

  /**
   * Update 系
   */

  /**
   * Delete 系
   */
}
