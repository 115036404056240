var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "link-text-router" }, [
    !_vm.openUrl
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link col-12",
                staticStyle: { padding: "0.3rem 1rem" },
                attrs: { to: { name: _vm.name } }
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(_vm.iconName, {
                      tag: "div",
                      staticClass: "col-3",
                      staticStyle: { "padding-left": "1.1rem" },
                      attrs: {
                        width: "32",
                        height: "32",
                        "fill-color": _vm.iconColor
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "col-9 py-1", class: _vm.textClassObject },
                      [_vm._v(" " + _vm._s(_vm.labelText) + " ")]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      : _c("div", [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { href: _vm.openUrl, target: "_blank" }
            },
            [
              _c("div", { class: _vm.textClassObject }, [
                _vm._v(_vm._s(_vm.labelText))
              ])
            ]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }