/**
 * 宿題管理関連 API
 * ※LMS サーバの API は全て POST メソッド、Request body
 */
import apiInstance from "./axiosBase"

export default {
  /**
   * Create 系 API
   */

  /**
   * Read 系 API
   */

  /**
   * 宿題一覧を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {String} apiToken LMS API トークン
   * @returns 宿題一覧
   */
  async getHomeworkList(accountId, schoolId, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/search`,
        data: {
          account_id: accountId,
          school_id: schoolId,
          token: apiToken,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題詳細情報を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} apiToken LMS API トークン
   */
  async getHomeworkDetail(accountId, schoolId, homeworkKey, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/Detail`,
        data: {
          token: apiToken,
          account_id: accountId,
          school_id: schoolId,
          homework_key: homeworkKey,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題進捗情報を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} apiToken LMS API トークン
   * @returns 宿題進捗情報
   */
  async getHomeworkProgressList(accountId, schoolId, homeworkKey, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SlctResultInfo`,
        data: {
          token: apiToken,
          school_id: schoolId,
          homework_key: homeworkKey,
          account_id: accountId,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 生徒のノート画像を取得する
   * @param {String} accountId アカウントID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} studentAccountId 生徒アカウントID
   * @param {String} apiToken LMS API トークン
   * @returns 宿題進捗情報の生徒のノート画像を取得する
   */
  async searchStudentNoteInfo(
    accountId,
    schoolId,
    homeworkKey,
    studentAccountId,
    apiToken
  ) {
    try {
      let prmAccountId;
      let prmAccountIdList;
      if (Array.isArray(studentAccountId)) {
        prmAccountIdList = studentAccountId;
      } else {
        prmAccountId = studentAccountId;
      }
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SlctNoteFileInfo`,
        data: {
          token: apiToken,
          school_id: schoolId,
          homework_key: homeworkKey,
          account_id: accountId,
          student_account_id: prmAccountId,
          student_account_id_list: prmAccountIdList,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 採点スタンプ画像を取得する
   * @param {String} fileName スタンプ画像ファイル名
   * @returns スタンプ画像
   */
  async getGradeStamps(fileName) {
    try {
      return await apiInstance.axiosAwsS3LinkTextbookBase({
        method: "get",
        // 配置先は教材連携用のバケット（アクセス制限のない場所でないと取得できないため）
        url: `/lms-assets/stamp/${fileName}`,
        headers: {
          "Content-Type": "image/svg+xml",
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 教材設定を取得する
   * @returns 教材設定
   */
  async getBookConfig(fileName) {
    try {
      return await apiInstance.axiosAwsS3LinkTextbookBase({
        method: "get",
        // 配置先は教材連携用のバケット（アクセス制限のない場所でないと取得できないため）
        url: `/lms-assets/json/${fileName}`,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題配信したファイルの履歴情報（STDB、PDF ファイル）を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} mode モード "0"："アップロード一覧"、"1"："課題一覧"
   * @param {Number} homeworkKey モードが"0"場合、0(固定)、モードが"1"の場合、必須。
   * @param {Number} keyNo モードが"0"の場合、配信ファイル連番、モードが"1"の場合、宿題-枝番
   * @param {String} apiToken LMS API トークン
   * @returns 配信ファイル履歴一覧
   */
  async getHomeworkStreamFileInfo(
    accountId,
    schoolId,
    mode,
    homeworkKey,
    keyNo,
    apiToken
  ) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SlctHaishinFileHis`,
        data: {
          token: apiToken,
          account_id: accountId,
          school_id: schoolId,
          mode: mode,
          homework_key: homeworkKey,
          key_no: keyNo,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 配信した宿題種別毎のファイル履歴一覧を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkType 宿題種別
   * @param {String} apiToken LMS API トークン
   * @returns 配信ファイル履歴一覧
   */
  async getHomeworkStreamFileHistoryList(
    accountId,
    schoolId,
    homeworkType,
    apiToken
  ) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/HaishinFileHisList`,
        data: {
          token: apiToken,
          account_id: accountId,
          school_id: schoolId,
          homework_syubetu_kbn: homeworkType,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題の種別ごとの登録済一覧を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {Number} homeworkType 宿題種別
   * @param {String} apiToken LMS API トークン
   * @returns 宿題の問題設定情報一覧
   */
  async getQuestionOfHomework(
    accountId,
    schoolId,
    homeworkKey,
    homeworkType,
    apiToken
  ) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/InsCompList`,
        data: {
          token: apiToken,
          account_id: accountId,
          school_id: schoolId,
          homework_key: homeworkKey,
          homework_syubetu_kbn: homeworkType,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * Update 系 API
   */

  /**
   * 宿題保存・配信
   * @param {Object} params パラメータオブジェクト
   * @param {Number} mode モード（0: 保存、1: 配信）
   * @param {String} apiToken APIトークン
   * @returns 処理結果
   */
  async saveOrPublishHomework(accountId, params, mode, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/Inshomework`,
        data: {
          token: apiToken,
          school_id: params.schoolId,
          homework_key: params.homeworkKey,
          account_id: accountId,
          haishin_status: params.status,
          mode: mode,
          curriculum: params.curriculum,
          subject: params.subject,
          homework_nm: params.homeworkName,
          haishin_start_date: params.startDate,
          haishin_end_date: params.deadlineDate,
          teacher_comment: params.comment,
          haishin_self_flg: params.sendToSelfFlag,
          open_date_time: params.openDateTime,
          HomeworkDetailList: params.detailList,
          HomeworkQuesSetList: params.questionSetList,
          HomeworkFileHistoryList: params.fileHistoryList,
          haishin_grp: params.groupId,
          haishin_account_id: params.publishAccountList,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 生徒別宿題データを更新する（返却する）
   * @param {String} studentAccountId 生徒アカウント ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {Number} mode モード（0：評価セレクト選択、1：返却）
   * @param {String} accountId ID
   * @param {Number} gradeStamp 採点スタンプ
   * @param {String} teacherComment 先生のコメント
   * @param {Array} drawList お絵描きデータ一覧
   * @param {Array} writeInfoList 書き込み情報一覧
   * @param {String} apiToken LMS API トークン
   * @param {String} systemDate
   * @returns 処理結果
   */
  async updateHomeworkProgressByStudent(
    studentAccountId,
    schoolId,
    homeworkKey,
    mode,
    accountId,
    gradeStamp,
    teacherComment,
    drawList,
    writeInfoList,
    apiToken,
    systemDate,
  ) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/UpdHomeworkScoreStamp`,
        data: {
          token: apiToken,
          student_account_id: studentAccountId,
          school_id: schoolId,
          homework_key: homeworkKey,
          mode: mode,
          account_id: accountId,
          score_stamp: gradeStamp,
          teacher_comment: teacherComment,
          NoteDrawList: drawList,
          NoteWriteInfoList: writeInfoList,
          system_date: systemDate,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * オリジナル教材アップロード用のURL取得
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @returns 宿題の問題設定情報一覧
   */
  async giveUncompRequest(accountId, sessionToken) {
    let headers = {
      Accept: "application/json",
      Authorization: `Bearer ${sessionToken}`,
    }
    try {
      return await apiInstance.axiosStreamBase({
        method: "post",
        url: `/accounts/${accountId}/files/uncompressings`,
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * アップロードしたオリジナル教材取得
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @returns 宿題の問題設定情報一覧
   */
  async uploadSpoSpr(uploadURL, fileData) {
    let headers = {
      "Content-Type": fileData["type"],
    }
    try {
      return await apiInstance.axiosLmsBase({
        method: "put",
        url: uploadURL,
        data: fileData,
        headers: headers,
        processData: false,
        contentType: false,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * アップロードしたオリジナル教材取得
   * @param {String} uncompressingURL 解凍URL
   * @param {String} sessionToken セッショントークン
   * @returns 宿題の問題設定情報一覧
   */
  async getUncompressingsURL(uncompressingURL, sessionToken) {
    let headers = {
      Accept: "application/json",
      Authorization: `Bearer ${sessionToken}`,
    }
    try {
      return await apiInstance.axiosStreamBaseForExclusionCredentials({
        method: "get",
        url: uncompressingURL,
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * Delete 系 API
   */

  /**
   * 宿題を1件削除する
   * @param {String} accountId ID
   * @param {Number} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} apiToken LMS API トークン
   */
  async deleteHomework(accountId, schoolId, homeworkKey, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/delete`,
        data: {
          account_id: accountId,
          school_id: schoolId,
          homework_key: homeworkKey,
          token: apiToken,
        },
      })
    } catch (error) {
      return error
    }
  },
}
