<template>
  <div class="input-parameters-homework-register">
    <div class="row mb-1">
      <div class="col-md-6 col-lg-5">
        <FormTextField
          id="homeworkName"
          :label-text="$t('labels.homeworkName')"
          :value="homeworkName"
          :initial-value="initialHomeworkName"
          :max-length="100"
          :is-require="true"
          @input="homeworkName = $event"
        />
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-auto">
        <FormSelectBox
          id="group"
          ref="groupSelectBox"
          :label-text="$t('labels.group')"
          :value="selectedGroup"
          :options="generateGroupIdItems"
          :initial-value="initialStreamGroup"
          :disabled="isPublished"
          :is-require="true"
          @input="onSelectGroup"
        />
      </div>
      <div class="col-auto">
        <FormSelectBox
          id="curriculum"
          ref="curriculumSelectBox"
          :label-text="$t('labels.curriculum')"
          :value="selectedCurriculum"
          :options="generateCurriculumItems"
          :initial-value="initialCurriculum"
          :disabled="isPublished"
          :is-require="true"
          @input="onSelectCurriculum"
        />
      </div>
      <div class="col-auto">
        <FormSelectBox
          id="subject"
          ref="subjectSelectBox"
          :label-text="$t('labels.subject')"
          :value="selectedSubject"
          :options="generateSubjectItems"
          :initial-value="initialSubject"
          :disabled="isPublished"
          @input="selectedSubject = $event"
        />
      </div>
      <div class="col-auto">
        <FormDateField
          :label-text="$t('labels.startDate')"
          :value="startDate"
          :initial-value="initialStartDate"
          :disabled="isPublished"
          :date-range="startDateRange"
          :is-require="true"
          @input="onSelectedStartDate"
        />
      </div>
      <div class="col-auto">
        <FormDateField
          :label-text="$t('labels.deadlineDate')"
          :value="deadlineDate"
          :initial-value="initialDeadlineDate"
          :date-range="deadlineDateRange"
          :is-require="true"
          @input="onSelectedDeadlineDate"
        />
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-12">
        <div
          v-if="showCanNotGetTextbook"
          class="text-danger"
        >
          {{ $t("messages.error.canNotGetTextbook") }}
        </div>
        <div
          v-if="showCanNotGetStudent"
          class="text-danger"
        >
          {{ $t("messages.error.canNotGetStudent") }}
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col">
        <SelectGroupMembers
          ref="groupMembers"
          :options="generateGroupMemberItems"
          :initial-members="initialStreamGroupMembers"
          :is-add-only="isPublished"
          :is-require="true"
          @input="onSelectedGroupMembers"
          v-on="{
            'change-stream-my-self': onCheckedStreamMySelf,
          }"
        />
      </div>
    </div>

    <!-- グループ選択用 -->
    <ModalConfirm
      v-if="showConfirmDeleteBySelectGroup"
      :message="confirmMessage"
      v-on="{
        'ok-confirm-modal': onOkShowConfirmDeleteBySelectGroup,
        'close-confirm-modal': onCloseConfirmModalGroup,
      }"
    />

    <!-- 教科選択用 -->
    <ModalConfirm
      v-if="showConfirmDeleteBySelectCurriculum"
      :message="confirmMessage"
      v-on="{
        'ok-confirm-modal': onOkShowConfirmDeleteBySelectCurriculum,
        'close-confirm-modal': onCloseConfirmModalCurriculum,
      }"
    />
    <ModalConfirmOkOnly
      v-if="isInvalidDateRange"
      :message="
        $t('messages.error.validateDateRange', {
          maxDeadlineDate:
            paramReleaseEndPeriodItems[0].items[0].value -
            paramDeadlinePeriodItems[0].items[0].value,
        })
      "
      @close-confirm-modal-ok-only="onClickInvalidDateRange()"
    />
  </div>
</template>
<script>
/**
 * 宿題管理 - 宿題作成 - ヘッダー設定項目コンポーネント
 */
import { mapGetters, mapMutations } from "vuex"
import mixin from "@/mixins/mixin"
import FormTextField from "@/components/atoms/FormTextField.vue"
import FormSelectBox from "@/components/atoms/FormSelectBox.vue"
import FormDateField from "@/components/atoms/FormDateField.vue"
import SelectGroupMembers from "@/components/organisms/SelectGroupMembers.vue"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import convertValue from "../../repositories/convert-value"
import { homeworkTypeCode } from "../../constant/homework"
import homeworkRepository from "@/repositories/homework"

export default {
  name: "InputParametersHomeworkRegister",
  components: {
    FormTextField,
    FormSelectBox,
    FormDateField,
    SelectGroupMembers,
    ModalConfirm,
    ModalConfirmOkOnly,
  },

  mixins: [mixin],

  props: {
    selectParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isInvalidDateRange: { type: Boolean },
    isPublished: { type: Boolean },
    showCanNotGetStudent: { type: Boolean },
    showCanNotGetTextbook: { type: Boolean },
    streamGroupMember: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  data: function () {
    return {
      homeworkName: "",
      selectedGroup: "",
      // 変更する前のグループ
      previousSelectedStream: "",
      selectedPreviousGroup: "",
      selectedCurriculum: "",
      // 変更する前の教科
      selectedPreviousCurriculum: "",
      selectedSubject: "",
      startDate: "",
      deadlineDate: "",
      selectedGroupMembers: [],
      selectedIsStreamMySelf: false,
      // 削除対象の宿題（課題）インデックス一覧
      deleteHomeworkDetails: [],
      // 初期処理かどうかのフラグ
      isInit: true,
      // 配信データ一覧の削除確認フラグ
      showConfirmDeleteBySelectGroup: false,
      showConfirmDeleteBySelectCurriculum: false,
      // 確認メッセージ
      confirmMessage: "",
      // 確認ダイアログでOKを押したか？
      // HACK:現状どちらを選択してもclose-confirm-modalが走る（と言うか「キャンセルが押された」が取れない）のでこれが必要になる
      //      ModalConfirmの投げるイベントを見直すべきだが、影響範囲を考慮すると相応の工数が必要になると思われるので一旦保留
      isConfirmed: false,
      startDateRange: {},
      deadlineDateRange: {},
    }
  },

  computed: {
    ...mapGetters("homework", ["homeworkDetailItems"]),

    /**
     * グループ一覧を生成
     * （FormSelectBox コンポーネントの配列形式に合わせるために変換）
     */
    generateGroupIdItems: function () {
      let groupItems = this.loginUserInfo.groupIds.map((x) => {
        return {
          label: x.groupName,
          value: x.groupId,
        }
      })
      groupItems.unshift({ label: this.$t("labels.pleaseSelect"), value: "0" })

      return groupItems
    },
    /**
     * 教科一覧を生成
     * （FormSelectBox コンポーネントの配列形式に合わせるために変換）
     */
    generateCurriculumItems: function () {
      let curriculumItems = this.nameCurriculums.items.map((x) => {
        return {
          label: x.name,
          value: x.code,
        }
      })
      curriculumItems.unshift({
        label: this.$t("labels.pleaseSelect"),
        value: "0",
      })

      return curriculumItems
    },
    /**
     * 選択した教科に紐づく科目一覧を生成
     */
    generateSubjectItems: function () {
      const unselected = { label: "　　　　　　", value: "0" }
      let subjectItems = []
      subjectItems.push(unselected)

      // 選択された教科
      const curriculum = Number(this.selectedCurriculum)
      // 選択していない場合は処理しない
      if (!curriculum || curriculum === 0) {
        return subjectItems
      }

      // 教科
      const foundCurriculumKey = this.nameCurriculums.keyItems.find(
        (ck) => Number(ck.code) === curriculum
      )
      // 科目
      let foundSubject = {}
      if (foundCurriculumKey) {
        foundSubject = this.nameSubjects.find(
          (s) => s.curriculumKey === foundCurriculumKey.name
        )
      }

      subjectItems = foundSubject.subjectItems.map((x) => {
        return {
          label: x.name,
          value: x.code,
        }
      })
      subjectItems.unshift(unselected)

      return subjectItems
    },
    /**
     * 配信先グループメンバリストを生成
     */
    generateGroupMemberItems: function () {
      if (this.streamGroupMember.groupMemberItems.length === 0) {
        return []
      }
      return this.streamGroupMember.groupMemberItems.map((x) => {
        return {
          accountId: x.accountId,
          accountName: x.accountName,
          bookItems: x.bookItems,
        }
      })
    },
    /**
     * 宿題名の初期値
     */
    initialHomeworkName: function () {
      if (!Object.keys(this.selectParams.headerInfo).length) {
        return
      }
      return this.selectParams.headerInfo.homeworkName
    },
    /**
     * グループの初期値
     */
    initialStreamGroup: function () {
      if (!Object.keys(this.selectParams.headerInfo).length) {
        return "0"
      }
      if (this.selectParams.headerInfo.streamGroup === null) {
        return "0"
      }
      return String(this.selectParams.headerInfo.streamGroup)
    },
    /**
     * 教科の初期値
     */
    initialCurriculum: function () {
      if (!Object.keys(this.selectParams.headerInfo).length) {
        return "0"
      }
      return String(this.selectParams.headerInfo.curriculum)
    },
    /**
     * 科目の初期値
     * TODO: 値はセットできているが、選択状態にならないので調整が必要
     */
    initialSubject: function () {
      if (!Object.keys(this.selectParams.headerInfo).length) {
        return "0"
      }
      if (this.selectParams.headerInfo.subject === "0") {
        return "0"
      }
      return String(this.selectParams.headerInfo.subject)
    },
    /**
     * 開始日の初期値
     */
    initialStartDate: function () {
      if (!Object.keys(this.selectParams.headerInfo).length) {
        return ""
      }
      if (!this.selectParams.headerInfo.startDate) {
        return ""
      }
      return convertValue.toDateStringSeparatorZeroPadding(
        this.selectParams.headerInfo.startDate,
        "-"
      )
    },
    /**
     * 締切日の初期値
     */
    initialDeadlineDate: function () {
      if (!Object.keys(this.selectParams.headerInfo).length) {
        return ""
      }
      if (!this.selectParams.headerInfo.deadlineDate) {
        return ""
      }
      return convertValue.toDateStringSeparatorZeroPadding(
        this.selectParams.headerInfo.deadlineDate,
        "-"
      )
    },
    /**
     * 配信先グループメンバ一覧の初期値
     */
    initialStreamGroupMembers: function () {
      if (
        !this.selectParams.streamGroupMember.groupMemberItems ||
        this.selectParams.streamGroupMember.groupMemberItems.length === 0
      ) {
        return []
      }
      return this.selectParams.streamGroupMember.groupMemberItems.map((x) => {
        return {
          code: x.accountId,
          name: x.accountName,
          bookItems: x.bookItems,
        }
      })
    },
  },

  beforeMount() {
    // ※編集・コピー時に発生する問題
    // mountedのタイミングでは教科が選択されていない（厳密にはselectedCurriculumに値が入っていない）ため、
    // 教科未選択の状態でgenerateSubjectItemsが走ってしまい、その状態で初期値が確定してしまう
    // （存在しないものを選択してしまう。FormSelectBoxのmountメソッドで初期値がemitされるのはその後）
    // beforeMountでselectedCurriculumに値を放り込み、generateSubjectItemsが正しく動作するようにする
    this.selectedCurriculum = this.selectParams.headerInfo.curriculum
  },

  mounted: function () {
    this.isInit = false
    this.selectedPreviousGroup = this.selectedGroup
    this.startDateRange = homeworkRepository.getStartDateRange()
  },

  methods: {
    ...mapMutations("homework", ["deleteHomeworkDetailItemWithBookId"]),

    /**
     * グループセレクトボックス選択
     */
    onSelectGroup: async function (event) {
      this.selectedPreviousGroup = this.selectedGroup
      this.selectedGroup = event

      await this.$emit("select-group", {
        group: this.selectedGroup,
        curriculum: this.selectedCurriculum,
        isInit: this.isInit,
      })
    },
    deleteHomeworkForNotExistBooks() {
      // 配信データ一覧より教材の問題をチェックして、誰も所有していなければ削除する
      // 初期表示時は処理させたくないのでフラグでチェックしている
      this.deleteHomeworkDetails = []
      if (!this.homeworkDetailItems || this.isInit) {
        return
      }
      if (this.streamGroupMember.groupMemberItems.length > 0) {
        this.homeworkDetailItems.forEach((x) => {
          // 教材の問題の場合
          if (x.questionType === homeworkTypeCode.textbook) {
            const found = this.streamGroupMember.groupMemberItems.some(
              (member) => {
                return member.bookItems.some(
                  (bookItem) =>
                    bookItem.id === x.subQuestionInfo.teachingMaterials
                )
              }
            )

            if (!found) {
              this.deleteHomeworkDetails.push({
                bookId: x.subQuestionInfo.teachingMaterials,
                name: x.taskName,
              })
            }
          }
        })
      } else {
        this.homeworkDetailItems.forEach((x) => {
          this.deleteHomeworkDetails.push({
            bookId: x.subQuestionInfo.teachingMaterials,
            name: x.taskName,
          })
        })
      }
      if (
        !this.isPublished &&
        this.deleteHomeworkDetails.length > 0 &&
        this.initialStreamGroup !== this.selectedGroup
      ) {
        // 削除確認ダイアログメッセージを表示
        this.confirmMessage = this.$t(
          "messages.confirm.deleteHomeworkOnChangeGroupOrCurriculum"
        )
        // 箇条書きで課題名を列挙
        this.deleteHomeworkDetails.forEach(
          (x) => (this.confirmMessage += "・" + x.name + "\n")
        )
        this.isConfirmed = false
        this.showConfirmDeleteBySelectGroup = true
      }
    },
    /**
     * 教科セレクトボックス選択
     */
    onSelectCurriculum: function (event) {
      this.selectedPreviousCurriculum = this.selectedCurriculum
      this.selectedCurriculum = event

      // 教材があれば削除
      // 初期表示時は処理させたくないのでフラグでチェックしている
      if (!this.isInit && this.homeworkDetailItems.length) {
        this.homeworkDetailItems.forEach((x) => {
          if(x.questionType === homeworkTypeCode.textbook){
            this.deleteHomeworkDetails.push({
              bookId: x.subQuestionInfo.teachingMaterials,
              name: x.taskName,
            })
          }
        })
      }
      if (this.deleteHomeworkDetails.length > 0) {
        // 削除確認ダイアログメッセージを表示
        this.confirmMessage = this.$t(
          "messages.confirm.deleteHomeworkOnChangeGroupOrCurriculum"
        )
        // 箇条書きで課題名を列挙
        this.deleteHomeworkDetails.forEach(
          (x) => (this.confirmMessage += "・" + x.name + "\n")
        )
        this.isConfirmed = false
        this.showConfirmDeleteBySelectCurriculum = true
        return
      }

      this.$emit("select-curriculum", {
        group: this.selectedGroup,
        curriculum: this.selectedCurriculum,
        isInit: this.isInit,
      })
      if (!this.isInit) {
        // 科目の初期化
        // ※初期表示時は処理させたくないのでフラグを確認
        this.selectedSubject = "0"
        this.$refs.subjectSelectBox.setValue(this.selectedSubject)
      }
    },
    /**
     * 開始日選択
     */
    onSelectedStartDate: function (event) {
      this.startDate = event
      this.deadlineDateRange = homeworkRepository.getDeadlineDateRange(
        event,
        this.paramReleaseEndPeriodItems[0].items[0].value,
        this.paramDeadlinePeriodItems[0].items[0].value
      )
    },
    /**
     * 締切日選択
     */
    onSelectedDeadlineDate(event) {
      this.deadlineDate = event
    },
    /**
     * 配信先グループメンバ選択
     */
    onSelectedGroupMembers: function (event) {
      this.selectedGroupMembers = event
      this.$emit("on-update-selected-group-members", event)
    },
    /**
     * 自分にも配信するチェックボックスをチェック
     */
    onCheckedStreamMySelf: function (event) {
      this.selectedIsStreamMySelf = event
    },
    /**
     * 教科確認ダイアログ「OK」押下
     */
    onOkShowConfirmDeleteBySelectCurriculum: async function () {
      this.deleteHomeworkDetails.forEach((x) =>
        this.deleteHomeworkDetailItemWithBookId(x.bookId)
      )
      this.deleteHomeworkDetails = []

      this.isConfirmed = true
    },
    /**
     * グループ確認ダイアログ「OK」押下
     */
    onOkShowConfirmDeleteBySelectGroup: async function () {
      this.deleteHomeworkDetails.forEach((x) =>
        this.deleteHomeworkDetailItemWithBookId(x.bookId)
      )
      this.deleteHomeworkDetails = []

      this.isConfirmed = true
    },
    /**
     * グループ選択時確認ダイアログ「キャンセル」押下
     */
    onCloseConfirmModalGroup: async function () {
      // グループを選択前の状態に戻す
      if (!this.isConfirmed) {
        this.selectedGroup = this.selectedPreviousGroup
        await this.$emit("rollback-stream-member")
        this.$refs.groupSelectBox.setValue(this.selectedGroup)
      }

      this.showConfirmDeleteBySelectGroup = false
    },
    onCloseConfirmModalCurriculum: function () {
      if (!this.isConfirmed) {
        // 教科を選択前の状態に戻す
        this.selectedCurriculum = this.selectedPreviousCurriculum
        this.$emit("select-curriculum", {
          group: this.selectedGroup,
          curriculum: this.selectedCurriculum,
        })
        this.$refs.curriculumSelectBox.setValue(this.selectedCurriculum)
      } else {
        // 科目の初期化
        this.selectedSubject = "0"
        this.$refs.subjectSelectBox.setValue(this.selectedSubject)
      }

      this.showConfirmDeleteBySelectCurriculum = false
    },
    /**
     * 必須項目が入力されているか？
     */
    // TODO: バリデーションは、VeeValidate を使用するように変更する
    validateRequiredParameters: function () {
      // 宿題名
      if (!this.homeworkName) {
        return false
      }

      // グループ
      if (this.selectedGroup <= 0) {
        return false
      }

      // 科目
      if (this.selectedCurriculum <= 0) {
        return false
      }

      // 配信先
      if (this.selectedGroupMembers.length === 0) {
        return false
      }

      // 開始日
      if (!this.startDate) {
        return false
      }

      // 締切日
      if (!this.deadlineDate) {
        return false
      }

      return true
    },
    async setInitialMembers() {
      await this.$refs.groupMembers.setInitialMembers()
    },
    onClickInvalidDateRange() {
      this.$emit("on-click-invalid-date-range")
    },
  },
}
</script>
