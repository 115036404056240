<template>
  <div class="homework-register">
    <div class="mt-3 mb-4 mx-4">
      <TitleHomework
        ref="titleArea"
        class="mr-6"
        :help-link="$t('url.helps.homeworkRegister')"
        :show-required-message="true"
      />
    </div>

    <div class="container">
      <div class="row mb-2">
        <div class="col">
          <InputParametersHomeworkRegister
            v-if="showed"
            ref="inputParameters"
            :select-params="{
              headerInfo: headerInfo,
              streamGroupMember: selectedStreamGroupMember,
            }"
            :stream-group-member="accountItems"
            :is-invalid-date-range="isInvalidDateRange"
            :is-published="isPublished"
            :show-can-not-get-student="showCanNotGetStudent"
            :show-can-not-get-textbook="showCanNotGetTextbook"
            v-on="{
              'select-group': onSelectGroup,
              'rollback-stream-member': rollbackStreamMember,
              'on-update-selected-group-members': onUpdateSelectedGroupMembers,
              'on-click-invalid-date-range': onClickInvalidDateRange,
            }"
          />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <ButtonBorderCircleMedium
            :label-name="$t('buttons.addStreamData')"
            color="layout-theme-light"
            :label-color="colorLayoutTheme"
            :border-color="colorLayoutTheme"
            :disabled="isPublished"
            @click.native="tryOpenAddStreamDataModal"
          />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <TableHomeworkStreamDataList
            :stream-data-list="homeworkDetailItems"
            :is-published="isPublished"
            :display-count="10"
            :stream-group-member="selectedStreamGroupMember.groupMemberItems"
            :student-items="studentItems"
            :book-items="bookItems"
            :stream-group="selectedGroupId.toString()"
            :copy-homework-key="previewHomeworkKey"
            :is-require="true"
            v-on="{
              'on-sort': sortHomeworkDetailItemList,
              'changed-stream-data-list': changedStreamDataList,
              delete: deleteStreamData,
            }"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <FormTextArea
            v-if="showed"
            :label-text="$t('labels.comment')"
            aria-label="comment"
            row="3"
            :value="inputTeacherComment"
            :initial-value="initialTeacherComment"
            :placeholder="$t('placeholder.teacherComment')"
            @input="inputTeacherComment = $event"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="row">
            <div class="col-auto">
              <ButtonBorderCircleMedium
                :disabled="!isCreating"
                :label-name="$t('buttons.save')"
                color="layout-theme-light"
                :label-color="colorLayoutTheme"
                :border-color="colorLayoutTheme"
                @click.native="onClickSave"
              />
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="row gx-5">
            <div class="col-auto">
              <ButtonBorderCircleMedium
                :label-name="$t('buttons.cancel')"
                :label-color="colorLayoutTheme"
                :border-color="colorLayoutTheme"
                @click.native="onClickCancel"
              />
            </div>
            <div class="col-auto">
              <ButtonBorderCircleMedium
                :label-name="$t('buttons.stream')"
                color="layout-theme-light"
                :label-color="colorLayoutTheme"
                :border-color="colorLayoutTheme"
                @click.native="onClickStream"
              />
            </div>
          </div>
        </div>
      </div>
      <LoadingScreen v-if="!showed || isLoading" />

      <ModalSelectQuestionType
        v-if="showSelectQuestionType"
        :is-published="isPublished"
        v-on="{
          'pre-transition': preTransitionSelectQuestion,
          close: onCloseSelectQuestionType,
        }"
      />

      <ModalConfirmOkOnly
        v-if="isShowingModalOkOnly"
        :message="modalOkOnlyMessage"
        @close-confirm-modal-ok-only="onClickOkConfirmModalOkOnly"
      />

      <ModalConfirm
        v-if="isShowingModalConfirm"
        :message="confirmMessage"
        v-on="{
          'ok-confirm-modal': onOkConfirmModal,
          'close-confirm-modal': onCloseConfirmModal,
        }"
      />

      <ModalConfirmOkOnly
        v-if="sessionDisconnect"
        :message="$t('messages.error.overLimitSessionToken')"
        @close-confirm-modal-ok-only="onSessionDisconnect()"
      />

      <ModalConfirm
        v-if="showChangedGroups"
        :message="$t('messages.error.changedGroupConfirm')"
        @ok-confirm-modal="onOkCloseShowChangedGroups()"
        @close-confirm-modal="onCloseShowChangedGroups()"
      />
    </div>
  </div>
</template>

<script>
/**
 * 宿題管理 - 宿題作成
 */
import { mapActions, mapGetters, mapMutations } from "vuex"
import { db } from "@/dbs/indexedDb"
import mixin from "@/mixins/mixin"
import { homeworkTypeCode, streamStatus } from "@/constant/homework"
import { sukenServiceId, apiResponseCode } from "@/constant/network"
import homeworkApi from "@/api/homework"
import accountRepository from "@/repositories/account"
import homeworkRepository from "@/repositories/homework"
import convertValue from "@/repositories/convert-value"
import TitleHomework from "@/components/molecules/TitleHomework.vue"
import InputParametersHomeworkRegister from "@/components/organisms/InputParametersHomeworkRegister.vue"
import TableHomeworkStreamDataList from "@/components/organisms/TableHomeworkStreamDataList.vue"
import FormTextArea from "@/components/atoms/FormTextArea.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import ModalSelectQuestionType from "@/components/organisms/modal/ModalSelectQuestionType.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"

export default {
  name: "HomeworkRegister",
  components: {
    TitleHomework,
    InputParametersHomeworkRegister,
    TableHomeworkStreamDataList,
    FormTextArea,
    ButtonBorderCircleMedium,
    ModalSelectQuestionType,
    LoadingScreen,
    ModalConfirmOkOnly,
    ModalConfirm,
  },

  mixins: [mixin],

  props: {
    // モード（ブランク（空文字）：新規作成、edit：編集、copy：複製）
    mode: { type: String, default: "" },
    // 複製項目（学校 ID、宿題キー）
    editParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
    // 編集項目（宿題名、グループ、教科、科目、開始日、締切日、配信先、「自分にも配信」設定）
    copyParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
    // 宿題の配信状態
    homeworkStatus: { type: Number, required: false, default: 0 },
  },

  data: function () {
    return {
      colorLayoutTheme: "#ff7f27",
      showSelectQuestionType: false,
      isShowingModalOkOnly: false,
      modalOkOnlyMessage: "",
      initialTeacherComment: "",
      inputTeacherComment: "",
      showed: false,
      // API側の日付チェックに引っかかったか？
      isInvalidDateRange: false,
      // APIが返すシステム日時
      systemDateTime: "",
      isShowingModalConfirm: false,
      confirmMessage: "",
      // 宿題ステータス
      status: this.homeworkStatus,
      // モード（ブランク（空文字）：新規作成、edit：編集、copy：複製）
      editMode: this.mode,
      accountItems: {
        groupMemberItems: [],
        isStreamMySelf: false,
      },
      selectedStreamGroupMember: {
        groupMemberItems: [],
        isStreamMySelf: false,
      },
      showCanNotGetTextbook: false,
      showCanNotGetStudent: false,
      showChangedGroups: false,
      stateGroupIds: false,
      stateGroupAccounts: false,
      previousStreamGroupMember: {
        groupMemberItems: [],
        isStreamMySelf: false,
      },
      previousSelectedStreamGroupMember: {
        groupMemberItems: [],
        isStreamMySelf: false,
      },
      selectedGroupId: "",
      previousSelectedGroupId: "",
      studentItems: [],
      previousStudentItems: [],
      bookItems: [],
      previousBookItems: [],
      homeworkQuesSetList: [],
      isLoading: false,
      previewHomeworkKey: 0,
    }
  },

  computed: {
    ...mapGetters("nameConversions", ["curriculumConversionTable"]),
    ...mapGetters("homework", [
      "headerInfo",
      "homeworkDetailItems",
      "homeworkSetItems", // 宿題設定
      "homeworkFileHistoryItems", // ファイルリスト
      "uploadPdfDetailItems", // アップロード一覧（一時保存用）
      "uploadStdbDetailItems", // アップロード一覧（一時保存用）
    ]),
    /**
     * 配信中もしくは配信終了状態か？
     * ※これを見て一部パラメータを編集できないようにする
     */
    isPublished: function () {
      return (
        this.status === streamStatus.streaming ||
        this.status === streamStatus.endOfStream
      )
    },
    /**
     * 作成中か？
     * ※一度でも「配信」が実行されている宿題ならfalse
     */
    isCreating: function () {
      return this.status === streamStatus.creating
    },
    /**
     * 先生の所持教科を取得
     */
    handleCurriculums: function () {
      let handleCurriculums = this.loginUserInfo.handleCurriculums
      handleCurriculums = Array.from(new Set(handleCurriculums))
      handleCurriculums.sort(function (a, b) {
        return a - b
      })
      return handleCurriculums
    },
    /**
     * 公開終了日取得処理
     */
    strPeriodTime() {
      let endPeriod = this.paramKeepPeriodItems[2].items[0].value
      let nowDate = new Date()
      let periodTime = new Date(
        nowDate.getFullYear(),
        nowDate.getMonth() + endPeriod,
        nowDate.getDate()
      )
      return (
        periodTime.getFullYear() +
        ("00" + (periodTime.getMonth() + 1)).slice(-2) +
        ("00" + periodTime.getDate()).slice(-2)
      )
    },
  },

  mounted: async function () {
    // セッションチェック
    await this.checkSession()
    await this.init()
  },

  methods: {
    ...mapMutations("homework", [
      "setHeaderInfo",
      "setHomeworkDetailItems",
      "setHomeworkSetItems",
      "setHomeworkFileHistoryItems",
      "setUploadStdbDetailItems",
      "setUploadPdfDetailItems",
      "clearHomeworkFileHistoryItems",
      "clearHomeworkSetItems",
    ]),
    ...mapActions("homework", [
      "saveHomeworkDetailInfo",
      "sortHomeworkDetailItems",
      "deleteHomeworkDetailItem",
    ]),
    ...mapMutations([
      "setGroupIdsOfLoginUser",
      "setHandleCurriculumsOfLoginUser",
    ]),
    async init() {
      // システム日付取得
      this.systemDateTime = await homeworkRepository.getSystemDate(
        this.loginUserInfo.accountId,
        this.loginUserInfo.schoolId,
        this.loginUserInfo.lmsApiToken
      )

      // 既に store にデータが保持されていたら store のデータを表示する
      if (Object.keys(this.headerInfo).length) {
        this.status = this.headerInfo.homeworkStatus
        ;(this.previewHomeworkKey = this.headerInfo.previewHomeworkKey),
          (this.initialTeacherComment = this.headerInfo.teacherComment)
        this.editMode = this.headerInfo.editMode
        this.selectedGroupId = !this.headerInfo.streamGroup
          ? "0"
          : this.headerInfo.streamGroup.toString()
        this.selectedStreamGroupMember =
          await this.getSelectedStreamGroupMemberItems()
        await this.initStreamGroupMemberInfo()
        await this.getChangeStateGroupAccounts()
        this.isLoading = false
        this.$nextTick()
        this.showed = true
        return
      }

      switch (this.editMode) {
        // 編集
        case "edit": {
          try {
            await this.getHomeworkDetail(
              this.editParams.homeworkKey,
              0,
              this.editParams.schoolId
            )

            this.$nextTick()
            this.stateGroupAccounts = await this.getChangeStateGroupAccounts()

            //setlist
            this.createHomeworkQuesSetListForEdit()
            // 宿題ファイルリスト生成
            await this.createHomeworkFileHistoryListForEdit()
            this.showed = true
          } catch (error) {
            console.log(error)
            this.$router.push({
              name: "APIError",
              params: { status: error.status },
            })
          } finally {
            this.isLoading = false
          }
          break
        }
        // コピー
        case "copy": {
          this.previewHomeworkKey = this.copyParams.copyHomeworkKey
          try {
            await this.getHomeworkDetail(
              this.copyParams.copyHomeworkKey,
              this.copyParams.copyHomeworkKey,
              this.copyParams.schoolId
            )

            this.$nextTick()
            this.stateGroupAccounts = await this.getChangeStateGroupAccounts()

            // 宿題設定リスト生成
            this.createHomeworkQuesSetListForEdit()
            // 宿題ファイルリスト生成
            await this.createHomeworkFileHistoryListForEdit()
            this.showed = true
          } catch (error) {
            this.$router.push({
              name: "APIError",
              params: { status: error.status },
            })
          } finally {
            this.isLoading = false
          }
          break
        }
        // 新規作成
        default:
          this.showed = true
          await this.clearSelectedStreamGroupMemberItems()
          break
      }
    },
    /**
     * 宿題ファイルリスト作成
     */
    createHomeworkQuesSetListForEdit: function () {
      let setList = []
      const hwList = this.homeworkDetailItems.filter((item) => {
        return item.questionType === homeworkTypeCode.stdb
      })
      if (!hwList) {
        return
      }
      hwList.forEach((homework) => {
        const quesSetList = this.homeworkQuesSetList.find(
          (item) => item.homework_eda_no === homework.homeworkEdaNo
        )
        setList.push({
          question_type: homework.questionType, // 宿題種別(API登録には含めない：登録時の排除に使用)
          homework_eda_no: homework.homeworkEdaNo, // 宿題連番
          ans_disp_flg:
            quesSetList !== undefined ? quesSetList.ans_disp_flg : 0, // 答え表示フラグ
          explain_disp_flg:
            quesSetList !== undefined ? quesSetList.explain_disp_flg : 0, // 解説表示フラグ
          fileNmSeq: homework.fileNmSeq, // ファイル連番
          file_path: homework.subQuestionInfo.filePath, // ファイルパス
        })
      })
      this.setHomeworkSetItems(setList)
    },

    async createHomeworkFileHistoryListForEdit() {
      // 宿題ファイルリスト宣言
      let fileList = []
      // 宿題配信ファイル履歴一覧データリスト宣言
      let results = []
      // 宿題リスト抽出（教材の問題以外）
      const hwList = this.homeworkDetailItems.filter((item) => {
        return item.questionType !== homeworkTypeCode.textbook
      })
      // 宿題リストが存在しない場合は処理中断
      if (!hwList) {
        return
      }

      // 宿題リストにPDFの課題を含むかフラグを宣言
      const includingPdfFlg = hwList.some(
        (item) => item.questionType === homeworkTypeCode.pdf
      )
      // 宿題リストにSTDBの課題を含むかフラグを宣言
      const includingStdbFlg = hwList.some(
        (item) =>
          item.questionType === homeworkTypeCode.stdbLayout ||
          item.questionType === homeworkTypeCode.stdb
      )

      if (includingPdfFlg) {
        let promise = await this.getHomeworkStreamFileHistoryList(
          homeworkTypeCode.pdf
        )
        const result = promise.map((item) => {
          return {
            expiration_date: item.expiration_date,
            haishin_file_no: item.haishin_file_no,
            homework_syubetu_kbn: homeworkTypeCode.pdf,
            last_use_date: item.last_use_date,
            last_use_time: item.last_use_time,
            main_info: item.main_info,
            send_memo: item.send_memo,
            sub_info: item.sub_info,
            title: item.title,
            update_date: item.update_date,
          }
        })
        results = results.concat(result)
      }
      if (includingStdbFlg) {
        let promise = await this.getHomeworkStreamFileHistoryList(
          homeworkTypeCode.stdbLayout
        )
        const result = promise.map((item) => {
          return {
            expiration_date: item.expiration_date,
            haishin_file_no: item.haishin_file_no,
            homework_syubetu_kbn: homeworkTypeCode.stdbLayout,
            last_use_date: item.last_use_date,
            last_use_time: item.last_use_time,
            main_info: item.main_info,
            send_memo: item.send_memo,
            sub_info: item.sub_info,
            title: item.title,
            update_date: item.update_date,
          }
        })
        results = results.concat(result)
      }
      // DB上のデータと比較してfileListを生成する
      hwList.forEach((homework) => {
        // 既に同じデータを投入していないかの重複チェックを行う
        const existCheck = fileList.some(
          (item) =>
            item.sub_info[0].file_path === homework.subQuestionInfo.filePath
        )
        // 重複していない場合、検索/投入処理を実行する
        if (!existCheck) {
          // 宿題配信ファイル履歴一覧データリストから宿題の固有ファイルパスに当てはまるデータを抽出
          const foundData = results.find(
            (history) =>
              homework.subQuestionInfo.filePath ===
              history.sub_info[0].file_path
          )

          if (foundData !== undefined) {
            // ファイル一覧に情報が存在する場合再利用として投入する
            fileList.push({
              haishin_file_no: foundData.haishin_file_no,
              homework_syubetu_kbn: homework.questionType,
              title: foundData.title,
              main_info: foundData.main_info,
              sub_info: foundData.sub_info,
              send_memo: foundData.send_memo,
              expiration_date: this.strPeriodTime,
              file_status_flg: 1,
              upload_file_list: [],
            })
          }
        }
      })
      if (
        this.uploadStdbDetailItems.length ||
        this.uploadPdfDetailItems.length
      ) {
        let uploadItems = []
        uploadItems = uploadItems
          .concat(this.uploadStdbDetailItems)
          .concat(this.uploadPdfDetailItems)
        fileList.forEach((item) => {
          const findItem = uploadItems.find(
            (ui) => ui.subQuestionInfo.filePath === item.sub_info[0].file_path
          )
          if (findItem !== undefined) {
            if (findItem.deleteFlg) {
              item.file_status_flg = 2
            }
          }
        })

        this.uploadStdbDetailItems.forEach((uploadItem) => {
          if (uploadItem.deleteFlg && uploadItem.haishinFileNo !== 0) {
            const existCheck = fileList.every(
              (file) =>
                file.sub_info[0].file_path !==
                uploadItem.subQuestionInfo.filePath
            )
            if (existCheck) {
              fileList.push({
                title: uploadItem.taskName, // 課題名
                expiration_date: uploadItem.endPubDate.replaceAll("/", ""),
                file_status_flg: 2, // "0"：新規、"1"：再利用、"2"：削除
                haishin_file_no: uploadItem.haishinFileNo,
                homework_syubetu_kbn: homeworkTypeCode.stdbLayout,
                main_info: [
                  {
                    // メイン情報
                    chapter_id: uploadItem.mainQuestionInfo.chapterId, // 章ID
                    node_id: uploadItem.mainQuestionInfo.nodeId, // 節ID
                    ques_parent_id: uploadItem.mainQuestionInfo.quesParentId, // 親問題ID
                    file_name: uploadItem.mainQuestionInfo.fileName, // ファイル名
                  },
                ],
                sub_info: [
                  {
                    // サブ情報
                    teaching_materials:
                      uploadItem.subQuestionInfo.teachingMaterials, // 教材ID
                    file_path: uploadItem.subQuestionInfo.filePath, // ファイルパス
                  },
                ],
                send_memo: uploadItem.sendMemo,
                upload_file_list: [],
              })
            }
          }
        })

        this.uploadPdfDetailItems.forEach((uploadItem) => {
          if (uploadItem.deleteFlg && uploadItem.haishinFileNo !== 0) {
            const existCheck = fileList.every(
              (file) =>
                file.sub_info[0].file_path !==
                uploadItem.subQuestionInfo.filePath
            )
            if (existCheck) {
              fileList.push({
                title: uploadItem.taskName, // 課題名
                expiration_date: uploadItem.expiration_date,
                file_status_flg: 2, // "0"：新規、"1"：再利用、"2"：削除
                haishin_file_no: uploadItem.haishinFileNo,
                homework_syubetu_kbn: homeworkTypeCode.pdf,
                main_info: [
                  {
                    // メイン情報
                    chapter_id: uploadItem.mainQuestionInfo.chapterId, // 章ID
                    node_id: uploadItem.mainQuestionInfo.nodeId, // 節ID
                    ques_parent_id: uploadItem.mainQuestionInfo.quesParentId, // 親問題ID
                    file_name: uploadItem.mainQuestionInfo.fileName, // ファイル名
                  },
                ],
                sub_info: [
                  {
                    // サブ情報
                    teaching_materials:
                      uploadItem.subQuestionInfo.teachingMaterials, // 教材ID
                    file_path: uploadItem.subQuestionInfo.filePath, // ファイルパス
                  },
                ],
                send_memo: uploadItem.sendMemo,
                upload_file_list: [],
              })
            }
          }
        })
      }

      this.setHomeworkFileHistoryItems(fileList)
    },
    /**
     * 配信履歴一覧を取得
     */
    getHomeworkStreamFileHistoryList: async function (questionType) {
      const result = await homeworkApi.getHomeworkStreamFileHistoryList(
        this.loginUserInfo.accountId, // アカウントID
        this.loginUserInfo.schoolId, // 学校ID
        questionType, // 宿題種別
        this.loginUserInfo.lmsApiToken // APIトークン
      )
      if (!result || !result.data) {
        return []
      }
      return result.data.data.haishinfile_history_list
    },
    /**
     * セッションが切れた際のログアウト処理
     */
    async onSessionDisconnect() {
      await this.setSessionDisconnectFalse()
      await this.clearSessionInfo()
      await this.clearSelectedStreamGroupMemberItems()
      await this.$router.push({ name: "Login" })
    },

    /**
     * 宿題詳細情報を取得
     */
    async getHomeworkDetail(homeworkKey, copyHomeworkKey, schoolId) {
      let homeworkDetailPromise
      try {
        homeworkDetailPromise = await homeworkRepository.getHomeworkDetail(
          this.loginUserInfo.accountId,
          schoolId,
          homeworkKey,
          this.loginUserInfo.lmsApiToken,
          this.loginUserInfo.sessionToken,
          this.nameCurriculums,
          this.handleCurriculums
        )
      } catch (error) {
        return error
      } finally {
        this.isLoading = false
      }
      // 先生のコメント
      const teacherComment = homeworkDetailPromise.headerInfo.teacherComment
      this.initialTeacherComment = teacherComment
      this.selectedGroupId = !homeworkDetailPromise.headerInfo.streamGroup
        ? "0"
        : homeworkDetailPromise.headerInfo.streamGroup.toString()

      // store にデータを保持する
      const headerInfo = {
        homeworkKey: copyHomeworkKey ? 0 : homeworkKey,
        previewHomeworkKey: copyHomeworkKey || 0,
        homeworkName: homeworkDetailPromise.headerInfo.homeworkName,
        homeworkStatus: this.status,
        schoolId: schoolId,
        streamGroup: homeworkDetailPromise.headerInfo.streamGroup,
        curriculum: homeworkDetailPromise.headerInfo.curriculum,
        subject: homeworkDetailPromise.headerInfo.subject,
        startDate: homeworkDetailPromise.headerInfo.startDate,
        deadlineDate: homeworkDetailPromise.headerInfo.deadlineDate,
        teacherComment: teacherComment,
        bookItems: homeworkDetailPromise.bookItems,
        editMode: this.editMode,
      }

      this.homeworkQuesSetList = homeworkDetailPromise.homeworkQuesSetList

      this.setHeaderInfo(headerInfo)
      this.setHomeworkDetailItems({
        homeworkDetailItems: homeworkDetailPromise.homeworkDetail,
        questionType: 0,
      })

      await this.setSelectedStreamGroupMemberItems(
        homeworkDetailPromise.groupMemberItems
      )
      this.setSelectedIsStreamMySelf(
        homeworkDetailPromise.headerInfo.isStreamMySelf
      )
      // indexedDB
      await db.selectedStreamGroupMember.put({
        id: 0,
        groupMemberItems: homeworkDetailPromise.groupMemberItems,
        isStreamMySelf: homeworkDetailPromise.headerInfo.isStreamMySelf,
      })
      this.$set(
        this.selectedStreamGroupMember,
        "groupMemberItems",
        homeworkDetailPromise.groupMemberItems
      )
      await this.initStreamGroupMemberInfo()
    },
    async initStreamGroupMemberInfo() {
      if (this.headerInfo.streamGroup) {
        const promise = await homeworkRepository.getStreamGroupMemberInfo(
          this.headerInfo.streamGroup,
          this.loginUserInfo.accountId,
          this.handleCurriculums,
          this.loginUserInfo.sessionToken,
          this.nameCurriculums
        )
        this.$set(
          this.accountItems,
          "groupMemberItems",
          !promise ? [] : promise.accountItems
        )
      }
    },
    /**
     * 配信データ追加モーダルを開く
     * ※「グループ」もしくは「教科」が未選択ならalertでエラーメッセージを出して終了
     */
    tryOpenAddStreamDataModal: async function () {
      if (this.isPublished) {
        return
      }
      if (this.showChangedGroups) {
        return
      }

      // 初期状態の場合、undefinedになっている（何故か普通のundefinedではなく、文字列で"undefined"）
      // その場合は-1を突っ込む
      const selectedGroup =
        this.$refs.inputParameters.selectedGroup != "undefined"
          ? this.$refs.inputParameters.selectedGroup
          : -1
      const selectedCurriculum =
        this.$refs.inputParameters.selectedCurriculum != "undefined"
          ? this.$refs.inputParameters.selectedCurriculum
          : -1

      if (selectedGroup <= 0 || selectedCurriculum <= 0) {
        this.showModalOkOnly(
          this.$t("messages.error.notSelectedGroupAndCurriculum")
        )
        return
      }

      this.selectedGroupId = selectedGroup.toString()
      // 選択したグループが変更されているかを検知する
      await this.getChangeStateGroups()
      this.isLoading = false
      this.showSelectQuestionType = true
    },
    /**
     * 提出する宿題に関連する画像を抽出する
     */
    filterFileHistoryList: function () {
      const list = []
      this.homeworkFileHistoryItems.forEach((item) => {
        if (item.file_status_flg == 2) {
          if (item.haishin_file_no !== 0) {
            list.push(item)
          }
        } else {
          for (const detail of this.homeworkDetailItems) {
            if (
              detail.subQuestionInfo.filePath === item.sub_info[0].file_path &&
              list.every(
                (x) => x.sub_info[0].file_path !== item.sub_info[0].file_path
              )
            ) {
              list.push({
                expiration_date: item.expiration_date,
                file_status_flg: item.file_status_flg,
                haishin_file_no: item.haishin_file_no,
                homework_syubetu_kbn:
                  item.homework_syubetu_kbn === undefined
                    ? detail.questionType
                    : item.homework_syubetu_kbn,
                main_info: item.main_info,
                send_memo: item.send_memo,
                sub_info: item.sub_info,
                title: item.title,
                upload_file_list: item.upload_file_list,
              })
            }
          }
        }
      })
      return list
    },
    /**
     * 保存ボタン押下
     */
    onClickSave: async function () {
      if (!this.isCreating) {
        return
      }
      this.isLoading = true
      // セッション期間が有効かチェックする
      await this.checkSession()

      // HACK:native.clickを使用している為、非活性かどうかの判定はここで行う必要がある
      if (!this.isCreating) {
        this.isLoading = false
        return
      }

      // 選択したグループが変更されているかを検知する
      await this.getChangeStateGroups()
      if (this.showChangedGroups) {
        this.isLoading = false
        return
      }

      // 宿題数上限チェック
      if (
        homeworkRepository.isHomeworkLimit(
          this.homeworkDetailItems,
          this.paramHomeworkMaximumLimitItems
        )
      ) {
        this.showModalOkOnly(
          this.$t("messages.error.overHomeworkStreamCountLimit")
        )

        this.isLoading = false
        return
      }

      // アップロード一覧の存在チェック
      if (await this.notExistCheckForUploadList()) {
        this.isLoading = false
        return
      }

      // 配信するSTDB / PDFの最終利用日と公開終了日を更新
      await this.updateLastUseDateStdbPdf()

      const promise = await this.saveStream()
      // 暫定処理：保存処理結果で日付が返ってくるが、API仕様書にエラーハンドリングされているかが記載されていない為nullが返ってくる可能性を考慮
      if (promise.data) {
        this.systemDateTime = promise.data.data.system_date
        // headerInfoの宿題キーだけ書き換える
        const headerInfo = JSON.parse(JSON.stringify(this.headerInfo))
        headerInfo.homeworkKey = promise.data.data.homework_key
        this.setHeaderInfo(headerInfo)
        const aliveFileHistory = this.homeworkFileHistoryItems.filter(
          (item) => item.file_status_flg !== 2
        )
        this.setHomeworkFileHistoryItems(aliveFileHistory)
      }

      // editモードに変更
      this.editMode = "edit"
      this.preTransitionSelectQuestion()

      this.isLoading = false
      this.showModalOkOnly(this.$t("messages.success.save"))
    },
    /**
     * 配信ボタン押下
     */
    async onClickStream() {
      this.isLoading = true
      // セッション期間が有効かチェックする
      await this.checkSession()

      // 一旦falseにする
      this.isInvalidDateRange = false
      const params = this.$refs.inputParameters

      // 必須チェック
      if (!params.validateRequiredParameters()) {
        this.showModalOkOnly(
          this.$t("messages.error.validateRequiredParameters")
        )

        this.isLoading = false
        return
      }

      // 選択したグループが変更されているかを検知する
      await this.getChangeStateGroups()
      if (this.showChangedGroups) {
        this.isLoading = false
        return
      }

      // 教材があるかどうかのバリデーションは、配信する宿題の中に教材の課題が含まれる場合のみとする
      if (
        (this.showCanNotGetTextbook &&
          this.homeworkDetailItems.find(
            (item) => item.questionType === homeworkTypeCode.textbook
          )) ||
        this.showCanNotGetStudent
      ) {
        this.isLoading = false
        return
      }

      // 日付チェック
      if (!this.isPublished) {
        if (
          !homeworkRepository.isValidStartDate(
            this.systemDateTime,
            params.startDate.replaceAll("-", "/")
          )
        ) {
          this.isInvalidDateRange = true

          this.isLoading = false
          return
        }
      }

      if (
        !homeworkRepository.isValidDeadlineDate(
          params.startDate.replaceAll("-", "/"),
          params.deadlineDate.replaceAll("-", "/"),
          this.paramReleaseEndPeriodItems[0].items[0].value,
          this.paramDeadlinePeriodItems[0].items[0].value
        )
      ) {
        this.isInvalidDateRange = true

        this.isLoading = false
        return
      }

      // 宿題数下限チェック
      if (homeworkRepository.isHomeworkEmpty(this.homeworkDetailItems)) {
        this.showModalOkOnly(
          this.$t("messages.error.homeworkStreamListIsEmpty")
        )

        this.isLoading = false
        return
      }

      // 宿題数上限チェック
      if (
        homeworkRepository.isHomeworkLimit(
          this.homeworkDetailItems,
          this.paramHomeworkMaximumLimitItems
        )
      ) {
        this.showModalOkOnly(
          this.$t("messages.error.overHomeworkStreamCountLimit")
        )

        this.isLoading = false
        return
      }

      // 配信先チェック
      // ※対象となる教材を保有していないメンバが一人でもいる課題が一つでもあった場合は確認ダイアログを出す
      const checkItemNames = []
      this.homeworkDetailItems.forEach((x) => {
        // 教材の問題の場合
        if (x.questionType === homeworkTypeCode.textbook) {
          const found = this.selectedStreamGroupMember.groupMemberItems.some(
            (s) =>
              !s.bookItems.find(
                (book) => book.id === x.subQuestionInfo.teachingMaterials
              )
          )
          if (found) {
            checkItemNames.push(x.taskName)
          }
        }
      })

      // アップロード一覧の存在チェック
      if (await this.notExistCheckForUploadList()) {
        this.isLoading = false
        return
      }

      // 配信するSTDB / PDFの最終利用日と公開終了日を更新
      await this.updateLastUseDateStdbPdf()

      if (checkItemNames.length) {
        this.confirmMessage = this.$t(
          "messages.confirm.streamTargetMemberHasNotBook"
        )
        checkItemNames.forEach((x) => (this.confirmMessage += "・" + x + "\n"))
        this.isShowingModalConfirm = true

        this.isLoading = false
        return
      }

      await this.executeStream()
    },
    /**
     * 配信APIを叩く
     */
    executeStream: async function () {
      const histories = this.filterFileHistoryList()
      const params = this.$refs.inputParameters
      try {
        await homeworkRepository.publishHomework(
          this.loginUserInfo.accountId,
          {
            schoolId: this.loginUserInfo.schoolId,
            homeworkKey:
              this.editMode === "edit" ? this.headerInfo.homeworkKey : 0,
            status: this.status,
            curriculum: params.selectedCurriculum,
            subject: params.selectedSubject,
            homeworkName: params.homeworkName,
            startDate: params.startDate,
            deadlineDate: params.deadlineDate,
            comment: this.inputTeacherComment,
            sendToSelfFlag: params.selectedIsStreamMySelf,
            openDateTime: this.systemDateTime,
            detailList: this.homeworkDetailItems,
            questionSetList: this.homeworkSetItems,
            fileHistoryList: histories,
            groupId: params.selectedGroup,
            publishAccountList: params.selectedGroupMembers.map(
              (item) => item.code
            ),
          },
          this.loginUserInfo.lmsApiToken
        )
        this.showModalOkOnly(this.$t("messages.success.publish"))
        this.clearHomeworkFileHistoryItems()
        this.clearHomeworkSetItems()
      } catch (error) {
        this.isLoading = false
        if (error.status === apiResponseCode.internalServerError) {
          // データがなかったよ
          return
        } else {
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    async saveStream() {
      const histories = this.filterFileHistoryList()
      const params = this.$refs.inputParameters
      try {
        return await homeworkRepository.saveHomework(
          this.loginUserInfo.accountId,
          {
            schoolId: this.loginUserInfo.schoolId,
            homeworkKey:
              this.editMode === "edit" ? this.headerInfo.homeworkKey : 0,
            status: this.status,
            curriculum: params.selectedCurriculum,
            subject: params.selectedSubject,
            homeworkName: params.homeworkName,
            startDate: params.startDate,
            deadlineDate: params.deadlineDate,
            comment: this.inputTeacherComment,
            sendToSelfFlag: params.selectedIsStreamMySelf,
            openDateTime: this.systemDateTime,
            detailList: this.homeworkDetailItems,
            questionSetList: this.homeworkSetItems,
            fileHistoryList: histories,
            groupId: params.selectedGroup,
            publishAccountList: params.selectedGroupMembers.map(
              (item) => item.code
            ),
          },
          this.loginUserInfo.lmsApiToken
        )
      } catch (error) {
        this.isLoading = false
        if (error.status === apiResponseCode.internalServerError) {
          // データがなかったよ
          return
        } else {
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        }
      }
    },
    /**
     * キャンセルボタン押下
     */
    onClickCancel: function () {
      this.$router.push({ name: "Homework" })
    },
    /**
     * 宿題リストのソート
     */
    sortHomeworkDetailItemList: function (indexA, indexB) {
      this.sortHomeworkDetailItems({ indexA: indexA, indexB: indexB })
    },
    /**
     * 宿題を一件削除
     */
    deleteStreamData: function (item) {
      this.deleteHomeworkDetailItem(item.index)
    },
    /**
     * OKだけのモーダルを表示する
     */
    showModalOkOnly: function (message) {
      this.modalOkOnlyMessage = message
      this.isShowingModalOkOnly = true
    },
    /**
     * 配信先グループメンバ情報を取得
     */
    getStreamGroupMemberInfo: async function (selectedData) {
      if (selectedData.isInit) {
        return
      }

      // 配信先グループメンバの氏名を取得
      try {
        const promise = await homeworkRepository.getStreamGroupMemberInfo(
          selectedData.group,
          this.loginUserInfo.accountId,
          this.handleCurriculums,
          this.loginUserInfo.sessionToken,
          this.nameCurriculums
        )

        this.$set(this.accountItems, "groupMemberItems", promise.accountItems)
        this.$set(
          this.selectedStreamGroupMember,
          "groupMemberItems",
          promise.accountItems
        )

        // 紐づく教材が0件の場合
        this.showCanNotGetTextbook = !promise.accountItems.some(
          (item) => item.bookItems.length > 0
        )
        if (this.showCanNotGetTextbook) {
          return
        }
        this.studentItems = promise.accountItems
        this.bookItems = promise.bookItems
      } catch (error) {
        // 紐づく生徒が0件の場合
        this.showCanNotGetStudent = true
        this.clearStreamGroupMemberItems()
        await this.clearSelectedStreamGroupMemberItems()
        await this.$refs.inputParameters.setInitialMembers()
        this.isLoading = true
      }
    },
    /**
     * グループセレクトボックス選択
     */
    onSelectGroup: async function (event) {
      // セッション期間が有効かチェックする
      await this.checkSession()
      this.isLoading = true

      this.previousSelectedGroupId = this.selectedGroupId
      this.selectedGroupId = event.group.toString()
      this.previousStreamGroupMember = JSON.parse(
        JSON.stringify(this.accountItems)
      )
      this.previousSelectedStreamGroupMember = JSON.parse(
        JSON.stringify(this.selectedStreamGroupMember)
      )
      this.previousStudentItems = JSON.parse(JSON.stringify(this.studentItems))
      this.previousBookItems = JSON.parse(JSON.stringify(this.bookItems))
      this.showChangedGroups = false
      this.showCanNotGetTextbook = false
      this.showCanNotGetStudent = false

      if (this.selectedGroupId === "0") {
        await this.clearSelectedStreamGroupMemberItems()
        this.clearStreamGroupMemberItems()
        await this.$nextTick()
        await this.$refs.inputParameters.setInitialMembers()
      } else {
        // 配信先グループメンバ情報を取得
        await this.getStreamGroupMemberInfo(event)
        // 選択したグループが変更されているかを検知する
        this.stateGroupIds = await this.getChangeStateGroupIds()
        if (this.accountItems.groupMemberItems.length === 0) {
          this.showCanNotGetStudent = true
        }
        await this.$refs.inputParameters.setInitialMembers()
      }
      this.$refs.inputParameters.deleteHomeworkForNotExistBooks()

      this.isLoading = false
    },
    /**
     * 配信先グループメンバー更新
     */
    onUpdateSelectedGroupMembers: function (members) {
      if (!members || members.length === 0) {
        this.$set(this.selectedStreamGroupMember, "groupMemberItems", [])
      } else {
        this.$set(
          this.selectedStreamGroupMember,
          "groupMemberItems",
          members.map((m) => {
            return {
              accountId: m.code,
              accountName: m.name,
              bookItems: m.bookItems,
            }
          })
        )
      }
    },
    /**
     * 子での値変更時に親の値を変更する
     */
    changedStreamDataList(streamDataList) {
      this.setHomeworkDetailItems({
        homeworkDetailItems: streamDataList,
        questionType: 0,
      })
    },
    /**
     * 確認ダイアログでOKボタンが押された
     */
    onOkConfirmModal() {
      this.executeStream()
      this.isLoading = true
      this.isShowingModalConfirm = false
    },
    /**
     * 確認ダイアログが閉じられた
     */
    onCloseConfirmModal() {
      this.isShowingModalConfirm = false
    },
    /**
     * 確認ダイアログ（OK のみ）で OK ボタン押下
     */
    onClickOkConfirmModalOkOnly() {
      this.isShowingModalOkOnly = false
      // 配信処理が終了した場合
      if (this.modalOkOnlyMessage === this.$t("messages.success.publish")) {
        // 宿題一覧に遷移
        this.$router.push({ name: "Homework" })
      }
    },
    /**
     * 他画面に遷移する前に呼ばれる
     */
    async preTransitionSelectQuestion() {
      // store にデータを保持する
      const params = this.$refs.inputParameters
      const headerInfo = {
        homeworkKey: this.editMode === "edit" ? this.headerInfo.homeworkKey : 0,
        previewHomeworkKey:
          this.previewHomeworkKey || this.copyParams.copyHomeworkKey,
        homeworkName: params.homeworkName,
        homeworkStatus: this.status,
        schoolId: this.headerInfo.schoolId,
        bookItems: this.headerInfo.bookItems,
        streamGroup: params.selectedGroup,
        curriculum: params.selectedCurriculum,
        subject: params.selectedSubject,
        editMode: this.editMode,
        // 日付はyyyyMMdd形式である必要がある
        startDate: params.startDate.replaceAll("-", ""),
        deadlineDate: params.deadlineDate.replaceAll("-", ""),
        teacherComment: this.inputTeacherComment,
      }
      this.setHeaderInfo(headerInfo)
      // store に登録
      await this.setSelectedStreamGroupMemberItems(
        params.selectedGroupMembers.map((item) => {
          // サーバから受信するデータに合わせる
          // ※InputParametersHomeworkRegister側で受け付けているのがこの形の為
          return {
            accountId: item.code,
            accountName: item.name,
            bookItems: item.bookItems,
          }
        })
      )
      this.setSelectedIsStreamMySelf(params.selectedIsStreamMySelf)
    },
    /**
     * 問題の種類選択モーダルが閉じられた
     */
    onCloseSelectQuestionType() {
      this.showSelectQuestionType = false
    },
    /**
     * 選択したグループが変更されているかを取得する
     */
    async getChangeStateGroups() {
      if (this.selectedGroupId && this.selectedGroupId !== "0") {
        this.stateGroupIds = await this.getChangeStateGroupIds()
        this.stateGroupAccounts = await this.getChangeStateGroupAccounts()
        this.showChangedGroups = this.stateGroupIds || this.stateGroupAccounts
      }
    },
    async getChangeStateGroupIds() {
      return await accountRepository.getChangeStateGroupIds(
        this.loginUserInfo.accountId,
        this.loginUserInfo.sessionToken,
        this.selectedGroupId
      )
    },
    /**
     * グループ再取得処理
     */
    async onRefleshGroup() {
      let groupIdPromise = null
      let sukenAccountInfoPromise = null
      try {
        groupIdPromise = await accountRepository.getGroupIdsOfSukenAccount(
          sukenServiceId.lms,
          this.loginUserInfo.accountId,
          this.loginUserInfo.sessionToken
        )
        sukenAccountInfoPromise = await accountRepository.getSukenAccountInfo(
          sukenServiceId.lms,
          this.loginUserInfo.accountId,
          this.loginUserInfo.sessionToken
        )
      } catch (error) {
        this.$router.push({
          name: "APIError",
          params: { status: error.status },
        })
      }

      // グループID
      this.setGroupIdsOfLoginUser(groupIdPromise.data.groupList)

      // 担当教科
      const curriculums = sukenAccountInfoPromise.data.chargedSubject
      // 教科IDを変換
      let isError = false
      const handleCurriculums = curriculums.map((code) => {
        let item = this.curriculumConversionTable.find(
          (item) => code == item.code
        )

        if (!item) {
          console.error(`Invalid curriculum code: ${code}`)
          isError = true
        }
        return Number(item.value)
      })

      if (isError) {
        // 変換できなかったものがあった場合は403エラーとする
        this.$router.push({
          name: "APIError",
          params: { status: apiResponseCode.forbidden },
        })
        return
      }
      this.setHandleCurriculumsOfLoginUser(handleCurriculums)

      if (this.stateGroupIds) {
        if (this.homeworkStatus === 2 || this.homeworkStatus === 3) {
          await this.$router.push({
            name: "Homework",
            params: {
              deleteParams: {
                schoolId: this.headerInfo.schoolId,
                homeworkKey: this.headerInfo.homeworkKey,
              },
            },
          })
        } else {
          const params = this.$refs.inputParameters
          const headerInfo = {
            homeworkKey:
              this.editMode === "edit" ? this.headerInfo.homeworkKey : 0,
            homeworkName: params.homeworkName,
            homeworkStatus: this.status,
            schoolId:
              this.editMode === "edit"
                ? this.headerInfo.schoolId
                : this.loginUserInfo.schoolId,
            streamGroup: this.editMode === "edit" ? params.selectedGroup : "0",
            curriculum:
              this.editMode === "edit" ? params.selectedCurriculum : "0",
            subject: this.editMode === "edit" ? params.selectedSubject : "",
            editMode: this.editMode,
            // 日付はyyyyMMdd形式である必要がある
            startDate: params.startDate.replaceAll("-", ""),
            deadlineDate: params.deadlineDate.replaceAll("-", ""),
            teacherComment: this.inputTeacherComment,
          }
          this.setHeaderInfo(headerInfo)
          await this.clearSelectedStreamGroupMemberItems()
          if (this.editMode === "edit") {
            const promise = await homeworkRepository.getStreamGroupMemberInfo(
              this.headerInfo.streamGroup,
              this.loginUserInfo.accountId,
              this.handleCurriculums,
              this.loginUserInfo.sessionToken,
              this.nameCurriculums
            )
            this.$set(
              this.accountItems,
              "groupMemberItems",
              promise.accountItems
            )
          } else {
            this.$set(this.accountItems, "groupMemberItems", [])
          }

          location.reload()
        }
      } else {
        // ブラウザリロード
        const params = this.$refs.inputParameters
        const headerInfo = {
          homeworkKey:
            this.editMode === "edit" ? this.headerInfo.homeworkKey : 0,
          previewHomeworkKey:
            this.previewHomeworkKey || this.copyParams.copyHomeworkKey,
          homeworkName: params.homeworkName,
          homeworkStatus: this.status,
          schoolId: this.headerInfo.schoolId,
          streamGroup: params.selectedGroup,
          curriculum: params.selectedCurriculum,
          subject: params.selectedSubject,
          editMode: this.editMode,
          // 日付はyyyyMMdd形式である必要がある
          startDate: params.startDate.replaceAll("-", ""),
          deadlineDate: params.deadlineDate.replaceAll("-", ""),
          teacherComment: this.inputTeacherComment,
        }
        this.setHeaderInfo(headerInfo)
        const promise = await homeworkRepository.getStreamGroupMemberInfo(
          this.headerInfo.streamGroup,
          this.loginUserInfo.accountId,
          this.handleCurriculums,
          this.loginUserInfo.sessionToken,
          this.nameCurriculums
        )
        if (
          this.headerInfo.homeworkStatus === 2 ||
          this.headerInfo.homeworkStatus === 3
        ) {
          const selectedStreamGroupMember =
            await this.getSelectedStreamGroupMemberItems()
          const result =
            await selectedStreamGroupMember.groupMemberItems.filter((item) =>
              promise.accountItems.some((p) => {
                return item.accountId === p.accountId
              })
            )
          await this.setSelectedStreamGroupMemberItems(result)
        } else {
          await this.clearSelectedStreamGroupMemberItems()
        }
        location.reload()
      }
    },
    /**
     * 配信先の選択可能リストを初期化
     */
    clearStreamGroupMemberItems() {
      this.accountItems.groupMemberItems = []
      this.accountItems.isStreamMySelf = false
    },
    /**
     * 配信先の選択済リストを初期化
     */
    async clearSelectedStreamGroupMemberItems() {
      await db.selectedStreamGroupMember.put({
        id: 0,
        groupMemberItems: [],
        isStreamMySelf: false,
      })
      this.selectedStreamGroupMember = {
        groupMemberItems: [],
        isStreamMySelf: false,
      }
    },

    async setSelectedStreamGroupMemberItems(paramGroupMemberItems) {
      return await db.selectedStreamGroupMember.put({
        id: 0,
        groupMemberItems: paramGroupMemberItems,
        isStreamMySelf: false,
      })
    },

    async setSelectedIsStreamMySelf(paramIsStreamMySelf) {
      return await db.selectedStreamGroupMember.update(0, {
        isStreamMySelf: paramIsStreamMySelf,
      })
    },

    async getSelectedStreamGroupMemberItems() {
      return await db.selectedStreamGroupMember.get(0)
    },

    /**
     * 選択したグループが削除されている場合のモーダルOKボタン押下時
     */
    async onOkCloseShowChangedGroups() {
      // グループ再取得処理
      await this.onRefleshGroup()
    },
    /**
     * 選択したグループが削除されている場合のモーダルキャンセルボタン押下時
     */
    onCloseShowChangedGroups() {
      // モーダル表示フラグ初期化
      this.showChangedGroups = false
    },
    /**
     * グループに紐づく配信先が変更されているかを取得する
     */
    async getChangeStateGroupAccounts() {
      this.isLoading = true
      try {
        const getSukenAccountWithBooksPromise =
          await this.getSukenAccountWithBooks(this.selectedGroupId)
        this.studentItems = getSukenAccountWithBooksPromise.accountItems
        this.bookItems = getSukenAccountWithBooksPromise.bookItems

        // 紐づく教材が0件の場合
        if (!this.bookItems || this.bookItems.length === 0) {
          this.showCanNotGetTextbook = true
          return false
        }

        if (this.studentItems.length === 0) {
          if (this.accountItems.groupMemberItems.length > 0) {
            return true
          }
        }

        const checkResult = this.accountItems.groupMemberItems.every(
          (account) =>
            this.studentItems.find(
              (pAccount) => pAccount.accountId === account.accountId
            )
        )
        return !checkResult
      } catch (error) {
        await this.$nextTick()
        // 紐づく生徒が0件の場合
        this.showCanNotGetStudent = true
        if (this.accountItems.groupMemberItems.length > 0) {
          return true
        }
        this.isLoading = false
      }
    },
    async getSukenAccountWithBooks(selectedGroupId) {
      try {
        let curriculums = []
        this.nameCurriculums.shortName.map((item) => {
          for (let i in this.loginUserInfo.handleCurriculums) {
            if (this.loginUserInfo.handleCurriculums[i] == item.code) {
              curriculums.push(item.name)
            }
          }
        })
        return await accountRepository.getSukenAccountWithBooks(
          selectedGroupId,
          this.loginUserInfo.accountId,
          curriculums,
          this.loginUserInfo.sessionToken
        )
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 日付範囲エラーモーダル閉じる
     */
    onClickInvalidDateRange() {
      this.isInvalidDateRange = !this.isInvalidDateRange
    },
    /**
     * 配信先をグループ変更前の状態に戻す
     */
    async rollbackStreamMember() {
      // グループIDを変更前に戻す
      this.selectedGroupId = this.previousSelectedGroupId

      // 生徒一覧を変更前に戻す
      this.studentItems = JSON.parse(JSON.stringify(this.previousStudentItems))
      await this.$nextTick()
      // 書籍一覧を変更前に戻す
      this.accountItems = JSON.parse(
        JSON.stringify(this.previousStreamGroupMember)
      )
      await this.$nextTick()
      // 選択済生徒一覧を変更前に戻す
      this.selectedStreamGroupMember = JSON.parse(
        JSON.stringify(this.previousSelectedStreamGroupMember)
      )
      await this.$nextTick()
      // 選択可能生徒一覧を変更前に戻す
      this.bookItems = JSON.parse(JSON.stringify(this.previousBookItems))
      await this.$nextTick()
      await this.$refs.inputParameters.setInitialMembers()
    },

    /**
     * アップロード一覧の存在チェックを行う（true: 存在しない, false: 存在する）
     */
    async notExistCheckForUploadList() {
      let deletedList = []
      let haishinLists = []

      let homeworkPdfItems = this.homeworkDetailItems.filter(
        (hw) => hw.questionType === homeworkTypeCode.pdf
      )

      let homeworkStdbItems = this.homeworkDetailItems.filter(
        (hw) =>
          hw.questionType === homeworkTypeCode.stdb ||
          hw.questionType === homeworkTypeCode.stdbLayout
      )

      let filteredHomeworkFileHistoryItems =
        this.homeworkFileHistoryItems.filter(
          (hisItem) => hisItem.file_status_flg !== 0
        )

      // 絞り込んだSTDBの要素のうち既に存在しない配信ファイルを使用している宿題がないかをチェック
      if (homeworkStdbItems.length > 0) {
        // DB上に存在しないファイルを使用していないか
        const result = await homeworkApi.getHomeworkStreamFileHistoryList(
          this.loginUserInfo.accountId, // アカウントID
          this.loginUserInfo.schoolId, // 学校ID
          homeworkTypeCode.stdbLayout, // 宿題種別
          this.loginUserInfo.lmsApiToken // APIトークン
        )
        let haishinList = result.data.data.haishinfile_history_list
        haishinLists = haishinLists.concat(haishinList)
      }

      // 絞り込んだPDFの要素のうち既に存在しない配信ファイルを使用している宿題がないかをチェック
      if (homeworkPdfItems.length > 0) {
        // DB上に存在しないファイルを使用していないか
        const result = await homeworkApi.getHomeworkStreamFileHistoryList(
          this.loginUserInfo.accountId, // アカウントID
          this.loginUserInfo.schoolId, // 学校ID
          homeworkTypeCode.pdf, // 宿題種別
          this.loginUserInfo.lmsApiToken // APIトークン
        )

        let haishinList = result.data.data.haishinfile_history_list
        haishinLists = haishinLists.concat(haishinList)
      }
      if (filteredHomeworkFileHistoryItems.length > 0) {
        // 再利用または削除予定のアップロード一覧が1つでも存在する場合存在チェックを行う

        // PDFの要素のみで絞り込み
        let pdfItems = filteredHomeworkFileHistoryItems.filter(
          (item) => item.homework_syubetu_kbn == homeworkTypeCode.pdf
        )
        // STDBの要素のみで絞り込み
        let stdbItems = filteredHomeworkFileHistoryItems.filter(
          (item) => item.homework_syubetu_kbn != homeworkTypeCode.pdf
        )

        // 絞り込んだPDFの要素のうち既に存在しない配信ファイルを使用している宿題がないかをチェック
        if (pdfItems.length > 0) {
          // Store上に存在しないファイルを使用していないか
          const willDeleteItems = pdfItems.filter(
            (item) => item.file_status_flg === 2
          )
          deletedList = deletedList.concat(willDeleteItems)
        }

        // 絞り込んだSTDBの要素のうち既に存在しない配信ファイルを使用している宿題がないかをチェック
        if (stdbItems.length > 0) {
          // Store上に存在しないファイルを使用していないか
          const willDeleteItems = stdbItems.filter(
            (item) => item.file_status_flg === 2
          )
          deletedList = deletedList.concat(willDeleteItems)
        }
      }

      this.homeworkDetailItems.forEach((hw) => {
        const notExistDeletedList = deletedList.every(
          (dl) => dl.sub_info[0].file_path !== hw.subQuestionInfo.filePath
        )
        const notExistDb = haishinLists.every(
          (hl) => hl.sub_info[0].file_path !== hw.subQuestionInfo.filePath
        )
        const notExistStore = this.homeworkFileHistoryItems.every(
          (hfh) => hfh.sub_info[0].file_path !== hw.subQuestionInfo.filePath
        )
        if (notExistDeletedList && notExistDb && notExistStore) {
          deletedList.push({
            sub_info: [
              {
                file_path: hw.subQuestionInfo.filePath,
              },
            ],
          })
        }
      })
      if (deletedList.length > 0) {
        let filteredHomeworkDetailItems = this.homeworkDetailItems.filter(
          (item) => {
            return item.questionType != homeworkTypeCode.textbook
          }
        )
        let deleteTasks = filteredHomeworkDetailItems.filter((item) => {
          return deletedList.some((elem) => {
            return elem.sub_info[0].file_path == item.subQuestionInfo.filePath
          })
        })

        if (deleteTasks.length > 0) {
          let taskNames = ""
          deleteTasks.forEach((item) => {
            taskNames += "・" + item.taskName + "\n"
          })
          if (this.isPublished) {
            // エラーメッセージ表示
            this.showModalOkOnly(
              this.$t(
                "messages.error.notExistUploadFileForPublishOrSaveAlreadyPublished"
              )
            )
          } else {
            // エラーメッセージ表示
            this.showModalOkOnly(
              this.$t("messages.error.notExistUploadFileForPublishOrSave", {
                taskName: taskNames,
              })
            )
          }

          return true
        } else {
          return false
        }
      }
    },
    /**
     * 配信するSTDB / PDFの最終利用日・公開終了日を更新
     */
    async updateLastUseDateStdbPdf() {
      const periodDate = homeworkRepository.getPeriodDate(
        this.paramKeepPeriodItems[2].items[0].value
      )
      const strPeriodDate =
        periodDate.getFullYear() +
        "/" +
        ("00" + (periodDate.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + periodDate.getDate()).slice(-2)

      const stdbList = JSON.parse(JSON.stringify(this.uploadStdbDetailItems))
      const pdfList = JSON.parse(JSON.stringify(this.uploadPdfDetailItems))

      const list = this.homeworkFileHistoryItems.filter(
        (hisItem) => hisItem.file_status_flg !== 0
      )
      list.forEach((item) => {
        if (item.homework_syubetu_kbn === homeworkTypeCode.pdf) {
          // PDF
          const index = pdfList.findIndex(
            (item2) =>
              item.sub_info[0].file_path === item2.subQuestionInfo.filePath
          )
          if (index !== -1) {
            pdfList[index].endUseDate = new Date().toLocaleDateString()
            pdfList[index].endPubDate = strPeriodDate
            pdfList[index].updateDate = convertValue.formatDatetime(new Date())
          }
        } else {
          // STDB
          const index = stdbList.findIndex(
            (item2) =>
              item.sub_info[0].file_path === item2.subQuestionInfo.filePath
          )
          if (index !== -1) {
            stdbList[index].endUseDate = new Date().toLocaleDateString()
            stdbList[index].endPubDate = strPeriodDate
            stdbList[index].updateDate = convertValue.formatDatetime(new Date())
          }
        }
      })

      await this.setUploadStdbDetailItems(stdbList)
      await this.setUploadPdfDetailItems(pdfList)
    },
  },
}
</script>
<style lang="scss" scoped>
.homework-register-btn-group {
  display: flex;
  position: relative;
}
.btn-position-wrapper {
  width: 100%;
  justify-content: center;
  position: absolute;
}
.btn-position-save {
  float: left;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  @media (max-width: 991px) {
    justify-content: flex-start;
  }
}
.btn-position-stream {
  float: left;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  justify-content: center;
  @media (max-width: 991px) {
    justify-content: flex-end;
  }
}
.btn-position-left-btn {
  padding: 0 1vw;
}
@media (max-width: 991px) {
  .col-8 .row {
    --bs-gutter-x: 1.5rem !important;
  }
}
</style>
