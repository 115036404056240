<template>
  <div
    class="table-header-label"
    @click="onClick"
  >
    <div class="text-center">
      {{ labelText }}
      <span v-if="sortKey && isSelected">
        <span v-if="sortOrder === 1"> ▲ </span>
        <span v-else> ▼ </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableHeaderLabel",
  props: {
    labelText: {
      type: String,
      default: function () {
        return ''
      },
    },
    sortKey: {
      type: String,
      default: function () {
        return ''
      },
    },
    isSelected: Boolean,
  },
  data: function () {
    return {
      // 1:昇順、-1:降順
      sortOrder: -1,
    }
  },
  methods: {
    onClick: function () {
      if (!this.sortKey) {
        return
      }

      if (this.isSelected) {
        this.sortOrder *= -1
      } else {
        this.sortOrder = 1
      }
      this.$emit("on-selected", this.sortKey, this.sortOrder)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header-label {
  cursor: default;
  user-select: none;
}
</style>
