<template>
  <div class="form-login-password-field">
    <label
      :for="id"
      class="form-label"
    >{{ labelText }}</label>
    <input
      :id="id"
      type="password"
      :value="value"
      :disabled="isDisabled"
      style="background-color: #e9e9e9"
      class="form-control form-login-password-control"
      :aria-describedby="help"
      @input="$emit('input', $event.target.value)"
    >
    <div :id="help">
      {{ helpMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FormLoginPasswordField",
  props: {
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
    id: {
      type: String,
      default: function () {
        return ""
      },
    },
    value: {
      type: String,
      default: function () {
        return ""
      },
    },
    help: {
      type: String,
      default: function () {
        return ""
      },
    },
    helpMessage: {
      type: String,
      default: function () {
        return ""
      },
    },
    isDisabled: Boolean,
  },
}
</script>
<style>
.form-label {
  margin: 0;
}
.form-login-password-control {
  height: 50px;
}
.form-login-password-field {
  width: 94%;
}
.form-login-password-field > label {
  margin: 0;
}
</style>