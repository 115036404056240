// APIが返すHTTPステータスコード
export const apiResponseCode = {
    // 成功
    ok: 200,
    // 何らかのクライアント側のエラー（バリデーションエラー）
    badRequest: 400,
    // 有効な認証資格が不足している
    unauthorized: 401,
    // 有効な認証資格が不足している
    unauthorizedPassword: 402,
    // サーバーがリクエストを理解したものの、認証が拒否された
    forbidden: 403,
    // サーバーがリクエストされたリソースを見つけることができない
    notFound: 404,
    // サーバーがリクエスト実行を妨げる予期しない条件に遭遇した
    internalServerError: 500,
    // サーバーがリクエストを処理する準備ができていない
    serviceUnavailable: 503,
    // サーバーからレスポンスが返ってこなかった
    timeout: 504,
}

// 数研サービスID
export const sukenServiceId = {
    // LMS(新デジタル教科書システム)
    lms: 2,
}

// LMS APIが返す結果コード
export const lmsApiResultCode = {
    // 成功
    success: 1,
    // 失敗
    failure: 0,
}

// LMS APIが返すエラーコード
export const lmsApiErrorCode = {
    // 配信ステータスエラー（配信ステータスが0：作成中、1：配信済（公開待）以外だった場合）
    streamStatusError: 'E0001',
}
