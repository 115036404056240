<template>
  <div
    class="homework-progress"
    style="overflow: auto"
  >
    <div class="container-fluid">
      <div class="row mt-3 mb-2 justify-content-around">
        <div class="col-auto">
          <TitleHomework :help-link="$t('url.helps.homeworkProgress')" />
        </div>
        <div class="col text-center homework-name py-2">
          {{ homeworkName }}
        </div>
        <div class="col-auto">
          <div class="d-flex flex-column">
            <div>
              {{
                $t("labels.startDateReplaceWords", {
                  date: "：" + params.startDate,
                })
              }}
            </div>
            <div>
              {{
                $t("labels.deadlineDateReplaceWords", {
                  date: "：" + params.deadlineDate,
                })
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col">
          <div class="row justify-content-around">
            <div class="col-auto">
              <router-link :to="{ name: 'Homework' }">
                <ButtonReturn
                  :label-name="$t('buttons.returnToList')"
                  label-color="#ff7f27"
                  border-color="#ff7f27"
                />
              </router-link>
            </div>
            <div class="col-auto">
              <ButtonExportProgress
                :label-name="$t('buttons.fileExport')"
                color="layout-theme-light"
                :label-color="colorLayoutTheme"
                :border-color="colorLayoutTheme"
                @click.native="onClickExport(item)"
              />
            </div>
            <div class="col text-end py-2">
              <CheckBox
                ref="showStudentNameCheckBox"
                label="生徒名を表示"
                :initial-checked="true"
                @input="onChangedShowStudentNameCheckBox"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="w-100 p-3 pt-0">
            <div class="row mb-4">
              <div class="col">
                <TableHomeworkProgressWhole
                  :header-items="!isLoading ? homeworkHeaderItems : []"
                  :child-question-rate-items="
                    !isLoading ? childQuestionRateItems : []
                  "
                  :book-items="bookItems"
                  :student-items="studentItems"
                  :homework-details="homeworkDetails"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <TableHomeworkProgressStudents
                  :header-items="!isLoading ? homeworkHeaderItems : []"
                  :items="!isLoading ? homeworkProgressItems : []"
                  :view-items="!isLoading ? viewProgressItems : []"
                  :grade-stamps="stamps"
                  :teacher-comment="teacherComment"
                  :haishin-start-date="haishinStartDate"
                  :show-student-name="showStudentName"
                  :progress-params="params"
                  :homework-details="homeworkDetails"
                  :ques-image-list.sync="quesImageList"
                  :config-page-teacher.sync="configPageTeacher"
                  :cond-teacher.sync="condTeacher"
                  v-on="{
                    'on-click-return-of': onClickReturnOf,
                    'on-click-send-back': onClickSendBack,
                    'on-checked-student-data': onCheckedStudentData,
                    'on-change-show-student-name':
                      onChangedShowStudentNameCheckBox,
                    'on-reload': reloadStudentsInfo,
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoadingScreen v-if="isLoading" />
  </div>
</template>

<script>
/**
 * 宿題管理 - 宿題進捗
 */
import { mapGetters, mapMutations, mapActions } from "vuex";
import mixin from "@/mixins/mixin";
import TitleHomework from "@/components/molecules/TitleHomework.vue";
import ButtonReturn from "@/components/atoms/buttons/ButtonReturn.vue";
import ButtonExportProgress from "@/components/atoms/buttons/ButtonExportProgress.vue";
// TODO:非表示の要望がありコメントアウト。
// import ButtonImage from '@/components/atoms/buttons/ButtonImage.vue'
import CheckBox from "@/components/atoms/CheckBox.vue";
import TableHomeworkProgressWhole from "@/components/organisms/TableHomeworkProgressWhole.vue";
import TableHomeworkProgressStudents from "@/components/organisms/TableHomeworkProgressStudents.vue";
import LoadingScreen from "@/components/atoms/LoadingScreen.vue";
import homeworkRepository from "@/repositories/homework";
import accountRepository from "@/repositories/account";
import questionRepository from "@/repositories/question"
import { handedStatus, homeworkTypeCode, streamFileInfoMode, noteType } from "@/constant/homework"

export default {
  name: "HomeworkProgress",
  components: {
    TitleHomework,
    ButtonReturn,
    ButtonExportProgress,
    // TODO:非表示の要望がありコメントアウト。
    // ButtonImage,
    CheckBox,
    TableHomeworkProgressWhole,
    TableHomeworkProgressStudents,
    LoadingScreen,
  },

  mixins: [mixin],
  props: {
    progressParams: {
      type: Object,
      require: true,
      default: function () {
        return {};
      },
    },
  },

  data: function () {
    return {
      colorLayoutTheme: "#ff7f27",
      params: this.progressParams,
      homeworkName: "",
      homeworkHeaderItems: [],
      homeworkProgressItems: [],
      viewProgressItems: [],
      childQuestionRateItems: [],
      isLoading: false,
      // 初期値はチェックボックス ON とする
      showStudentName: true,
      teacherComment: "",
      haishinStartDate: "",
      // 生徒別データで選択したスタンプコード
      selectedStampCode: 0,
      // 生徒名称リスト
      groupMemberItems: [],
      studentItems: [],
      bookItems: [],
      homeworkDetails: {},
      systemDate: "",
      jsons: {},
      configPageTeacher: {},
      condTeacher: {},
      quesImageList: [],
    };
  },

  computed: {
    ...mapGetters("gradeStamp", ["stamps"]),
    ...mapGetters("homework", [
      "temporaryProgressParams",
      "checkedStudentList",
    ]),
    ...mapGetters("nameConversions", ["gradeStampItems"]),
  },
  created: function () {
    if (!Object.keys(this.params).length) {
      this.params = JSON.parse(JSON.stringify(this.temporaryProgressParams));
    } else {
      // 宿題一覧から貰ったパラメータが存在すればそれをstoreに保存する
      // ※this.progressParamsはリロードしたら消えてしまうため
      this.setTemporaryProgressParams(JSON.parse(JSON.stringify(this.params)));
    }
    this.homeworkName = this.params.homeworkName;
  },
  mounted: async function () {
    console.time('mounted');

    // 宿題データのロード
    await this.loadHwData(true);
    this.isLoading = true;

    // this.isLoading = true;

    // // 宿題進捗情報を取得
    // let promise = null;
    // try {
    //   promise = await homeworkRepository.getHomeworkProgressList(
    //     this.loginUserInfo.accountId,
    //     this.loginUserInfo.schoolId,
    //     this.params.homeworkKey,
    //     this.params.deadlineDate,
    //     this.loginUserInfo.lmsApiToken,
    //     this.loginUserInfo.sessionToken
    //   );
    // } catch (error) {
    //   this.$router.push({ name: "APIError", params: { status: error.status } });
    // }

    // // システム日付
    // this.systemDate = promise.systemDate;

    // // ヘッダー情報
    // // 親問題単位で扱えるように加工する
    // const headers = promise.headerItems;
    // const childQuestionRates = promise.childQuestionRateItems;
    // const headerItems = [];
    // const childQuestionRateItems = [];
    // headers.forEach((header, index) => {
    //   const headerIndex = Number(header.split("\n")[0]) - 1;
    //   if (!headerItems[headerIndex]) {
    //     headerItems[headerIndex] = [];
    //     childQuestionRateItems[headerIndex] = [];
    //   }
    //   headerItems[headerIndex].push(header);
    //   childQuestionRateItems[headerIndex].push(childQuestionRates[index]);
    // });
    // this.homeworkHeaderItems = headerItems;

    // // 子問題別レート情報
    // this.childQuestionRateItems = childQuestionRateItems;

    // // 進捗詳細情報
    // this.homeworkProgressItems = promise.progressItems;

    // // 先生コメント
    // this.teacherComment = promise.teacherComment;

    // // 採点スタンプ画像情報が無い場合は、S3 より取得する
    // if (!this.stamps.length) {
    //   // 採点スタンプ画像を取得
    //   homeworkRepository
    //     .getGradeStamps(this.gradeStampItems)
    //     .then((response) => {
    //       // store に登録
    //       this.saveGradeStamps(response);
    //     });
    // }

    // try {
    //   await this.getAccountWithBookItems();
    // } catch (error) {
    //   console.log(error);
    //   this.isLoading = false;
    // }

    // try {
    //   await this.getStudentsInfo(this.params.homeworkKey, this.params.schoolId);
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   this.setStudentsInfo();
    //   this.isLoading = false;
    // }

    if (this.homeworkProgressItems.length > 0) {
        this.deleteTransferStudentList(this.params.homeworkKey)

      this.homeworkProgressItems.forEach((item) => {
        switch (item.status) {
          case handedStatus.handed:
            // グループから外されている場合
            if (item.accountName == "―") {
              // 転校した生徒のIDをStoreに保存
              this.addTransferStudentList({
                homeworkKey: this.params.homeworkKey,
                studentId: item.accountId,
              });
            }
            break;
          case handedStatus.handChecked:
            // 確認した生徒のIDをStoreに保存
            this.addCheckedStudentProgress({
              homeworkKey: this.params.homeworkKey,
              studentId: item.accountId,
            });
            break;
          case handedStatus.returned:
            // 確認した生徒のIDをStoreに保存
            this.addCheckedStudentProgress({
              homeworkKey: this.params.homeworkKey,
              studentId: item.accountId,
            });
            break;
          case handedStatus.returnChecked:
            // 確認した生徒のIDをStoreに保存
            this.addCheckedStudentProgress({
              homeworkKey: this.params.homeworkKey,
              studentId: item.accountId,
            });
            break;

          default:
            break;
        }
      });
    }

    // 問題画像の準備
    await this.setupQuesImageList();
    this.isLoading = false;
    console.timeEnd('mounted');
  },

  methods: {
    ...mapActions("gradeStamp", ["saveGradeStamps"]),
    ...mapActions("homework", ["addCheckedStudentProgress", "addTransferStudentList"]),
    ...mapMutations("homework", ["setTemporaryProgressParams", "deleteTransferStudentList"]),

    async loadHwData(isLoadAccountInfo) {
      this.isLoading = true;

      // 宿題進捗情報を取得
      let promise = null;
      try {
        promise = await homeworkRepository.getHomeworkProgressList(
          this.loginUserInfo.accountId,
          this.loginUserInfo.schoolId,
          this.params.homeworkKey,
          this.params.deadlineDate,
          this.loginUserInfo.lmsApiToken,
          this.loginUserInfo.sessionToken
        );
      } catch (error) {
        this.$router.push({ name: "APIError", params: { status: error.status } });
      }

      // システム日付
      this.systemDate = promise.systemDate;

      // ヘッダー情報
      // 親問題単位で扱えるように加工する
      const headers = promise.headerItems;
      const childQuestionRates = promise.childQuestionRateItems;
      const headerItems = [];
      const childQuestionRateItems = [];
      headers.forEach((header, index) => {
        const headerIndex = Number(header.split("\n")[0]) - 1;
        if (!headerItems[headerIndex]) {
          headerItems[headerIndex] = [];
          childQuestionRateItems[headerIndex] = [];
        }
        headerItems[headerIndex].push(header);
        childQuestionRateItems[headerIndex].push(childQuestionRates[index]);
      });
      this.homeworkHeaderItems = headerItems;

      // 子問題別レート情報
      this.childQuestionRateItems = childQuestionRateItems;

      // 進捗詳細情報
      this.homeworkProgressItems = promise.progressItems;

      // 先生コメント
      this.teacherComment = promise.teacherComment;

      // 配信開始日
      this.haishinStartDate = this.params.startDate;

      // 採点スタンプ画像情報が無い場合は、S3 より取得する
      if (!this.stamps.length) {
        // 採点スタンプ画像を取得
        homeworkRepository
          .getGradeStamps(this.gradeStampItems)
          .then((response) => {
            // store に登録
            this.saveGradeStamps(response);
          });
      }

      if (isLoadAccountInfo) {
        try {
          await this.getAccountWithBookItems();
        } catch (error) {
          console.log(error);
          this.isLoading = false;
        }
      }

      try {
        await this.getStudentsInfo(this.params.homeworkKey, this.params.schoolId);
      } catch (error) {
        console.log(error);
      } finally {
        homeworkRepository.setStudentsInfo(
          this.homeworkHeaderItems,
          this.homeworkProgressItems,
          this.groupMemberItems,
          this.childQuestionRateItems,
          this.homeworkDetails
        );
        this.updateHandedStatus();
        this.isLoading = false;
      }

      // 宿題情報をノート情報に反映
      for (const studentData of this.homeworkProgressItems) {
        for (const note of studentData.noteItems) {
          if (note.noteType !== noteType.writeInfo) {
            continue;
          }
          note.homework_syubetu_kbn = this.homeworkDetails.homeworkInfo.find(hw => hw.homeworkEdaNo === note.homeworkEdaNo).kind;
        }
      }
    },
    // 返却押下時保存処理
    async onClickReturnOf(studentIndex, stampCode, comment, noteWriteInfoList, callback) {
      const stamp = this.stamps.find((s) => s.code === stampCode);
      this.homeworkProgressItems[studentIndex].stamp = stamp.code;
      try {
        await homeworkRepository.updateHomeworkProgressByStudent(
          this.homeworkProgressItems[studentIndex].accountId,
          this.params.schoolId,
          this.params.homeworkKey,
          1,
          this.loginUserInfo.accountId,
          stampCode,
          comment,
          [],
          noteWriteInfoList,
          this.loginUserInfo.lmsApiToken,
          this.systemDate,
        );
        if (callback) {
          callback(true);
        }
      } catch (error) {
        console.log(error);
        if (this.checkExlusive(error)) {
          // 競合・排他エラーの場合
          if (callback) {
            callback(false);
          }
          return;
        }
        this.$router.push({
          name: "APIError",
          params: { status: error.status },
        });
      }
    },
    // やり直し押下時
    async onClickSendBack(studentIndex, stampCode, comment, noteWriteInfoList, callback) {
      const stamp = this.stamps.find((s) => s.code === stampCode);
      this.homeworkProgressItems[studentIndex].stamp = stamp.code;
      try {
        await homeworkRepository.updateHomeworkProgressByStudent(
          this.homeworkProgressItems[studentIndex].accountId,
          this.params.schoolId,
          this.params.homeworkKey,
          2,
          this.loginUserInfo.accountId,
          stampCode,
          comment,
          [],
          noteWriteInfoList,
          this.loginUserInfo.lmsApiToken,
          this.systemDate,
        );

        if (callback) {
          callback(true);
        }
      } catch (error) {
        console.log(error);
        if (this.checkExlusive(error)) {
          // 競合・排他エラーの場合
          if (callback) {
            callback(false);
          }
          return;
        }
        this.$router.push({
          name: "APIError",
          params: { status: error.status },
        });
      }
    },
    async reloadStudentsInfo() {
      await this.loadHwData(false);
    },
    async checkExlusive(error) {
      return error.status === 409 || error.status === 550;
    },
    async getAccountWithBookItems() {
      try {
        const responce = await accountRepository.getSukenAccountWithBooks(
          this.params.groupId,
          this.loginUserInfo.accountId,
          this.params.curriculum,
          this.loginUserInfo.sessionToken
        );
        this.studentItems = responce.accountItems;
        this.bookItems = responce.bookItems;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    /**
     * 生徒情報を取得する処理
     */
    async getStudentsInfo(homeworkKey, schoolId) {
      const promise = await homeworkRepository.getStudentsInfo(
        this.loginUserInfo.accountId,
        schoolId,
        homeworkKey,
        this.loginUserInfo.lmsApiToken,
        this.loginUserInfo.sessionToken,
        this.nameCurriculums,
        false,
      );
      this.groupMemberItems = promise.groupMemberItems;
      this.homeworkDetails = promise.homeworkDetails;
    },
    /**
     * 提出ステータスを更新
     */
    updateHandedStatus() {
      const checkedIdList =
        this.checkedStudentList[this.params.homeworkKey] || [];
      this.homeworkProgressItems.forEach((item) => {
        // 「提出済み」かつStoreに確認済み情報が保持されていればステータスを「確認済み」とする
        if (
          item.status === handedStatus.handed &&
          checkedIdList.some((x) => x === item.accountId)
        ) {
          this.$set(item, "status", handedStatus.handChecked);
        }
      });

      // 表示用進捗詳細情報リストを取得
      this.viewProgressItems = homeworkRepository.getViewProgressItems(
        this.homeworkProgressItems,
        this.nameHomeworkStatusItems,
        this.homeworkHeaderItems,
        this.progressParams.homeworkKey,
        this.$t("labels.expired")
      );
    },
    /**
     * 生徒のデータを確認した
     */
    onCheckedStudentData(index) {
      if (this.homeworkProgressItems[index].status == handedStatus.sendBack || this.homeworkProgressItems[index].status == handedStatus.sendBackCheck) {
        // やり直しの場合は確認済にしない
        return;
      }
      // 確認した生徒のIDをStoreに保存
      this.addCheckedStudentProgress({
        homeworkKey: this.params.homeworkKey,
        studentId: this.homeworkProgressItems[index].accountId,
      });
      // ステータス更新
      this.updateHandedStatus();
    },
    /*
     * 生徒名の表示フラグ変更
     */
    onChangedShowStudentNameCheckBox(isShow) {
      this.showStudentName = isShow;
      this.$refs.showStudentNameCheckBox.setChecked(isShow);
    },
    /**
     * 問題画像を取得する
     */
    async setupQuesImageList() {
      this.configPageTeacher = await homeworkRepository.getBookConfig('config_page_teacher.json');
      this.condTeacher = await homeworkRepository.getBookConfig('cond_teacher.json');
      const imgList = [];
      const condOriginalUrl = "cond_original.json";
      const configPageOriginalUrl = "config_page_original.json";
      const curriculum = this.homeworkDetails.curriculum;
      for(let lessonIdx = 0; lessonIdx < this.homeworkDetails.homeworkInfo.length; lessonIdx++){
        const detail = this.homeworkDetails.homeworkInfo[lessonIdx];
        const lessonNo = lessonIdx + 1;
        switch(detail.kind){
          case homeworkTypeCode.textbook:     // 書籍
            {
              await this.getBookAndStudentAccount(detail.bookId);
              const img = JSON.parse(JSON.stringify(detail));
              const basePath = `textbook/${curriculum}/${detail.bookId}`;
              img.filePath = await this.getQuestionImage(`${basePath}/${detail.chapterId}/page/learning_img/${detail.quesParentId}.jpg`);
              img.lessonNo = lessonNo;
              img.cond = await this.getJson(`${basePath}/000/json/cond.json`);
              img.configPage = await this.getJson(`${basePath}/000/json/config_page.json`);
              imgList.push(img);
              console.debug(`homeworkKey:${img.homeworkKey}, homeworkEdaNo:${img.homeworkEdaNo}, kind:${img.kind}, lessonNo:${img.lessonNo}, fielName:${detail.quesParentId}, taskName:${img.taskName}`);
            }
            break;
          case homeworkTypeCode.pdf:          // PDF
          case homeworkTypeCode.stdbLayout:   // STDB(レイアウト通り)
            {
              // 1課題で複数ページがある
              try{
                const files = await this.getFiles(detail);
                for(let i = 0; i < files.length; i++){
                  const file = files[i];
                  const img = JSON.parse(JSON.stringify(detail));
                  img.lessonNo = lessonNo;
                  img.pageNo = i + 1;
                  img.maxPage = files.length;
                  img.filePath = file.filePath;
                  img.cond = await homeworkRepository.getBookConfig(condOriginalUrl);
                  img.configPage = await homeworkRepository.getBookConfig(configPageOriginalUrl);
                  imgList.push(img);
                  console.debug(`homeworkKey:${img.homeworkKey}, homeworkEdaNo:${img.homeworkEdaNo}, kind:${img.kind}, lessonNo:${img.lessonNo}, fielName:${file.fileName}, taskName:${img.taskName}, pageNo:${img.pageNo}, maxPage:${img.maxPage}`);
                };
              }catch(err){
                console.error(`問題画像取得エラー`);
              }
            }
            break;
          case homeworkTypeCode.stdb:         // STDB(1枚づつ)
            {
              try{
                const files = await this.getFiles(detail);
                let pageNo = 0;
                for (let i = 0; i < files.length; i++) {
                  const file = files[i];
                  if (file.fileName && file.fileName.match(/^Q/)) {
                    // ファイル名が"Q"で始まるものが問題用画像
                    const img = JSON.parse(JSON.stringify(detail));
                    img.lessonNo = lessonNo;
                    img.pageNo = ++pageNo;
                    img.filePath = file.filePath;
                    img.cond = await homeworkRepository.getBookConfig(condOriginalUrl);
                    img.configPage = await homeworkRepository.getBookConfig(configPageOriginalUrl);
                    imgList.push(img);
                    console.debug(`homeworkKey:${img.homeworkKey}, homeworkEdaNo:${img.homeworkEdaNo}, kind:${img.kind}, lessonNo:${img.lessonNo}, fielName:${file.fileName}, taskName:${img.taskName}, pageNo:${img.pageNo}, maxPage:${img.maxPage}`);
                    break;
                  }
                };
              }catch(err){
                console.error(`問題画像取得エラー`);
              }
            }
            break;
        }
      };
      for(const img of imgList){
        if(img.filePath != ""){
          await this.loadImage(img);
        }
      }
      this.quesImageList.splice(0, this.quesImageList.length, ...imgList)
    },
    async getBookAndStudentAccount(bookId) {
      try {
        const bookAndAccount = await accountRepository.getBookAndStudentAccount(
          this.studentItems,
          this.bookItems,
          bookId
        )
        await this.getCredentials(bookAndAccount)
      } catch (error) {
        console.error(error)
      }
    },
     async getCredentials(bookAndAccount) {
      try {
        return await accountRepository.getCookieSukenAccountWithBooks(
          this.homeworkDetails.groupId,
          bookAndAccount.student.accountId,
          bookAndAccount.book.productIds[0],
          bookAndAccount.book.bookId,
          this.loginUserInfo.accountId,
          this.loginUserInfo.sessionToken
        )
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async getJson(url){
      if(!(url in this.jsons)){
        this.jsons[url] = await questionRepository.getSviewerJson(url);
      }
      return this.jsons[url];
    },
    async getQuestionImage(url){
      const img = await questionRepository.getQuestionImage(url);
      return img;
    },
    async getFiles(detail){
      switch(detail.kind){
        case homeworkTypeCode.pdf:          // PDF
        case homeworkTypeCode.stdbLayout:   // STDB(レイアウト通り)
          return await homeworkRepository.getHomeworkStreamFileListForPreview(
            this.loginUserInfo.accountId,
            this.loginUserInfo.schoolId,
            streamFileInfoMode.questionList,
            detail.homeworkKey,
            detail.homeworkEdaNo,
            this.loginUserInfo.lmsApiToken
          );
        case homeworkTypeCode.stdb:         // STDB(1枚づつ)
          return await homeworkRepository.getHomeworkStreamFileListForStdbPreview(
            this.loginUserInfo.accountId,
            this.loginUserInfo.schoolId,
            streamFileInfoMode.questionList,
            detail.homeworkKey,
            detail.homeworkEdaNo,
            detail.fileNmSeq,
            this.loginUserInfo.lmsApiToken
          );
        default:
          return null;
      }
    },
    loadImage(imgInfo){
      return new Promise((resolve, reject)=>{
        const img = new Image();
        img.onload = ()=>{
          imgInfo.width = img.naturalWidth;
          imgInfo.height = img.naturalHeight;
          resolve();
        };
        img.onerror = e=>reject(e);
        img.src = imgInfo.filePath;
      });
    },
    /**
     * Excelファイルエクスポート
     */
    async onClickExport() {
      this.isLoading = true;

      homeworkRepository.outputProgressInfo(
        this.params,
        this.homeworkHeaderItems,
        this.childQuestionRateItems,
        this.viewProgressItems,
      );

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.homework-progress {
  .title-homework {
    ::v-deep ul.list-inline {
      margin-bottom: 0;
    }
  }
}
.homework-name {
  font-size: 120%;
  font-weight: bold;
}
</style>
