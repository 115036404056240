<template>
  <div class="icon-close">
    <svg
      id="close"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      :width="width"
      :height="height"
      viewBox="0 0 125.5 125.5"
      style="enable-background:new 0 0 125.5 125.5;"
      xml:space="preserve"
    >
      <polygon
        class="st0"
        points="62.7,70.8 15.2,118.3 7.2,110.2 54.7,62.7 7.2,15.2 15.2,7.2 62.7,54.7 110.2,7.2 118.3,15.2 
      70.8,62.7 118.3,110.2 110.2,118.3 "
        :style="closeStyle"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconClose',
  props: {
    width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    colorStyle: {
      type: String,
      default: function () {
        return '#FFFFFF'
      },
    },
  },

  data: () => ({
    // アイコンにタイトルを設定する場合は、iconName を表示するようにする
    // ファイル名：close.svg（自社作成）
    iconName: '閉じる'
  }),

  computed: {
    closeStyle: function () {
      return {
        // 指定がなければ白色
        'fill': this.colorStyle ? this.colorStyle : '#FFFFFF',
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>