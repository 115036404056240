var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "service-unavailable-content" }, [
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6 offset-3 text-center" },
        [
          _c(
            "router-link",
            { attrs: { to: { name: "Login" } } },
            [
              _c("ButtonForward", {
                attrs: {
                  "label-name": _vm.$t("buttons.returnToLogin"),
                  color: "secondary"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("h1", [_vm._v("ただいまメンテナンス中です。")]),
      _c("h3", { staticClass: "text-muted" }, [
        _vm._v(" 503 Maintenance Mode ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("p", [_vm._v("ご不便をおかけし申し訳ございません。")]),
      _c("p", [_vm._v("メンテナンス終了まで今しばらくお待ちください。")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }