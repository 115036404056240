<template>
  <div class="service-unavailable-content">
    <div class="text-center">
      <h1>ただいまメンテナンス中です。</h1>
      <h3 class="text-muted">
        503 Maintenance Mode
      </h3>
    </div>
    <div class="content">
      <p>ご不便をおかけし申し訳ございません。</p>
      <p>メンテナンス終了まで今しばらくお待ちください。</p>
    </div>
    <div class="row">
      <div class="col-6 offset-3 text-center">
        <router-link :to="{ name: 'Login' }">
          <ButtonForward
            :label-name="$t('buttons.returnToLogin')"
            color="secondary"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonForward from "@/components/atoms/buttons/ButtonForward.vue"

export default {
  name: "ServiceUnavailableContent",
  components: {
    ButtonForward,
  },
}
</script>

<style lang="scss" scoped>
.service-unavailable-content {
  padding: 2.5em;
  min-width: 600px;
  border-style: double;
}
.content {
  padding: 1.5em;
}
.footer {
  justify-content: center;
}
</style>
