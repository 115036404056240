var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-answer-status" }, [
    _c(
      "svg",
      {
        staticStyle: { "enable-background": "new 0 0 512 512" },
        attrs: {
          id: "_x32_",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 512 512",
          "xml:space": "preserve"
        }
      },
      [
        _c("g", [
          _c("path", {
            attrs: {
              d:
                "M219.1,181.5c16.2,0,189.8-21.7,241,70.9c-2.5-75.7-72.4-187.5-241-186.3V0L51.8,126.8l167.3,126.8L219.1,181.5\n              L219.1,181.5z"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M292.8,330.5c-16.2,0-189.8,21.6-241-70.9c2.5,75.7,72.4,187.5,241,186.3V512l167.3-126.8L292.8,258.4V330.5z"
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }