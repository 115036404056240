/**
 * 設定値 Repository
 */
import { apiResponseCode, sukenServiceId } from "../constant/network"
import configApi from "../api/config"
import accountRepository from "../repositories/account"

export default {
  /**
   * コード値・名称変換設定値を取得する
   * @param {Object} accessParameter アクセスパラメータ情報
   * @returns 名称変換設定値情報
   */
  async getNameConversionConfig(accessParameter) {
    const promise = await configApi.getNameConversionConfig(accessParameter)
    if (promise.status === apiResponseCode.ok) {
      promise.data.headerInfo = {
        fileName: "NameConversionList.json",
        urlParameters: accessParameter,
      }
    }
    return promise
  },

  /**
   * パラメータ設定を取得する
   * @param {Object} accessParameter アクセスパラメータ情報
   * @returns パラメータ設定情報
   */
  async getParameterConfig(accessParameter) {
    const promise = await configApi.getParameterConfig(accessParameter)
    if (promise.status === apiResponseCode.ok) {
      promise.data.headerInfo = {
        fileName: "ParameterList.json",
        urlParameters: accessParameter,
      }
    }
    return promise
  },

  /**
   * 各設定値を取得する
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @param {Object} accessParameter アクセスパラメータ情報
   * @returns 各設定値情報
   */
  async getSettings(accountId, sessionToken, accessParameter) {
    let settings = {
      groupIds: {},
      nameConversions: {},
      parameterSettings: {},
      streamStatusList: [],
    }

    // ログインユーザが管理対象のグループ ID 一覧を取得
    const accountRepositoryPromise =
      await accountRepository.getGroupIdsOfSukenAccount(
        sukenServiceId.lms,
        accountId,
        sessionToken
      )

    if (accountRepositoryPromise.status !== apiResponseCode.ok) {
      return Promise.reject(accountRepositoryPromise)
    }

    // コード値・名称変換設定値を取得する
    const nameConversionPromise = await this.getNameConversionConfig(
      accessParameter.nameConversionItems
    )
    if (nameConversionPromise.status !== apiResponseCode.ok) {
      return Promise.reject(nameConversionPromise)
    }

    // パラメータ設定を取得する
    const parameterPromise = await this.getParameterConfig(
      accessParameter.parameterItems
    )

    if (parameterPromise.status !== apiResponseCode.ok) {
      return Promise.reject(parameterPromise)
    }

    return new Promise((resolve) => {
      settings.groupIds = accountRepositoryPromise.data.groupList
      settings.nameConversions = nameConversionPromise.data
      settings.parameterSettings = parameterPromise.data

      resolve(settings)
    })
  },
}
