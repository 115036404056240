/**
 * アカウント関連 API
 * WebAPI が3種類あるが、API に依存せず、アカウントに関する API を定義する
 * ※LMS サーバの API は全て POST メソッド、Request body
 */
import apiInstance from './axiosBase'

export default {
  // sukenAccountWithBooks: {},
  /**
   * Create 系 API
   */

  /**
   * Read 系 API
   */

  /**
   * セッショントークンを取得する
   * @param {String} accountId ID
   * @param {String} password パスワード
   * @returns セッショントークン
   */
  async getSessionToken(accountId, password) {
    let headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${password}`
    }

    try {
      return await apiInstance.axiosStreamBase({
        method: 'get',
        url: `/accounts/${accountId}/token`,
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 数研アカウント情報を取得する
   * @param {Number} serviceId サービス ID（LMS は、2:新デジタル教科書システム）
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @returns 数研アカウント情報
   */
  async getSukenAccountInfo(serviceId, accountId, sessionToken) {
    try {
      // アカウント基盤のクエリストリングの区切り記号が「/」となっているため、
      // URL のパスストリングとして記述
      return await apiInstance.axiosAccountBase({
        method: 'get',
        url: `/account/info/serviceid=${serviceId}&accountid=${accountId}&token=${sessionToken}`,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * LMS サーバ API に認証を実行する
   * @param {String} accountId ID
   * @param {String} apiToken LMS API トークン
   * @returns API トークン
   */
  async getLmsApiToken(accountId, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: 'post',
        url: `/lms/login`,
        data: {
          account_id: accountId,
          key: process.env.VUE_APP_LMS_API_KEY,
          token: apiToken,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 数研アカウントが管理対象のグループ ID 一覧を取得する
   * @param {Number} serviceId サービス ID（LMS は、2:新デジタル教科書システム）
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @returns オブジェクト型（数研アカウント ID、グループ ID の一覧）
   */
  async getGroupIdsOfSukenAccount(serviceId, accountId, sessionToken) {
    try {
      // アカウント基盤のクエリストリングの区切り記号が「/」となっているため、
      // URL のパスストリングとして記述
      return await apiInstance.axiosAccountBase({
        method: 'get',
        url: `/group/list/serviceid=${serviceId}&accountid=${accountId}&token=${sessionToken}`,
      })
    } catch (error) {
      return error.response
    }
  },

  /**
   * グループ ID に紐づくアカウントが所有している教材パッケージの一覧を取得する
   * @param {String} groupId グループ ID
   * @param {String} teacherAccountId 先生アカウント ID
   * @param {Array} curriculums 教科（例 ['ma', 'sa']）
   * @param {String} sessionToken セッショントークン
   * @returns 教材パッケージの一覧
   */
  async getSukenAccountWithBooks(
    groupId,
    teacherAccountId,
    curriculums,
    sessionToken
  ) {

    // let key = `${groupId}-${teacherAccountId}-${curriculums}-${sessionToken}`
    // if (this.sukenAccountWithBooks[key]) {
    //   return this.sukenAccountWithBooks[key]
    // }

    let headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${sessionToken}`
    }

    let paramCurriculums
    if(typeof curriculums === 'object'){
      if(curriculums.length > 1){
        paramCurriculums = Array.from(new Set(curriculums)).join(',')
      }else{
        paramCurriculums = curriculums[0]
      }
    }else{
      paramCurriculums = curriculums
    }


    try {
      const response =  await apiInstance.axiosStreamLmsApiBase({
        method: 'get',
        url: `/groups/${groupId}/accounts/products/books`,
        headers: headers,
        params: {
          teacher_account_id: teacherAccountId,
          curriculums: paramCurriculums,
        },
      })
      // this.sukenAccountWithBooks[key] = response
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },

  /**
   * グループ ID に紐づくアカウントが所有している教材パッケージの閲覧権限を生成する
   * @param {String} groupId グループ ID
   * @param {String} studentAccountId 生徒アカウント ID
   * @param {String} productId 商品 ID
   * @param {String} bookId 教材 ID
   * @param {String} teacherAccountId 先生アカウント ID
   * @param {String} sessionToken セッショントークン
   * @returns 生成された閲覧権限 Cookie
   */
  async getCookieSukenAccountWithBooks(
    groupId,
    studentAccountId,
    productId,
    bookId,
    teacherAccountId,
    sessionToken
  ) {
    let headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${sessionToken}`
    }

    try {
      return await apiInstance.axiosStreamLmsApiBase({
        method: 'post',
        url: `/groups/${groupId}/accounts/${studentAccountId}/products/${productId}/books/${bookId}/credentials`,
        headers: headers,
        params: {
          teacher_account_id: teacherAccountId
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * Update 系 API
   */

  /**
   * Delete 系 API
   */
}