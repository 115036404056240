<template>
  <div class="title-homework">
    <ul class="list-group list-group-horizontal">
      <li class="list-group-item border-0 pt-0 ps-0 pe-2 pb-0">
        <IconHomeworkMono
          width="30px"
          height="30px"
        />
      </li>
      <li class="list-group-item border-0 px-0 pb-0 title-text">
        <h5>{{ $t("titles.homework") }}</h5>
      </li>
      <li class="list-group-item border-0 ps-3 pe-1 pb-0 title-help">
        <ButtonOpenHelp :url="helpLink" />
      </li>
      <li
        v-if="showRequiredMessage"
        class="list-group-item border-0 title-text"
      >
        <label
          class="required-label"
        >
          {{ $t("labels.required") }}
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import IconHomeworkMono from "@/components/atoms/icons/IconHomeworkMono.vue"
import ButtonOpenHelp from "@/components/atoms/buttons/ButtonOpenHelp.vue"

export default {
  name: "TitleHomework",
  components: {
    IconHomeworkMono,
    ButtonOpenHelp,
  },
  props: {
    // ヘルプページへのリンク
    helpLink: {
      type: String,
      default: function () {
        return ""
      },
    },
    showRequiredMessage: {
      type: Boolean,
      default: function(){
        return false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.title-homework {
  font-size: 10px;
  height: 2.3rem;

  .list-group {
    height: 2.3rem;

    .title-text {
      padding-top: 0.1rem;
    }

    .title-help {
      padding-top: 0.1rem;
    }
  }
}
.required-label {
  padding: inherit;
  font-size: 140%;
}
.required-label:before {
  content: "*";
  color: red;
}
</style>
