/**
 * 宿題種別コード
 */
export const homeworkTypeCode = {
  // 教材の問題
  textbook: 1,
  // PDF
  pdf: 2,
  // STDB（レイアウト通り）
  stdbLayout: 3,
  // STDB（１問ずつ）
  stdb: 4,
}

/**
 * 配信ステータス
 */
export const streamStatus = {
  // 作成中
  creating: 0,
  // 配信予定
  waitStream: 1,
  // 配信中
  streaming: 2,
  // 配信終了
  endOfStream: 3,
}

/**
 * 提出ステータス
 */
export const handedStatus = {
  // 未着手
  notProgress: 0,
  // 着手中
  progressing: 1,
  // 提出待ち
  waitHand: 2,
  // 提出済み（未確認）
  handed: 3,
  // 提出済み（確認済み）
  handChecked: 4,
  // 返却済み
  returned: 5,
  // 返却済み（確認済み）
  returnChecked: 6,
  // 差し戻し
  sendBack: 7,
  // 差し戻し（確認済み）
  sendBackCheck: 8
}

/**
 * 結果区分
 */
export const correctResult = {
  // 未回答
  notAnswer: 0,
  // 正解
  correct: 1,
  // 不正解
  incorrect: 2,
}

/**
 * アップロードファイル取得モード
 * ※/api/homework/SlctHaishinFileHis 用
 */
export const streamFileInfoMode = {
  // アップロード一覧
  uploadList: 0,
  // 課題一覧
  questionList: 1,
}

/**
 * ノート種別
 */
export const noteType = {
  /** ノート写真 */
  picture: 0,
  /** 書き込み情報 */
  writeInfo: 1,
}

/**
 * Excel用スタイル
 */
export const excelStyle = {
  // 【共通】枠線
  border: {
    top: { style: 'thin', color: { argb: 'FF000000' } },
    left: { style: 'thin', color: { argb: 'FF000000' } },
    bottom: { style: 'thin', color: { argb: 'FF000000' } },
    right: { style: 'thin', color: { argb: 'FF000000' } },
  },
  // 【共通】ヘッダーの背景色
  headerFill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFEE0B4' },
  },
  // 【共通】ヘッダーのフォント
  headerFont: {
    color: { argb: 'FFFF7F27' },
    bold: true,
  },
  // 【共通】明細の背景色
  detailFill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFDF1E9' },
  },
  // 【全体情報】枠線右なし
  wholeFirstBorder: {
    top: { style: 'thin', color: { argb: 'FF000000' } },
    left: { style: 'thin', color: { argb: 'FF000000' } },
    bottom: { style: 'thin', color: { argb: 'FF000000' } },
  },
  // 【全体情報】枠線左右なし
  wholeEmptyBorder: {
    top: { style: 'thin', color: { argb: 'FF000000' } },
    bottom: { style: 'thin', color: { argb: 'FF000000' } },
  },
  // 【生徒情報】明細の非表示の背景色
  studentDetailDisableFill: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFC5C5C5' },
  },
  // 【生徒情報】明細の正解のフォント
  studentDetailCorrectFont: {
    color: { argb: 'FFFF0000' },
    bold: true,
  },
  // 【生徒情報】明細の不正解のフォント
  studentDetailIncorrectFont: {
    color: { argb: 'FF0070C0' },
    bold: true,
  },
}