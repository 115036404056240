<template>
  <div class="icon-answer-status">
    <svg
      id="_x32_"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      :width="width"
      :height="height"
      viewBox="0 0 512 512"
      style="enable-background:new 0 0 512 512;"
      xml:space="preserve"
    >
      <g>
        <path
          d="M219.1,181.5c16.2,0,189.8-21.7,241,70.9c-2.5-75.7-72.4-187.5-241-186.3V0L51.8,126.8l167.3,126.8L219.1,181.5
                L219.1,181.5z"
        />
        <path d="M292.8,330.5c-16.2,0-189.8,21.6-241-70.9c2.5,75.7,72.4,187.5,241,186.3V512l167.3-126.8L292.8,258.4V330.5z" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconReload',
  props: {
    width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
  },

  data: () => ({
    // アイコンにタイトルを設定する場合は、iconName を表示するようにする
    // ファイル名：グループリロード.svg
    iconName: 'グループリロード',
  }),
}
</script>
