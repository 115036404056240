var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "homework" },
    [
      _c(
        "div",
        { staticClass: "mt-3 mx-4" },
        [
          _c("TitleHomework", {
            attrs: { "help-link": _vm.$t("url.helps.homeworkList") }
          })
        ],
        1
      ),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row justify-content-center mb-4" }, [
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _c("ButtonBorderCircleMedium", {
                attrs: {
                  "label-name": _vm.$t("buttons.createHomework"),
                  color: "layout-theme-light",
                  "label-color": _vm.colorLayoutTheme,
                  "border-color": _vm.colorLayoutTheme
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.createHomework.apply(null, arguments)
                  }
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col" }, [
            _vm.homeworkItems.length > 0
              ? _c(
                  "div",
                  [
                    _c("TableHomework", {
                      attrs: { "homework-list": _vm.homeworkItems },
                      on: { "delete-homework": _vm.deleteHomework }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm.isLoading ? _c("LoadingScreen") : _vm._e(),
      _vm.showComfirm
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.comfirmMessage },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showComfirm = false
              }
            }
          })
        : _vm._e(),
      _vm.sessionDisconnect
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.error.overLimitSessionToken") },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                return _vm.onSessionDisconnect()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }