<template>
  <div class="modal-confirm-ok-only">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container p-4"
          style="overflow: auto; max-height: 80vh;"
        >
          <div class="row h-75 align-items-center">
            <div class="col message-wrapper">
              <p class="message-area">
                {{ message }}
              </p>
            </div>
          </div>
          <div class="row h-25 justify-content-center">
            <div class="col-auto">
              <!-- TODO: 固定サイズ -->
              <ButtonBorderCircleMedium
                :label-name="$t('buttons.ok')"
                color="layout-theme-light"
                :label-color="colorLayoutTheme"
                :border-color="colorLayoutTheme"
                font-size="1.2rem"
                width="10rem"
                @click.native="onClickOk"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 確認ダイアログ
 */
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"

export default {
  name: "ModalConfirmOkOnly",
  components: {
    ButtonBorderCircleMedium,
  },
  props: {
    message: {
      type: String,
      default: function () {
        return ""
      },
    },
  },

  data: () => ({
    colorLayoutTheme: "#ff7f27",
  }),

  methods: {
    async onClickOk() {
      return new Promise(resolve => 
        resolve(this.$emit("close-confirm-modal-ok-only"))
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container {
      width: 520px;
      height: 250px;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border: 2px solid rgba(0, 0, 0, 0.7);
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      text-align: center;

      .message-wrapper {
        height: 100px;
        overflow-y: auto;

        .message-area {
          font-size: 1.22rem;
          white-space: pre-wrap;
        }
      }

      ::v-deep .btn {
        padding: 0.6rem 0.5rem;
      }
    }
  }
}
</style>
