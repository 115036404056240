var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-group-members" },
    [
      _c("label", { class: { "required-label": _vm.isRequire } }, [
        _vm._v("配信先")
      ]),
      _c(
        "multiselect",
        _vm._g(
          {
            attrs: {
              options: _vm.groupMembers,
              multiple: true,
              taggable: true,
              searchable: false,
              loading: _vm.loaded,
              placeholder: "",
              "select-label": "選択",
              "select-group-label": "全員を選択",
              "selected-label": "選択済",
              "deselect-label": "選択解除",
              "deselect-group-label": "全員解除",
              "group-values": "members",
              "group-label": "groupName",
              "group-select": true,
              label: "name",
              "track-by": "code",
              "open-direction": "below"
            },
            on: { select: _vm.onSelect, remove: _vm.onRemove },
            model: {
              value: _vm.selectedGroupMembers,
              callback: function($$v) {
                _vm.selectedGroupMembers = $$v
              },
              expression: "selectedGroupMembers"
            }
          },
          {
            "delete-tag": _vm.onDeleteTag
          }
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }