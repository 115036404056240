<template>
  <div class="d-flex flex-column login-background">
    <div class="login d-flex justify-content-center align-self-center">
      <LoginForm
        :is-authentication-error="isAuthenticationError"
        :is-progressing="isProgressing"
        :is-session-error="isSessionError"
        v-on="{
          login: onLogin,
          'login-for-apple': onLoginForApple,
          'login-for-google': onLoginForGoogle,
          'login-for-microsoft': onLoginForMicrosoft,
        }"
      />
    </div>
  </div>
</template>

<script>
import { db } from "@/dbs/indexedDb"
import { apiResponseCode } from "../constant/network"
import { mapMutations, mapActions, mapGetters } from "vuex"
import LoginForm from "@/components/organisms/LoginForm.vue"
import accountRepository from "../repositories/account"
import configRepository from "../repositories/config"

export default {
  name: "Login",
  components: {
    LoginForm,
  },

  data: () => ({
    // ログイン認証エラーフラグ
    isAuthenticationError: false,
    // 通信中フラグ
    isProgressing: false,
    // セッション切れエラー
    isSessionError: false,
  }),

  computed: {
    ...mapGetters("nameConversions", ["curriculumConversionTable"]),
  },

  async beforeMount() {
    // 一時保管しているデータを全て削除する
    await this.clearStoreDatas()
  },

  async mounted() {
    this.isProgressing = true
    const status = this.$route.params.status
    if (status) {
      let accountId = this.$route.params.accountid
      accountId = accountId.replace(" ", "+")
      const token = this.$route.params.token
      if (Number(status) === apiResponseCode.ok && accountId && token) {
        // ソーシャルログイン実施
        await this.onSocialLogin(accountId, token)
      }
    }
    this.isProgressing = false
  },

  methods: {
    ...mapMutations(["setLoginUserInfo", "setGroupIdsOfLoginUser"]),
    ...mapMutations("homework", [
      "clearHeaderInfo",
      "clearHomeworkDetailItems",
      "clearUploadStdbDetailItems",
      "clearUploadPdfDetailItems",
      "clearHomeworkFileHistoryItems",
      "clearHomeworkSetItems",
      "deleteCheckedStudentList",
    ]),
    ...mapActions("configJson", ["saveConfigSettings"]),

    async clearIndexedDbRecords() {
      await db.selectedStreamGroupMember.clear()
      await db.idbQuestionImage.clear()
      await db.uploadHomeworkDetailItems.clear()
    },

    onLogin: async function (account) {
      this.isProgressing = true
      // ログイン認証を実行する
      accountRepository
        .authenticateLogin(account)
        .then((response) => {
          // ログイン認証 OK
          this.isAuthenticationError = false
          this.isSessionError = false

          // 設定値を取得後に教科IDを変換するのでここではStoreには登録しない
          let loginUserInfo = response.data.loginUserInfo

          // 設定値を取得し、ログイン情報を登録する
          this.registLoginSetting(
            loginUserInfo,
            response.data.settingUrlParameter
          )
        })
        .catch((error) => {
          // 許可されていないユーザ
          if (
            error.status === apiResponseCode.ok &&
            error.data.isForbiddenUser
          ) {
            this.isAuthenticationError = true
          }
          // ログイン認証エラー
          this.errorHandlingForLogin(error.status)
        })
    },
    async onSocialLogin(accountId, token) {
      this.isProgressing = true
      // ログイン認証を実行する
      accountRepository
        .authenticateSocialLogin(accountId, token)
        .then((response) => {
          // ログイン認証 OK
          this.isAuthenticationError = false
          this.isSessionError = false

          // 設定値を取得後に教科IDを変換するのでここではStoreには登録しない
          let loginUserInfo = response.loginUserInfo

          // 設定値を取得し、ログイン情報を登録する
          this.registLoginSetting(loginUserInfo, response.settingUrlParameter)
        })
        .catch((error) => {
          // ログイン認証エラー
          this.errorHandlingForSocialLogin(error.status)
        })
    },
    onLoginForApple() {
      this.$router.push({ name: "SocialLoginApple" })
    },
    onLoginForGoogle() {
      this.$router.push({ name: "SocialLoginGoogle" })
    },
    onLoginForMicrosoft() {
      this.$router.push({ name: "SocialLoginMicrosoft" })
    },
    errorHandlingForLogin(status) {
      this.isProgressing = false
      switch (status) {
        case apiResponseCode.badRequest:
          this.isAuthenticationError = true
          break
        case apiResponseCode.unauthorized:
          this.isAuthenticationError = true
          break
        case apiResponseCode.unauthorizedPassword:
          // 通常402は支払い関係のステータスだが当PJでは特異な使い方を取っている
          this.isAuthenticationError = true
          break
        default:
          this.$router.push({ name: "APIError", params: { status: status } })
          break
      }
    },
    errorHandlingForSocialLogin(status) {
      this.isProgressing = false
      switch (status) {
        case apiResponseCode.badRequest:
          this.isAuthenticationError = true
          break
        case apiResponseCode.unauthorized:
          this.isAuthenticationError = true
          break
        case apiResponseCode.forbidden:
          this.isSessionError = true
          break
        case apiResponseCode.unauthorizedPassword:
          // 通常402は支払い関係のステータスだが当PJでは特異な使い方を取っている
          this.isAuthenticationError = true
          break
        default:
          this.$router.push({ name: "APIError", params: { status: status } })
          break
      }
    },
    async clearStoreDatas() {
      this.clearHeaderInfo()
      this.clearHomeworkDetailItems()
      this.clearUploadStdbDetailItems()
      this.clearUploadPdfDetailItems()
      this.clearHomeworkFileHistoryItems()
      this.clearHomeworkSetItems()
      this.deleteCheckedStudentList()
      window.localStorage.clear()
      window.sessionStorage.clear()
      // IndexedDBの内容をクリアする
      await this.clearIndexedDbRecords()
    },
    // 設定値を取得し、ログイン情報を登録する
    registLoginSetting(loginUserInfo, settingUrlParameter) {
      // 設定値を取得
      configRepository
        .getSettings(
          loginUserInfo.accountId,
          loginUserInfo.sessionToken,
          settingUrlParameter
        )
        .then((response) => {
          // ログイン後の処理
          this.postLoginProcess(response, loginUserInfo)
        })
        .catch((error) => {
          this.errorHandlingForLogin(error.status)
        })
    },
    // ログイン後の処理
    postLoginProcess(response, loginUserInfo) {
      // store に登録
      this.saveConfigSettings(response)

      // 教科IDを変換
      let isError = false
      loginUserInfo.handleCurriculums = loginUserInfo.handleCurriculums.map(
        (code) => {
          let item = this.curriculumConversionTable.find(
            (item) => code == item.code
          )

          if (!item) {
            console.error(`Invalid curriculum code: ${code}`)
            isError = true
          }
          return Number(item.value)
        }
      )

      // 変換できなかったものがあった場合は403エラーとする
      if (isError) {
        throw { status: apiResponseCode.forbidden }
      }

      // ログイン情報を store に登録
      this.setLoginUserInfo({ loginUserInfo: loginUserInfo })

      // グループ ID 一覧をログイン情報 store に追加で登録
      this.setGroupIdsOfLoginUser(response.groupIds)

      // 宿題管理 - 宿題一覧に遷移
      this.$router.push({ name: "Homework" })
    },
  },
}
</script>

<style lang="scss" scoped>
.login-background {
  min-height: 100vh;
  background-color: #e9e9e9;
}

.login {
  width: 100%;
  margin: 0 auto auto;
}
</style>
