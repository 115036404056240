<template>
  <div class="form-date-field">
    <label
      :for="id"
      class="form-label"
      :class="{ 'required-label': isRequire}"
    >{{ labelText }}</label>
    <input
      :id="id"
      v-model="value"
      type="date"
      class="form-control form-control-sm"
      :aria-describedby="help"
      :disabled="disabled"
      :min="dateRange.min"
      :max="dateRange.max"
      @change="onChangeValue"
    >
  </div>
</template>

<script>
export default {
  name: "FormDateField",
  props: {
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
    id: {
      type: String,
      default: function () {
        return ""
      },
    },
    help: {
      type: String,
      default: function () {
        return ""
      },
    },
    initialValue: {
      type: String,
      default: function () {
        return ""
      },
    },
    isRequire: {
      type: Boolean,
      default: function (){
        return false
      }
    },
    disabled: { type: Boolean, default: false },
    dateRange: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  data: function () {
    return {
      value: this.initialValue,
    }
  },

  mounted: function () {
    // 初期値を呼び出し元に返す
    this.$emit("input", this.value)
  },

  methods: {
    onChangeValue() {
      this.$emit("input", this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep label {
  display: inline;
}
.required-label:before { 
   content:"*";
   color:red;
}
</style>
