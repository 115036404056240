var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-homework-progress-students" },
    [
      _c("table", [
        _c("thead", [
          _c(
            "tr",
            [
              _vm._l(_vm.setHeaderItems, function(headerArray, arrayIndex) {
                return _vm._l(headerArray, function(header, index) {
                  return _c(
                    "th",
                    {
                      key: "header-" + arrayIndex + "-" + index,
                      staticClass: "col",
                      class: {
                        first: index === 0,
                        last: index === headerArray.length - 1
                      }
                    },
                    [
                      _c("div", { staticClass: "text-center pre-line" }, [
                        _vm._v(" " + _vm._s(header) + " ")
                      ])
                    ]
                  )
                })
              })
            ],
            2
          )
        ]),
        _c(
          "tbody",
          _vm._l(_vm.viewItems, function(item, index) {
            return _c(
              "tr",
              { key: index, staticClass: "text-center" },
              [
                _vm.showStudentName
                  ? _c("td", { staticClass: "studentName text-center" }, [
                      _vm._v(" " + _vm._s(item.accountName) + " ")
                    ])
                  : _c("td", { staticClass: "studentName text-center" }, [
                      _vm._v(" " + _vm._s(item.anonymousAccountName) + " ")
                    ]),
                _c(
                  "td",
                  { staticClass: "status py-1" },
                  [
                    item.isInGroup
                      ? _c("BadgeStatus", {
                          attrs: {
                            "label-text": item.statusDisplayName,
                            color: item.statusColor
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("td", { staticClass: "stamp" }, [
                  item.isInGroup
                    ? _c("img", {
                        attrs: {
                          src:
                            item.stamp === null
                              ? _vm.gradeStamps[_vm.gradeStamps.length - 1]
                                  .stamp
                              : _vm.gradeStamps[item.stamp - 1].stamp,
                          width: "50"
                        }
                      })
                    : _vm._e()
                ]),
                _c("td", { staticClass: "response-rate" }, [
                  item.isInGroup
                    ? _c("div", { staticClass: "d-flex justify-content-end" }, [
                        _vm._v(
                          " " +
                            _vm._s(!item.responseRate ? 0 : item.responseRate) +
                            " "
                        ),
                        _c("div", { staticClass: "align-self-end percent" }, [
                          _vm._v(" % ")
                        ])
                      ])
                    : _vm._e()
                ]),
                _c("td", { staticClass: "correct-answer-rate" }, [
                  item.isInGroup
                    ? _c("div", { staticClass: "d-flex justify-content-end" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              !item.responseCorrectRate
                                ? 0
                                : item.responseCorrectRate
                            ) +
                            " "
                        ),
                        _c("div", { staticClass: "align-self-end percent" }, [
                          _vm._v(" % ")
                        ])
                      ])
                    : _vm._e()
                ]),
                _c("td", { staticClass: "response-correct-rate" }, [
                  item.isInGroup
                    ? _c("div", { staticClass: "d-flex justify-content-end" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              !item.correctAnswerRate
                                ? 0
                                : item.correctAnswerRate
                            ) +
                            " "
                        ),
                        _c("div", { staticClass: "align-self-end percent" }, [
                          _vm._v(" % ")
                        ])
                      ])
                    : _vm._e()
                ]),
                _c("td", { staticClass: "check" }, [
                  item.isInGroup
                    ? _c("div", [
                        item.status === _vm.handedStatus.notProgress ||
                        item.status === _vm.handedStatus.progressing ||
                        item.status === _vm.handedStatus.waitHand
                          ? _c("div")
                          : item.status === _vm.handedStatus.handed
                          ? _c(
                              "div",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.checkStudentData(index)
                                  }
                                }
                              },
                              [_c("ButtonProgressUnconfirm")],
                              1
                            )
                          : _c(
                              "div",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.checkStudentData(index)
                                  }
                                }
                              },
                              [_c("ButtonProgress")],
                              1
                            )
                      ])
                    : _vm._e()
                ]),
                _vm._l(item.results, function(resultArray, arrayIndex) {
                  return _vm._l(resultArray, function(result, childIndex) {
                    return _c(
                      "td",
                      {
                        key: arrayIndex + "-" + childIndex,
                        staticClass: "col",
                        class: [
                          {
                            first: childIndex === 0,
                            last: childIndex === resultArray.length - 1
                          },
                          !result || result.disableFlg
                            ? "child-question bg-disable"
                            : "child-question bg-layout-theme-light"
                        ]
                      },
                      [
                        result && !result.disableFlg
                          ? _c("div", [
                              result.result === _vm.correctResult.notAnswer
                                ? _c(
                                    "div",
                                    [
                                      _c("IconDash", {
                                        attrs: { width: "25px" }
                                      })
                                    ],
                                    1
                                  )
                                : result.result === _vm.correctResult.correct
                                ? _c(
                                    "div",
                                    [
                                      _c("IconCircle", {
                                        attrs: {
                                          width: "25px",
                                          "color-style": _vm.correctColor
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : result.result === _vm.correctResult.incorrect
                                ? _c(
                                    "div",
                                    [
                                      _c("IconClose", {
                                        attrs: {
                                          width: "25px",
                                          "color-style": _vm.incorrectColor
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          : _c("div")
                      ]
                    )
                  })
                })
              ],
              2
            )
          }),
          0
        )
      ]),
      _vm.showStudentDataModal
        ? _c(
            "ModalStudentHandedData",
            _vm._g(
              {
                attrs: {
                  "student-data": _vm.viewItems,
                  stamps: _vm.gradeStamps,
                  "student-num": _vm.studentNum,
                  "submit-index-list": _vm.submitIndexList,
                  "show-student-name": _vm.showStudentName,
                  "teacher-comment": _vm.teacherComment,
                  "haishin-start-date": _vm.haishinStartDate,
                  "homework-details": _vm.homeworkDetails,
                  "ques-image-list": _vm.quesImageList,
                  "config-page-teacher": _vm.configPageTeacher,
                  "cond-teacher": _vm.condTeacher
                },
                on: {
                  "update:quesImageList": function($event) {
                    _vm.quesImageList = $event
                  },
                  "update:ques-image-list": function($event) {
                    _vm.quesImageList = $event
                  },
                  "update:configPageTeacher": function($event) {
                    _vm.configPageTeacher = $event
                  },
                  "update:config-page-teacher": function($event) {
                    _vm.configPageTeacher = $event
                  },
                  "update:condTeacher": function($event) {
                    _vm.condTeacher = $event
                  },
                  "update:cond-teacher": function($event) {
                    _vm.condTeacher = $event
                  }
                }
              },
              {
                close: _vm.closeModalStudentHandedData,
                "on-click-return-of": _vm.onClickReturnOf,
                "on-click-send-back": _vm.onClickSendBack,
                "on-click-student-data-transfer": _vm.checkStudentData,
                "on-change-show-student-name": function($event) {
                  _vm.$emit("on-change-show-student-name", $event)
                },
                "on-reload": _vm.onReload
              }
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }