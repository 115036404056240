var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button-group-reload" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-black btn-sm",
        style: _vm.buttonStyle,
        attrs: { type: "button" }
      },
      [
        _c("IconReload", {
          staticClass: "d-inline",
          attrs: { width: "16px", height: "16px" }
        }),
        _vm._v(" " + _vm._s(_vm.$t("buttons.refleshGroupList")) + " ")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }