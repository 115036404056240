var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "login-form d-flex justify-content-center login-form-fontFamily"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex-fill d-flex flex-column align-items-center justify-content-center mt-5 mb-3 mx-4"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "logo-wrap d-flex flex-column align-items-center text-center mb-3"
            },
            [
              _c("SViewerLogo", {
                staticClass: "d-flex justify-content-center mb-2"
              }),
              _c(
                "h2",
                { staticClass: "mb-3 sviewer-logo-title" },
                [
                  _c("font", { attrs: { color: _vm.colorLayoutTheme } }, [
                    _vm._v(" " + _vm._s(_vm.$t("labels.loginTitle")) + " ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6 login-form-area" }, [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center border-bottom mb-3"
                    },
                    [
                      _c("h6", [
                        _vm._v(_vm._s(_vm.$t("labels.signinWithSuken")))
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex acount-login-form" },
                    [
                      _c("ValidationObserver", { ref: "form" }, [
                        _c(
                          "form",
                          {
                            staticClass: "acount-login-form-margin",
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.onSubmit.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mb-3 text-start" },
                              [
                                _c(
                                  "ValidationProvider",
                                  {
                                    attrs: {
                                      name: "id",
                                      mode: "passive",
                                      rules:
                                        "required|min:1|max:40|possibleLoginId"
                                    }
                                  },
                                  [
                                    _c("FormLoginIdField", {
                                      attrs: {
                                        id: "userName",
                                        "label-text": "ID",
                                        "is-disabled": _vm.isProgressing
                                      },
                                      model: {
                                        value: _vm.idValue,
                                        callback: function($$v) {
                                          _vm.idValue = $$v
                                        },
                                        expression: "idValue"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-3 text-start" },
                              [
                                _c(
                                  "ValidationProvider",
                                  {
                                    attrs: {
                                      name: "password",
                                      mode: "passive",
                                      rules:
                                        "required|min:1|possibleLoginPassword"
                                    }
                                  },
                                  [
                                    _c("FormLoginPasswordField", {
                                      attrs: {
                                        id: "password",
                                        "label-text": _vm.$t("labels.password"),
                                        "is-disabled": _vm.isProgressing
                                      },
                                      model: {
                                        value: _vm.password,
                                        callback: function($$v) {
                                          _vm.password = $$v
                                        },
                                        expression: "password"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "mb-3" }, [
                              _vm.isAuthenticationError
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(_vm.authenticationErrorMessage)
                                    )
                                  ])
                                : _vm.isSessionError
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "messages.error.overLimitSessionToken"
                                        )
                                      )
                                    )
                                  ])
                                : _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(_vm.validateMessage))
                                  ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-4 mb-4 login-submit" },
                              [
                                _c("ButtonSubmit", {
                                  attrs: {
                                    "label-name": _vm.$t("buttons.login"),
                                    color: _vm.colorLayoutTheme,
                                    "label-color": "white",
                                    "is-disabled": _vm.isDisabledButtonSubmit
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-3 forgetPassword-style" },
                              [
                                _c("LinkText", {
                                  staticClass: "font-small",
                                  attrs: {
                                    "link-url": _vm.helpUrl,
                                    "label-text": _vm.$t(
                                      "labels.forgotPassword"
                                    )
                                  }
                                }),
                                _c(
                                  "p",
                                  { staticClass: "text-muted font-small" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("labels.transferMessage")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "login-form-border" }, [
                              _c("span")
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "col-6 platform-login" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column content-height" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center border-bottom mb-4"
                      },
                      [
                        _c("h6", [
                          _vm._v(_vm._s(_vm.$t("labels.signinWithSocial")))
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center mb-4 apple-btn-size"
                      },
                      [
                        _c("ButtonAppleLogin", {
                          attrs: { width: "100%" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onClickLoginForApple.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center mb-4 google-btn-size"
                      },
                      [
                        _c("ButtonGoogleLogin", {
                          attrs: { width: "100%" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onClickLoginForGoogle.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center microsoft-btn-size"
                      },
                      [
                        _c("ButtonMicrosoftLogin", {
                          attrs: { width: "100%" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onClickLoginForMicrosoft.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "d-flex flex-row align-self-end terms" },
            [
              _c("LinkText", {
                staticClass: "font-small",
                attrs: {
                  "link-url": _vm.linkUrl,
                  "label-text": _vm.$t("labels.termsOfService")
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "d-flex flex-row align-self-end mt-2" }, [
            _c("div", { staticClass: "text-muted terms-text" }, [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("labels.ifLoginAssumeGiveConsent")))
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }