/**
 * セッション関連 API
 */
import apiInstance from './axiosBase'

export default {
  /**
  * Create 系 API
  */

  /**
  * Read 系 API
  */

  /**
   * システム日時を取得する
   * @param {String} accountId ID
   * @param {String} apiToken API トークン
   * @returns システム日時
   */
  async getSystemDateTime(accountId, apiToken) {
    try {
      // 存在しない学校 ID をセットして、結果が0件になるようにする
      const noSuchSchoolId = 0
      return await apiInstance.axiosLmsBase({
        method: 'post',
        url: `/homework/search`,
        data: {
          account_id: accountId,
          school_id: noSuchSchoolId,
          token: apiToken,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
  * Update 系 API
  */

  /**
  * Delete 系 API
  */

}