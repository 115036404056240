var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "homework-progress", staticStyle: { overflow: "auto" } },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row mt-3 mb-2 justify-content-around" }, [
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _c("TitleHomework", {
                attrs: { "help-link": _vm.$t("url.helps.homeworkProgress") }
              })
            ],
            1
          ),
          _c("div", { staticClass: "col text-center homework-name py-2" }, [
            _vm._v(" " + _vm._s(_vm.homeworkName) + " ")
          ]),
          _c("div", { staticClass: "col-auto" }, [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("labels.startDateReplaceWords", {
                        date: "：" + _vm.params.startDate
                      })
                    ) +
                    " "
                )
              ]),
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("labels.deadlineDateReplaceWords", {
                        date: "：" + _vm.params.deadlineDate
                      })
                    ) +
                    " "
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "row justify-content-around" }, [
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "Homework" } } },
                    [
                      _c("ButtonReturn", {
                        attrs: {
                          "label-name": _vm.$t("buttons.returnToList"),
                          "label-color": "#ff7f27",
                          "border-color": "#ff7f27"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("ButtonExportProgress", {
                    attrs: {
                      "label-name": _vm.$t("buttons.fileExport"),
                      color: "layout-theme-light",
                      "label-color": _vm.colorLayoutTheme,
                      "border-color": _vm.colorLayoutTheme
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onClickExport(_vm.item)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col text-end py-2" },
                [
                  _c("CheckBox", {
                    ref: "showStudentNameCheckBox",
                    attrs: { label: "生徒名を表示", "initial-checked": true },
                    on: { input: _vm.onChangedShowStudentNameCheckBox }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "w-100 p-3 pt-0" }, [
              _c("div", { staticClass: "row mb-4" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("TableHomeworkProgressWhole", {
                      attrs: {
                        "header-items": !_vm.isLoading
                          ? _vm.homeworkHeaderItems
                          : [],
                        "child-question-rate-items": !_vm.isLoading
                          ? _vm.childQuestionRateItems
                          : [],
                        "book-items": _vm.bookItems,
                        "student-items": _vm.studentItems,
                        "homework-details": _vm.homeworkDetails
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "TableHomeworkProgressStudents",
                      _vm._g(
                        {
                          attrs: {
                            "header-items": !_vm.isLoading
                              ? _vm.homeworkHeaderItems
                              : [],
                            items: !_vm.isLoading
                              ? _vm.homeworkProgressItems
                              : [],
                            "view-items": !_vm.isLoading
                              ? _vm.viewProgressItems
                              : [],
                            "grade-stamps": _vm.stamps,
                            "teacher-comment": _vm.teacherComment,
                            "haishin-start-date": _vm.haishinStartDate,
                            "show-student-name": _vm.showStudentName,
                            "progress-params": _vm.params,
                            "homework-details": _vm.homeworkDetails,
                            "ques-image-list": _vm.quesImageList,
                            "config-page-teacher": _vm.configPageTeacher,
                            "cond-teacher": _vm.condTeacher
                          },
                          on: {
                            "update:quesImageList": function($event) {
                              _vm.quesImageList = $event
                            },
                            "update:ques-image-list": function($event) {
                              _vm.quesImageList = $event
                            },
                            "update:configPageTeacher": function($event) {
                              _vm.configPageTeacher = $event
                            },
                            "update:config-page-teacher": function($event) {
                              _vm.configPageTeacher = $event
                            },
                            "update:condTeacher": function($event) {
                              _vm.condTeacher = $event
                            },
                            "update:cond-teacher": function($event) {
                              _vm.condTeacher = $event
                            }
                          }
                        },
                        {
                          "on-click-return-of": _vm.onClickReturnOf,
                          "on-click-send-back": _vm.onClickSendBack,
                          "on-checked-student-data": _vm.onCheckedStudentData,
                          "on-change-show-student-name":
                            _vm.onChangedShowStudentNameCheckBox,
                          "on-reload": _vm.reloadStudentsInfo
                        }
                      )
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]),
      _vm.isLoading ? _c("LoadingScreen") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }