<template>
  <div class="icon-camera">
    <svg
      id="_x32_"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px" 
      :width="width"
      :height="height"
      viewBox="0 0 512 512"
      style="enable-background:new 0 0 512 512;"
      xml:space="preserve"
    >

      <g>
        <path
          style="fill:#EE7C2F;"
          d="M256,220.8c-35.9,0-65,29.4-65,65.6c0,36.2,29.1,65.6,65,65.6s65-29.3,65-65.6
          C321,250.1,291.9,220.8,256,220.8z"
        />
        <path
          style="fill:#EE7C2F;"
          d="M496.8,135.6c-9.3-9.5-22.5-15.4-36.9-15.4h-56.4c-4.5,0-8.8-2.7-10.7-7.1l-16.9-37.8
          c-8.4-18.7-26.9-30.9-47.6-30.9H183.7c-20.6,0-39.2,12.2-47.6,30.9l-16.9,37.8c-2,4.4-6.2,7.1-10.7,7.1H52.1
          c-14.4,0-27.6,6-36.9,15.4C5.8,145,0,158.2,0,172.6v242.7c0,14.4,5.8,27.5,15.2,37c9.4,9.5,22.6,15.4,36.9,15.4h224.5h183.2
          c14.4,0,27.5-5.9,36.9-15.4c9.4-9.4,15.2-22.6,15.2-37V172.6C512,158.2,506.2,145,496.8,135.6z M339.5,370.3
          c-21.3,21.5-50.9,34.9-83.5,34.9c-32.6,0-62.2-13.4-83.5-34.9c-21.3-21.5-34.5-51.3-34.5-84c0-32.7,13.2-62.5,34.5-84
          c21.3-21.5,50.9-34.9,83.5-34.9c32.6,0,62.2,13.4,83.5,34.9c21.3,21.5,34.5,51.3,34.5,84C374,319.1,360.8,348.9,339.5,370.3z
          M462,214.5h-35.8v-35.8H462V214.5z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconCamera',
  props: {
    width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
  },

  data: () => ({
    // アイコンにタイトルを設定する場合は、iconName を表示するようにする
    // ファイル名：宿題進捗_カメラ(icooon-mono).svg
    iconName: 'カメラ'
  }),
}
</script>