<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from "vuex"

export default {
  name: "App",

  watch: {
    $route(routeInstance) {
      this.setMetaInfo(routeInstance)
    },
  },

  mounted: function () {
    // スタンプ情報は一旦初期化
    this.clearStamps()

    this.$nextTick(function () {
      this.setMetaInfo(this.$route)
    })
  },

  methods: {
    ...mapMutations("gradeStamp", ["clearStamps"]),

    /**
     * meta タグをセットする
     */
    setMetaInfo: function (routeInstance) {
      // title
      if (typeof routeInstance.meta.title !== "undefined") {
        document.title = routeInstance.meta.title
      }
      // description
      if (typeof routeInstance.meta.description !== "undefined") {
        const description = routeInstance.meta.description
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", description)
      }
      // keywords
      if (typeof routeInstance.meta.keywords !== "undefined") {
        const keywords = routeInstance.meta.keywords
        document
          .querySelector("meta[name='keywords']")
          .setAttribute("content", keywords)
      }
      // robots
      if (typeof routeInstance.meta.robots !== "undefined") {
        const robots = routeInstance.meta.robots
        document
          .querySelector("meta[name='robots']")
          .setAttribute("content", robots)
      }
    },
  },
}
</script>

<style lang="scss">
html,
body {
  height: 100%;
}

#app {
  height: 100%;
}
</style>
