/**
 * 日本語（デフォルト）
 */

const messages = {
  ja: {
    /**
     * タイトル
     */
    titles: {
      homework: "宿題管理",
      question: "表示制御",
    },

    /**
     * ラベル
     */
    labels: {
      // システムのタイトル、コピーライト等
      managementSite: "先生用サイト",
      copyright: "Copyright(c)2022 Suken Shuppan All rights reserved.",
      // コピーライトが丸記号の表記
      copyrightCircle: "Copyright©2022 Suken Shuppan All rights reserved.",
      termsOfService: "利用規約",
      forgotPassword: "ID・パスワードがわからない場合や新規登録はこちら",

      // LMS 関連
      group: "グループ",
      streamData: "配信データ",
      homeworkName: "宿題名",
      textbook: "教材",
      curriculum: "教科",
      subject: "科目",
      answerStatus: "解答状況",
      addTextbookQuestion: "教材の問題を追加",
      handedDate: "提出日",
      handedDateReplaceWords: "提出日時：",
      startDate: "開始日",
      startDateReplaceWords: "開始日{date}",
      deadlineDate: "締切日",
      deadlineDateReplaceWords: "締切日{date}",
      expired: "期限超過",
      overDeadLine: "締切超過",
      addStdbQuestion: "Studyaid D.B. プリントを追加",
      addPdfQuestion: "PDFを追加",
      uploadList: "アップロード一覧",
      textbookQuestionMessage:
        "※項目の表示／非表示は、表示制御で設定した内容が反映されます。",
      questionManagementMessage:
        "※設定した項目の表示／非表示は、宿題管理で配信する宿題にも反映されます。",
      fileDragAndDropOrFileSelect:
        "この枠内にファイルをドラッグ＆ドロップしてください　または、ボタンよりファイルを選択してください",
      selectStreamDataTheUploadListFirst: "アップロード一覧から配信する",
      selectStreamDataTheUploadListLate: "データを選択してください",

      howToDisplayInViewer: "ビューアでの表示方法",
      oneQuestion: "一問ずつ",
      answer: "答",
      explanation: "解説",
      followLayout: "レイアウト通り",
      questionQuantity: "問題数",
      questionNumber: "{number}問",

      taskName: "課題名",
      sendMemo: "送信メモ（この内容は送信されません）",
      lmsMaxByte: "{maxByte}GB中",
      lmsNowByte: "{nowByte}GB(Studyaid D.B. プリント:{stdbByte}GB PDF:{pdfByte}GB)",
      lmsByteUse: "を使用中",
      requiredV290:
        "※「返却（やり直し）」を生徒が受信するには、エスビューアのバージョンが2.9.0以降である必要があります。",
      recommendPageMessage:
        "※1ファイルあたりのページ数は5ページ以内を推奨しています。それを超える場合、生徒が受信する際に時間がかかります。",

      // その他汎用
      dear: "様",
      password: "パスワード",
      comment: "コメント",
      photo: "写真",
      note: "ノート",
      preview: "プレビュー",
      pleaseSelect: "選択してください",
      selectAll: "すべて選択",
      required: "は必須項目です",

      // ログイン関連
      loginTitle: "先生用サイト",
      signinWithSuken: "数研アカウントでログイン",
      signinWithSocial: "他のサービスIDでログイン",
      transferMessage: "異動した場合も上記をご確認ください",
      ifLoginAssumeGiveConsent:
        "※ログインすると利用規約に同意したものとみなします。",
      socialLogin: {
        serviceId: {
          stdb: "1",
          sviewer: "2",
          chartLab: "3",
        },
        appId: {
          sviewer: {
            browser: "1",
            windows: "2",
            iPad: "3",
            lms: "4",
          },
          stdb: {
            browser: "1",
            desktopApp: "2",
          },
        },
      },
    },

    /**
     * ボタン名
     */
    buttons: {
      ok: "OK",
      cancel: "キャンセル",
      login: "ログイン",
      logout: "ログアウト",
      save: "保存",
      register: "登録",
      stream: "配信",
      detail: "詳細",
      returnOf: "返却（完了）",
      sendBack: "返却（やり直し）",
      createHomework: "宿題を新規作成",
      addStreamData: "配信データを追加",
      questionOfTextbook: "教材の問題",
      stdbPrint: "Studyaid D.B. プリント",
      pdf: "PDF",

      fileSelect: "ファイル選択",
      returnToList: "一覧に戻る",
      returnToLogin: "ログイン画面へ",
      toInquiry: "問い合わせる",
      jumpToSukenMypage: "数研アカウントマイページ",
      checkEveryonesHandedImages: "全員の提出写真を確認",
      refleshGroupList: "グループの更新",

      erase: "削除",
      fileExport: "Excelへ出力",
    },

    /**
     * プレースホルダー
     */
    placeholder: {
      teacherComment: "生徒へのコメントを入力",
      returnComment: "生徒へのコメントを入力",
    },

    /**
     * ステータス
     */
    status: {
      // 配信ステータス
      stream: {
        making: "作成中",
        scheduledStream: "配信予定",
        streaming: "配信中",
        endOfStream: "配信終了",
      },
    },

    /**
     * メッセージ
     */
    messages: {
      // 確認
      confirm: {
        deleteHomeworkReplaceWords:
          "「{homeworkName}」を削除します。\nよろしいですか？",
        deleteHomeworkOnChangeGroupOrCurriculum:
          "以下の課題が削除されますがよろしいですか？\n",
        streamTargetMemberHasNotBook:
          "以下の教材の問題を所有していない生徒が配信先に含まれています。\nその生徒には課題が表示されません。\nこのまま配信してもよろしいですか？\n",
        notSavedChanges: "保存されていない変更があります。\nよろしいですか？",
        refleshGroupList:
          "グループ情報を更新します。\n入力内容が破棄されますが、よろしいですか？ ",
        deleteUploadFile:
          "「{fileName}」を削除します。\n配信中の宿題からも削除されますが、よろしいですか？",
        cancelSendBack:
          "宿題のやり直し指示が解除されます。\nよろしいですか？",
        eraseAllWriteInfo:
          "先生の書き込みをすべて削除しますか？\n※生徒の書き込みは削除されません。",
      },
      // 正常終了
      success: {
        register: "登録しました。",
        save: "保存しました。\n※まだ配信はされていません。\n  配信設定する場合は、「配信」を実行してください。",
        publish: "配信設定しました。",
        return: "{studentName}へ返却しました",
        sendBack: "{studentName}へ返却し、宿題のやり直しを指示しました",
        refleshGroupList: "グループを更新しました。",
        registerWithCaution:
          "@:messages.success.register \n生徒のエスビューアで本棚画面から開き直してください。 \n登録内容が反映されない場合は、一度ログアウトして再度ログインを行ってください。",
      },
      // エラー
      error: {
        incorrectIdOrPassword: "IDまたはパスワードが正しくありません。",
        // ログイン認証エラーメッセージ
        // ログイン時の ID、パスワードが一致しないエラーメッセージと同じ内容とする
        loginAuthentication: "@:messages.error.incorrectIdOrPassword",
        // 登録失敗
        register: "登録に失敗しました。",
        notSelectedGroupAndCurriculum: "グループと教科を選択してください。",
        overHomeworkStreamCountLimit:
          "登録されている課題が上限を超えています。",
        homeworkStreamListIsEmpty: "配信するデータを追加してください。",
        validateRequiredParameters: "必須項目が正しく設定されていません。",
        validateDateRange:
          "開始日または締切日の日付の設定が正しくありません。\n 開始日：本日～1か月後の範囲で設定してください。 \n 締切日：[開始日]～{maxDeadlineDate}か月後の範囲で設定してください。",
        overLimitHomeworkCount:
          "宿題作成数が上限に到達しています。既存の宿題を削除してから、再度お試しください。",
        failerSave: "保存に失敗しました。",
        failerPublish: "配信設定に失敗しました。",
        canNotChangeSelectedTextbook:
          "選択した教材は、設定の変更ができません。",
        canNotPublishSelectedTextbook:
          "選択した教材は、配信することができません。",
        canNotGetSelectTextbook: "選択した教材が取得できませんでした。",
        canNotGetTextbook: "担当教科に紐づく教材が取得できませんでした。",
        canNotGetStudent: "グループに所属している生徒が取得できませんでした。",
        notShowStudent: "生徒には表示されません。",
        notExistQuestionStream:
          "以下の配信先は、対象の教材を所有していません。\n{streamGroup}",
        overLimitTextBookHomeWorkCount:
          "登録できる最大数（{limit}件）を超えるため、追加できません。",
        overLimitSessionToken:
          "セッションの有効期限が切れました。\n 再度ログインしてください。",
        changedGroup:
          "グループ情報に変更があったため、グループ情報を更新します。",
        changedGroupConfirm:
          "グループ情報に変更があったため、グループ情報を更新します。\n入力内容が破棄されますが、よろしいですか？",
        notExistGroupForHomework:
          "グループ情報が削除されたため、宿題を削除します。",
        canNotGetPreviewFile:
          "ファイルの取得に失敗しました。\n 配信先に設定している生徒のライセンス登録状況を確認してください。",
        displayPDFOnly:
          "表示できるのは以下のファイル形式のみです。\n・PDFファイル（.pdf）",
        displaySTDBOnly:
          "表示できるのは以下のファイル形式のみです。\n・PrinTファイル（.spr/.spo）",
        overTextLength: "{maxLength}字以内で入力してください。",
        required: "必須項目を入力してください。",
        overRegistMax: "登録できる最大数（{maxCount}件）を超えています。",
        overFileSizeForDownload:
          "ファイルサイズが大きいため、処理ができませんでした。\nファイルを分割して再度お試しください。",
        overMaximumLimitSize:
          "アップロード可能な容量が上限を超えています。\n不要なファイルを削除して、保存容量を空けてから再度お試しください。",
        over20MBsize:
          "ファイルサイズが20MBを超えています。\nファイルサイズ：{fileSize}MB\nもう一度ファイルを選択してください。",
        stdbFileRead:
          "ファイルの読み込みに失敗しました。\nファイルが破損している可能性があります。",
        pdfFileRead:
          "ファイルの読み込みに失敗しました。\nファイルが破損しているかパスワードがかかっている可能性があります。",
        uploadPublicEndPreview:
          "ファイルの取得に失敗しました。\nアップロードしたファイルの公開終了日を過ぎた、もしくはファイルの削除操作をした場合、該当のファイルに紐づく課題は配信ができないため、課題を削除してください。\n",
        uploadPublicEndPreviewForPublish:
          "ファイルの取得に失敗しました。\nアップロードしたファイルの公開終了日を過ぎた、もしくはファイルの削除操作をした場合、該当のファイルに紐づく課題は開けません。\n",
        uploadPublicEndRegist:
          "以下の課題のファイルの取得に失敗しました。\nアップロードしたファイルの公開終了日を過ぎた、もしくはファイルの削除操作をした場合、該当のファイルに紐づく課題は配信ができないため、課題を削除してください。\n{fileNames}",
        notSelectedPresentation:
          "指定したファイルはエスビューアで表示できる形式ではありません。\nエスビューアでご利用になるにはStudyaid D.B.プリント作成システムでの保存時に「プレゼンテーションに対応する」のチェックを入れてください。",
        notExistUploadFileForPublishOrSave:
          "以下の課題のファイルの取得に失敗しました。\nアップロードしたファイルの公開終了日を過ぎた、もしくはファイルの削除操作をした場合、該当のファイルに紐づく課題は配信ができないため、課題を削除してください。\n{taskName}",
        notExistUploadFileForPublishOrSaveAlreadyPublished:
          "ファイルの取得に失敗しました。\nアップロードしたファイルの公開終了日を過ぎた、もしくはファイルの削除操作をした場合、該当のファイルを使用している宿題は変更ができません。",
        exlusiveUpdateHomework:
          "新しい宿題の提出があったため、返却できませんでした。\n編集していた内容は破棄されます。",
        overWriteInfoSize:
          "\nこれ以上書き込めません。",
      },
    },

    /**
     * サイドメニューの表示
     */
    sideMenu: {
      homework: "宿題管理",
      question: "表示制御",
      group: "グループ管理",
      help: "ヘルプ",
      terms: "利用規約",
    },

    /**
     * URL
     */
    url: {
      // グループ管理ページ
      groupManagement: "https://account.chart.co.jp/group/list",
      // 各種ヘルプページ
      helps: {
        top: "https://www.chart.co.jp/software/lms/manual.html",
        homeworkList: "https://www.chart.co.jp/software/lms/manual.html#hms",
        homeworkRegister:
          "https://www.chart.co.jp/software/lms/manual.html#hms",
        addQuestion: "https://www.chart.co.jp/software/lms/manual.html#hms",
        homeworkProgress:
          "https://www.chart.co.jp/software/lms/manual.html#hms",
        question: "https://www.chart.co.jp/software/lms/manual.html#access",
      },
      // 利用規約ページ
      terms: "https://www.chart.co.jp/software/digital/sviewer/terms.html",
    },
  },
}

export default messages
