// db.js
import Dexie from 'dexie';

export const db = new Dexie('managementSite');
db.version(2).stores({
  selectedStreamGroupMember: 'id, groupMemberItems, isStreamMySelf', // Primary key and indexed props
  uploadHomeworkDetailItems: 'id, ansDispFlg, checkFlg, endPubDate, endUseDate, explainDispFlg, externalFilePath, fileStatusFlg, haishinFileNo, homeworkEdaNo, mainQuestionInfo, questionCount, questionType, sendMemo, subQuestionInfo, taskName', // Primary key and indexed props
  idbQuestionImage: 'id, uploadFileList', // Primary key and indexed props
});
db.version(1).stores({
  selectedStreamGroupMember: 'id, groupMemberItems, isStreamMySelf', // Primary key and indexed props
});
