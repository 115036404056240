<template>
  <div>
    <button
      type="button"
      :class="['btn', 'btn-' + color]"
      :style="buttonStyle"
    >
      <div class="d-flex flex-row">
        <IconExport
          width="25px"
          height="25px"
        />
        &nbsp;
        {{ labelName }}
      </div>
    </button>
  </div>
</template>

<script>
import IconExport from "@/components/atoms/icons/IconExport.vue"

export default {
  name: "ButtonExportProgress",
  components: {
    IconExport,
  },
  props: {
    labelName: {
      type: String,
      default: function () {
        return ''
      },
    },
    color: {
      type: String,
      default: function () {
        return ''
      },
    },
    labelColor: {
      type: String,
      default: function () {
        return ''
      },
    },
    borderColor: {
      type: String,
      default: function () {
        return ''
      },
    },
  },

  computed: {
    buttonStyle: function () {
      return {
        color: this.labelColor,
        border: `solid 1px ${this.borderColor}`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 2rem;
  padding: 0.5em 2em 0.3em;
  font-weight: bold;
}
</style>