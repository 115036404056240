var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-date-field" }, [
    _c(
      "label",
      {
        staticClass: "form-label",
        class: { "required-label": _vm.isRequire },
        attrs: { for: _vm.id }
      },
      [_vm._v(_vm._s(_vm.labelText))]
    ),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value,
          expression: "value"
        }
      ],
      staticClass: "form-control form-control-sm",
      attrs: {
        id: _vm.id,
        type: "date",
        "aria-describedby": _vm.help,
        disabled: _vm.disabled,
        min: _vm.dateRange.min,
        max: _vm.dateRange.max
      },
      domProps: { value: _vm.value },
      on: {
        change: _vm.onChangeValue,
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.value = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }