var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-header-label", on: { click: _vm.onClick } },
    [
      _c("div", { staticClass: "text-center" }, [
        _vm._v(" " + _vm._s(_vm.labelText) + " "),
        _vm.sortKey && _vm.isSelected
          ? _c("span", [
              _vm.sortOrder === 1
                ? _c("span", [_vm._v(" ▲ ")])
                : _c("span", [_vm._v(" ▼ ")])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }