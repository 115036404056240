<template>
  <div
    ref="topElement"
    class="write-info"
    :style="{
      top: isTeacherMode ? 0 : '-80px',
    }"
  >
    <!-- ペン -->
    <div
      class="write-info_pen-canvas"
      :style="{
        height: isTeacherMode ? '100%' : penCanvasHeight+'px',
      }"
    >
      <template v-for="(penInf, pneIdx) in pens">
        <div
          :key="pneIdx"
          class="write-info_pen-instance"
        >
          <svg
            vesion="1.1"
            xmlns="http://www.w3.org/2000/svg"
            class="write-info_pensvg"
            :viewBox="`0 0 ${penViewBoxWidth} ${penViewBoxHeight}`"
            :style="{'z-index': penInf.zIndex}"
          >
            <path
              v-if="penInf.type==='path'"
              :style="{
                stroke: penColors[penInf.color],
                'stroke-width': penSizes[penInf.size],
                opacity: penOpacities[penInf.opacity],
              }"
              style="
                fill: rgba(255,255,255,0);
                stroke-linecap: round;
                stroke-linejoin: round;
              "
              :class="{
                'write-info_pen-detail': !isDrawModeErase,
                'write-info_pen-detail-erase': isDrawModeErase,
              }"
              :d="penInf.path"
            />
            <line
              v-if="penInf.type==='line'"
              :style="{
                stroke: penColors[penInf.color],
                'stroke-width': penSizes[penInf.size],
                opacity: penOpacities[penInf.opacity],
              }"
              :x1="penInf.x1"
              :y1="penInf.y1"
              :x2="penInf.x2"
              :y2="penInf.y2"
              :class="{
                'write-info_pen-detail': !isDrawModeErase,
                'write-info_pen-detail-erase': isDrawModeErase,
              }"
            />
            <line
              v-if="penInf.type==='vartHori'"
              :style="{
                stroke: penColors[penInf.color],
                'stroke-width': penSizes[penInf.size],
                opacity: penOpacities[penInf.opacity]
              }"
              :x1="penInf.x1"
              :y1="penInf.y1"
              :x2="penInf.x2"
              :y2="penInf.y2"
              :class="{
                'write-info_pen-detail': !isDrawModeErase,
                'write-info_pen-detail-erase': isDrawModeErase,
              }"
            />
            <rect
              v-if="penInf.type==='rect'"
              :style="{
                stroke: penColors[penInf.color],
                'stroke-width': penSizes[penInf.size],
                opacity: penOpacities[penInf.opacity],
              }"
              style="fill: rgba(255,255,255,0);"
              :x="penInf.x"
              :y="penInf.y"
              :width="penInf.width"
              :height="penInf.height"
              :class="{
                'write-info_pen-detail': !isDrawModeErase,
                'write-info_pen-detail-erase': isDrawModeErase,
              }"
            />
            <ellipse
              v-if="penInf.type==='ellipse'"
              :style="{
                stroke: penColors[penInf.color],
                'stroke-width': penSizes[penInf.size],
                opacity: penOpacities[penInf.opacity],
              }"
              style="fill: rgba(255,255,255,0);"
              :cx="penInf.cx"
              :cy="penInf.cy"
              :rx="penInf.rx"
              :ry="penInf.ry"
              :class="{
                'write-info_pen-detail': !isDrawModeErase,
                'write-info_pen-detail-erase': isDrawModeErase,
              }"
            />
          </svg>
        </div>
      </template>
    </div>
    <!-- テキスト(ふせん)(表示専用) -->
    <div
      v-if="!(isDrawable && isDrawModeText && editable)"
      class="write-info_text-canvas"
    >
      <template v-for="(textInf, textIdx) in texts">
        <div
          ref="textInstance"
          :key="textIdx"
          class="write-info_text-instance"
          :style="{top: textInf.y + 'px', left: textInf.x + 'px', 'z-index': textInf.zIndex}"
        >
          <textarea
            ref="textarea"
            v-model="textInf.text"
            readonly
            autocapitalize="none"
            spellcheck="false"
            class="write-info_text-input-area"
            :class="{'write-info_font_serif': textInf.textFont === 1}"
            style="user-select: none;"
            :style="{
              'pointer-events': isDrawModeNone || (isTeacherMode && (isDrawModeText || isDrawModeErase)) ? 'auto' : 'none',
              height: (parseFloat(textInf.height)+textareaCalibrationHeight)+'px',
              width: (parseFloat(textInf.width)+textareaCalibrationWidth)+'px',
              color: textColors[textInf.textColor],
              'font-size': (textFontSizes[textInf.textSize]/pageRate)+'px',
              'background-color': `rgba(${textBackColors[textInf.textBackColor]})`,
              'border-color': isTeacherMode && isDrawModeErase ? 'revert' : 'rgba(0,0,0,0)', 
            }"
          />
        </div>
      </template>
    </div>
    <!-- スタンプ -->
    <div class="write-info_stamp-canvas">
      <template v-for="(stampInf, stampIdx) in writeInfo.stamps">
        <div
          :key="stampIdx"
          class="write-info_stamp-instance"
          :style="{
            top: stampInf.y + 'px',
            left: stampInf.x + 'px',
            width: stampSizes[stampInf.size]/pageRate + 'px',
            height: stampSizes[stampInf.size]/pageRate + 'px',
            fill: stampColors[stampInf.color],
            'z-index': stampInf.zIndex
          }"
          v-html="getStampSvg(stampInf.stampIdx)"
        >
        </div>
      </template>
    </div>
    <!-- テキスト作成時ドラッグ矩形 -->
    <div
      v-if="creatingTextRect && creatingTextRect.display"
      class="text-create-rect"
      :style="{
        top: creatingTextRect.y+'px',
        left: creatingTextRect.x+'px',
        width: creatingTextRect.width+'px',
        height: creatingTextRect.height+'px'
      }"
    />
    <!-- ユーザー操作用 -->
    <div
      ref="uiArea"
      class="write-info_ui-area"
      :class="{peN: isDraggingText}"
    >
      <!-- テキスト(ふせん)(操作用)-->
      <template v-for="(textInf, textIdx) in texts">
        <div
          v-if="isDrawable && isDrawModeText && editable"
          :key="textIdx"
          class="write-info_text-instance"
          :style="{ top: textInf.y + 'px', left: textInf.x + 'px', 'z-index': textInf.zIndex }"
        >
          <!-- 
            readonly属性は普段はOFFにしておき、クリック時（タップ時）にオンにする。
            ただし、iPadの場合は、readonlyをONに切り替えてもソフトウェアキーボードが出ないため
            readonly属性はOFFのままにしておく。
            ※iPadではreadonly属性がOFFのままでも、
           -->
          <textarea
            ref="textarea"
            v-model="textInf.text"
            :readonly="!isIPad()"
            autocapitalize="none"
            spellcheck="false"
            class="write-info_text-input-area"
            :class="{'write-info_font_serif': textInf.textFont === 1}"
            :style="{
              height: textInf.height + 'px',
              width: textInf.width + 'px',
              color: textColors[textInf.textColor],
              'font-size': (textFontSizes[textInf.textSize]/pageRate) + 'px',
              'background-color': `rgba(${textBackColors[textInf.textBackColor]})`,
            }"
            @click="onTextareaClick(textIdx)"
            @focus="onTextareaFocus(textIdx)"
            @blur="onTextareaBlur(textIdx)"
          />
          <img
            ref="textareaResizeHandle"
            src="@/assets/images/tools/textPanel/resize_hunde.png"
            alt="リサイズ"
            class="resize-handle"
            :style="{
              top: textInf.height + 'px',
              left: textInf.width + 'px',
            }"
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { homeworkTypeCode } from "@/constant/homework"

export default {
  name: 'HomeworkWriteInfo',
  props: {
    pointerElement: {
      type: Element,
      required: false,
      default: null,
    },
    isTeacherMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    writeInfo: {
      type: Object,
      required: true,
    },
    imgWidth: {
      type: Number,
      required: true,
    },
    imgHeight: {
      type: Number,
      required: true,
    },
    pageRate: {
      type: Number,
      required: false,
      default: 1,
    },
    configPage: {
      type: Object,
      required: false,
      default: null,
    },
    cond: {
      type: Object,
      required: false,
      default: null,
    },
    baseScale: {
      type: Number,
      required: false,
      default: 1,
    },
    homeworkSyubetuKbn: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    writeInfoObjectToString: {
      type: Function,
      required: false,
      default: null
    }
  },
  data: function(){
    return {
      topElement: null,
      uiArea: null,
      pens: this.writeInfo.pens,
      texts: this.writeInfo.texts,
      stamps: this.writeInfo.stamps,
      currentPen: null,
      dragInfo: null,
      creatingTextRect: null,
      resizeTargetTextarea: null,
      lastFocusTextareaZIndex: NaN,
      focusTextareaZIndex: NaN,
      focusResizeHandleZIndex: NaN,
      isDragMoved: false,
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters('homework',[
      'toolbarInfoOfwriteInfo',
    ]),
    penCanvasHeight: {
      get() {
        let h = this.imgHeight;
        if (!this.isTeacherMode) {
          switch (this.homeworkSyubetuKbn) {
            case homeworkTypeCode.textbook:   h += 25; break;
            case homeworkTypeCode.pdf:        h += 25; break;
            case homeworkTypeCode.stdbLayout: h += 25; break;
            case homeworkTypeCode.stdb:       h += 25; break;
          }
        }
        return h;
      },
    },
    penViewBoxWidth:{
      get(){
        let w = this.imgWidth*this.pageRate;
        if(isNaN(w)){
          w = 0;
        }
        return w;
     }
    },
    penViewBoxHeight:{
      get(){
        let h = 0;
        if (this.isTeacherMode) {
          h = this.imgHeight * this.pageRate;
        } else {
          h = (this.imgHeight + 25) * this.pageRate;
        }
        if (isNaN(h)) {
          h = 0;
        }
        return h;
     }
    },
    toolbarInfo:{
      get(){
        return this.toolbarInfoOfwriteInfo ? this.toolbarInfoOfwriteInfo : {};
      },
      set(value){
        this.setToolbarInfoOfWriteInfo(value);
      }
    },
    lastZIndex:{
      get(){
        let lastZIndex = 0;
        lastZIndex = this.pens.reduce((a,b)=>a.zIndex > b.zIndex ? a : b , {zIndex: lastZIndex}).zIndex;
        lastZIndex = this.texts.reduce((a,b)=>a.zIndex > b.zIndex ? a : b , {zIndex: lastZIndex}).zIndex;
        lastZIndex = this.stamps.reduce((a,b)=>a.zIndex > b.zIndex ? a : b , {zIndex: lastZIndex}).zIndex;
        return lastZIndex;
      }
    },
    isDrawable:{
      get(){
        if (this.toolbarInfo) {
          return true;
        }else{
          return false;
        }
      }
    },
    toolType:{
      get(){
        if(this.isDrawable){
          return this.toolbarInfo.toolType;
        }else{
          return '';
        }
      },
    },
    isDrawModePen:{
      get(){
        return this.toolType === 'pen';
      },
    },
    isDrawModeText:{
      get(){
        return this.toolType === 'text';
      },
    },
    isDrawModeStamp:{
      get(){
        return this.toolType === 'stamp';
      },
    },
    isDrawModeErase:{
      get(){
        return this.toolType === 'erase';
      },
    },
    isDrawModeNone:{
      get(){
        return !(this.isDrawModePen || this.isDrawModeText || this.isDrawModeStamp || this.isDrawModeErase);
      }
    },
    penConfig:{
      get(){
        return this.cond.penconfig;
      },
    },
    penColors:{
      get(){
        return this.penConfig.penColor;
      },
    },
    penSizes:{
      get(){
        return this.penConfig.penSize;
      },
    },
    penOpacities:{
      get(){
        return this.penConfig.penOpacity;
      },
    },
    textConfig:{
      get(){
        return this.cond.textconfig;
      },
    },
    textColors:{
      get(){
        return this.textConfig.textColor;
      },
    },
    textBackColors:{
      get(){
        return this.textConfig.textBackColor;
      },
    },
    textFontSizes:{
      get(){
        return this.textConfig.textFontSize;
      },
    },
    stampConfig:{
      get(){
        return this.cond.stampconfig;
      },
    },
    stampColors:{
      get(){
        return this.stampConfig.stampColor;
      },
    },
    stampSizes:{
      get(){
        return this.stampConfig.stampSize;
      },
    },
    stampSvgs:{
      get(){
        return this.stampConfig.stampSvg;
      },
    },
    isDraggingText:{
      get(){
        return this.resizeTargetTextarea || this.creatingTextRect;
      },
    },
    toolbarTextInfo:{
      get(){
        return this.toolbarInfo && this.toolbarInfo.textInfo ? this.toolbarInfo.textInfo : {};
      },
    },
    textColor:{
      get(){
        return this.toolbarTextInfo.color;
      },
    },
    textFontSize:{
      get(){
        return this.toolbarTextInfo.size;
      },
    },
    textFont:{
      get(){
        return this.toolbarTextInfo.font;
      },
    },
    scale: {
      get() {
        return this.toolbarInfo ? this.toolbarInfo.scale : 1;
      },
    },
    limitWriteInfoSize: {
      get() {
        if(!this.editable){
          return 0;
        }
        if(this.cond && Number.isInteger(this.cond.limitWriteInfoSize)){
          return this.cond.limitWriteInfoSize;
        }else{
          // json未設定時の規定値
          return 100000;
        }
      },
    },
    textareaCalibrationWidth: {
      get() {
        // 生徒書き込みの14の内訳
        //   padding: 5px;
        //   border: 2px;
        // (5 + 2) * 2 左右分で2倍
        return this.isTeacherMode ? 0 : 14;
      }
    },
    textareaCalibrationHeight: {
      get() {
        // 生徒書き込みの14の内訳
        //   padding: 5px;
        //   border: 2px;
        // (5 + 2) * 2 上下分で2倍
        return this.isTeacherMode ? 0 : 14;
      }
    },
  },
  watch: {
    pointerElement(newValue){
      if(newValue && this.isDrawable && this.editable){
          this.pointerElement.addEventListener('touchstart', this.dragStart, {passive:true});
          this.pointerElement.addEventListener('mousedown', this.dragStart);
      }
    },
    writeInfo(newValue, oldValue){
      if(oldValue){
        if(this.isDrawable){
          oldValue.pens = this.pens;
          oldValue.texts = this.texts;
          oldValue.stamps = this.stamps;
        }
      }
      if(newValue){
        this.pens = newValue.pens;
        this.texts = newValue.texts;
        this.stamps = newValue.stamps;
        if(!this.writeInfo.historyInfo){
          this.saveHistory();
        }else{
          this.setToolbarUndoRedo();
        }
      }
    },
    toolType(newValue, oldValue){
      if(oldValue === 'text' || newValue === 'text'){
        // ツールがテキストになったときもしくはテキストが解除された時
        // 最後にフォーカスのあったテキストを忘れる
        this.lastFocusTextareaZIndex = NaN;
      }
    },
    textColor(newValue){
      // 文字色を変更したときはフォーカスのあるテキストエリアにも適用する
      if(isNaN(this.lastFocusTextareaZIndex)){
        return;
      }
      // zIndexで探して値を反映する
      this.texts.some((text, idx)=>{
        if(text.zIndex === this.lastFocusTextareaZIndex){
          // 値反映
          text.textColor = newValue;
          // Vueのリアクティブの特性で配列内を書き換えた場合は
          // push,pop,shift,unshift,splice,sort.reverse等のメソッド使用しないと画面に反映されない
          this.texts.splice();
          // 再フォーカス
          this.$refs.textarea[idx].focus();
          if(text.text){
            // ヒストリー保存
            this.saveHistory();
          }
          return true;
        }
        return false;
      })
    },
    textFontSize(newValue){
      // フォントサイズを変更したときはフォーカスのあるテキストエリアにも適用する
      if(isNaN(this.lastFocusTextareaZIndex)){
        return;
      }
      // zIndexで探して値を反映する
      this.texts.some((text, idx)=>{
        if(text.zIndex === this.lastFocusTextareaZIndex){
          // 値反映
          text.textSize = newValue;
          // Vueのリアクティブの特性で配列内を書き換えた場合は
          // push,pop,shift,unshift,splice,sort.reverse等のメソッド使用しないと画面に反映されない
          this.texts.splice();
          // 再フォーカス
          this.$refs.textarea[idx].focus();
          if(text.text){
            // ヒストリー保存
            this.saveHistory();
          }
          return true;
        }
        return false;
      })
    },
    textFont(newValue){
      // フォントを変更したときはフォーカスのあるテキストエリアにも適用する
      if(isNaN(this.lastFocusTextareaZIndex)){
        return;
      }
      // zIndexで探して値を反映する
      this.texts.some((text, idx)=>{
        if(text.zIndex === this.lastFocusTextareaZIndex){
          // 値反映
          text.textFont = newValue;
          // Vueのリアクティブの特性で配列内を書き換えた場合は
          // push,pop,shift,unshift,splice,sort.reverse等のメソッド使用しないと画面に反映されない
          this.texts.splice();
          // 再フォーカス
          this.$refs.textarea[idx].focus();
          if(text.text){
            // ヒストリー保存
            this.saveHistory();
          }
          return true;
        }
        return false;
      })
    },
    dragInfo(newValue){
      if(newValue){
        // ドラッグ開始
        // 描画開始イベントを送出
        this.$emit('drawStart');
      }else{
        // ドラッグ終了
        // 描画終了イベントを送出
        this.$emit('drawEnd');
      }
    },
  },
  mounted: function(){
    this.topElement = this.$refs.topElement;
    this.uiArea = this.$refs.uiArea;
  },
  beforeDestroy: function () {
    if(this.pointerElement){
      this.pointerElement.removeEventListener('touchstart', this.dragStart);
      this.pointerElement.removeEventListener('touchmove', this.dragMove);
      document.body.removeEventListener('touchend', this.dragEnd);
      document.body.removeEventListener('touchcancel', this.dragEnd);
      this.pointerElement.removeEventListener('mousedown', this.dragStart);
      this.pointerElement.removeEventListener('mousemove', this.dragMove);
      this.pointerElement.removeEventListener('mouseleave', this.dragEnd);
      document.body.removeEventListener('mouseup', this.dragEnd);
    }
    if(this.isDrawable){
      this.writeInfo.pens = this.pens;
      this.writeInfo.texts = this.texts;
      this.writeInfo.stamps = this.stamps;
    }
  },
  methods: {
    ...mapActions('homework',[
      'setToolbarInfoOfWriteInfo',
    ]),
    getStampSvg(stampIdx){
      const stampConfig = this.cond.stampconfig;
      if (stampConfig.stampSvg[stampIdx].split('<>')[1] && stampConfig.stampSvg[stampIdx].split('<>')[1].replaceAll('"', "'")) {
        return stampConfig.stampSvg[stampIdx].split('<>')[1].replaceAll('"', "'");
      }else{
        return '';
      }
    },
    dragStart(e){
      this.isDragMoved = false;
      if(this.isSelectingZoomRange){
        // ズーム用の範囲選択中は無視
        return;
      }
      if(!this.editable){
        // 編集不可の場合は無視
        return;
      }
      if(this.isDrawModeNone){
        // 描画モードでない場合は無視
        return;
      }
      if (e instanceof TouchEvent) {
        if (e.touches.length > 1) {
          // タッチ点が2点以上はドラッグを強制終了(キャンセル扱い)
          this.dragEnd(e, true);
          return;
        }
      } else if (e instanceof MouseEvent) {
          ;
      } else {
        return;
      }

      const p = this.getPoint(e);
      if (!this.existsPointerElement(p)) {
        // 領域外のドラッグはドラッグ終了
        this.dragEnd(e);
        return;
      }
      this.dragInfo = {
          startPoint: p,
          previousPoint: null,
      }
      const target = this.getTarget(e);

      switch(true){
        case this.isDrawModePen:
          this.movePen(p);
          this.dragInfo.isDrawn = true;
          break;
        case this.isDrawModeText:
          {
            if(this.$refs.textareaResizeHandle && this.$refs.textareaResizeHandle.some(h=>h===target)){
              // テキストのリサイズハンドルを触った
              const idx = this.$refs.textareaResizeHandle.findIndex(h=>h===target);
              const text = this.texts[idx];
              this.resizeTargetTextarea = {
                original: this.cloneObject(text),
                ref: text,
              };
              this.focusResizeHandleZIndex = text.zIndex;
            }else if(this.$refs.textarea && this.$refs.textarea.some(t=>t===target)){
              // テキストを触った
              // テキスト移動モード
              const textArea = this.$refs.textarea.find(t=>t===target);
              const textInstance = textArea.parentElement;
              const zIndex = parseInt(textInstance.style.zIndex);
              const text = this.texts.find(text=>text.zIndex === zIndex);

              // 編集中は移動しない
              // iPadはisEditing、それ以外はreadOnly属性で判定
              if ((!this.isIPad() && !textArea.readOnly) || (this.isIPad() && this.isEditing)) {
                this.dragEnd(e);
                return;
              }

              this.dragInfo.movingText = text;
              this.dragInfo.movingTextPoint = {
                x: text.x,
                y: text.y,
              }
              const histories = this.writeInfo.historyInfo.histories;
              const lastHistory = histories[histories.length - 1];
              const lastText = lastHistory.texts.find(t=>t.zIndex === text.zIndex);
              if(lastText){
                // 最終履歴に同一テキストあり
                if(lastText.text !== text.text){
                  // 最終テキストと現在のテキストが異なる場合は履歴保存
                  this.saveHistory();
                }
              }else{
                // 最終履歴に同一テキストなし
                if(text.text){
                  // 現在のテキストがある場合は履歴保存
                  this.saveHistory();
                }
              }
            }else{
              if(Number.isNaN(this.lastFocusTextareaZIndex)){
                // テキスト作成開始
                this.createText(p);
                this.dragInfo.isDrawn = true;
              }else{
                // フォーカス解除のみとする
                this.lastFocusTextareaZIndex = NaN;
                this.dragEnd(e);
                return;
              }
            }
          }
          break;
        case this.isDrawModeErase:
          {
            if(this.$refs.textarea && this.$refs.textarea.some(t=>t===target)){
              // テキストを触った場合
              const idx = this.$refs.textarea.findIndex(t=>t===target);
              this.texts.splice(idx, 1);
              // 履歴保存
              this.saveHistory();
            }else{
              // 上記以外は座標から削除
              this.erasePoint(p);
            }
          }
          break;
        default:
          // 上記以外はドラッグ終了
          this.dragEnd(e);
          return;
      }
      if (e instanceof TouchEvent) {
        this.pointerElement.addEventListener('touchmove', this.dragMove, {passive:true});
        document.body.addEventListener('touchend', this.dragEnd);
        document.body.addEventListener('touchcancel', this.dragEnd);
      } else if (e instanceof MouseEvent) {
        this.pointerElement.addEventListener('mousemove', this.dragMove);
        this.pointerElement.addEventListener('mouseleave', this.dragEnd);
        document.body.addEventListener('mouseup', this.dragEnd);
      }
      this.dragInfo.previousPoint = p;
    },
    dragMove(e){
      this.isDragMoved = true;
      if (e instanceof TouchEvent) {
        if (e.touches.length > 1) {
          // タッチ点が2点以上はドラッグを強制終了(キャンセル扱い)
          this.dragEnd(e, true);
        }
      } else if (e instanceof MouseEvent) {
          ;
      } else {
        return;
      }
      const target = this.getTarget(e);
      const p = this.getPoint(e);
      if(!this.dragInfo){
        return;
      }
      if(p.identifier !== this.dragInfo.startPoint.identifier){
        // 最初とタッチ識別子が異なる場合はドラッグ終了
        this.dragEnd(e);
        return;
      }
      if (!this.existsPointerElement(p)) {
        // 領域外のドラッグはドラッグ終了
        this.dragEnd(e);
        return;
      }
      switch(true){
        case this.isDrawModePen:
          this.movePen(p);
          break;
        case this.isDrawModeText:
          {
            if(this.resizeTargetTextarea){
              // テキストリサイズ中
              this.resizeText(p);
            }else if(this.dragInfo.movingText){
              // テキスト移動中
              this.moveText(p);
            }else if(this.creatingTextRect){
              // テキスト作成中
              this.createText(p);
            }
          }
          break;
        case this.isDrawModeErase:
          {
            if(this.$refs.textarea && this.$refs.textarea.some(t=>t===target)){
              // テキストを触った場合
              const idx = this.$refs.textarea.findIndex(t=>t===target);
              this.texts.splice(idx, 1);
              // 履歴保存
              this.saveHistory();
            }else{
              // 上記以外は座標から削除
              this.erasePoint(p);
            }
          }
          break;
        default:
          // 上記以外はドラッグ終了
          this.dragEnd(e);
          return;
      }
      this.dragInfo.previousPoint = p;
    },
    dragEnd(e, cancel=false){
      try {
        if(!this.dragInfo){
          return;
        }
        if (this.isDrawModePen) {
          const p = this.getPoint(e);
          this.movePen(p, true);
        }
        if (e instanceof TouchEvent) {
          if (e.touches.length > 1) {
            // タッチ点が2点以上はドラッグを強制終了(キャンセル扱い)
            cancel = true;
          }
        }
        if (cancel) {
          // キャンセル
          if (this.isDrawModePen) {
            if (this.pens.length > 0) {
              const pen = this.pens[this.pens.length - 1];
              if (pen.penInfo.type === 'path') {
                // フリーハンドの場合はストロークの距離を求める
                const minDistance = 10;
                const points = pen.points;
                let distance = 0;
                for (let i = 1; i < points.length; i++) {
                  const p1 = points[i - 1];
                  const p2 = points[i];
                  const x = p2.x - p1.x;
                  const y = p2.y - p1.y;
                  distance += Math.sqrt(x * x + y * y);
                  if (distance > minDistance) {
                    break;
                  }
                }
                if (distance <= minDistance) {
                  // 短い場合はストロークをなかったことにし、履歴登録しない
                  this.pens.splice(this.pens.length - 1, 1);
                  if (this.dragInfo) {
                    this.dragInfo.isDrawn = false;
                  }
                }
              }
            }
          }
          return;
        }
        switch (true) {
          case this.isDrawModeText:
            if (this.resizeTargetTextarea) {
              // テキストリサイズ確定
              const org = this.resizeTargetTextarea.original;
              const ref = this.resizeTargetTextarea.ref;
              if (org.width !== ref.width || org.height !== ref.height) {
                // リサイズ開始時とサイズが異なる場合
                this.dragInfo.isDrawn = true;
              }
            } else if (this.dragInfo.movingText) {
              // テキスト移動確定
              const startP = this.dragInfo.movingTextPoint;
              const text = this.dragInfo.movingText;
              if (text.x != startP.x || text.y !== startP.y) {
                // 移動している場合
                this.dragInfo.isDrawn = true;
              }
            } else if (this.creatingTextRect) {
              // テキスト作成確定
              this.commitTextRect();
            }
            break;
          default:
            break;
        }
      } finally {
        if (this.dragInfo && this.dragInfo.isDrawn) {
          this.saveHistory();
        }
        this.dragInfo = null;
        this.currentPen = null;
        this.resizeTargetTextarea = null;
        this.pointerElement.removeEventListener('touchmove', this.dragMove);
        document.body.removeEventListener('touchend', this.dragEnd);
        document.body.removeEventListener('touchcancel', this.dragEnd);
        this.pointerElement.removeEventListener('mousemove', this.dragMove);
        this.pointerElement.removeEventListener('mouseleave', this.dragEnd);
        document.body.removeEventListener('mouseup', this.dragEnd);

        // テキストのリサイズハンドルを触っていた場合は
        // 対象のテキストエリアにフォーカスする
        if(!isNaN(this.focusResizeHandleZIndex)){
          const idx = this.$refs.textareaResizeHandle.findIndex(h=>h.parentElement.style.zIndex==this.focusResizeHandleZIndex);
          if(idx >= 0){
            this.$refs.textarea[idx].focus();
          }
        }
        this.focusResizeHandleZIndex = NaN;
      }
    },
    getTarget(e){
      let targetInfo;
      if (e instanceof TouchEvent) {
        if(this.dragInfo && this.dragInfo.startPoint){
          targetInfo = Object.values(e.touches).find(t=>t.identifier === this.dragInfo.startPoint.identifier);
        }else{
          targetInfo = e.touches[0];
        }
      } else if (e instanceof MouseEvent) {
        targetInfo = e;
      }else{
        return null;
      }
      return targetInfo.target;
    },
    getPoint(e){
      let targetInfo;

      if (e instanceof TouchEvent) {
        const touches = e.touches && e.touches.length > 1 ? e.touches : e.changedTouches || e.touches;
        if(this.dragInfo && this.dragInfo.startPoint){
          targetInfo = Object.values(touches).find(t=>t.identifier === this.dragInfo.startPoint.identifier);
        }else{
          targetInfo = touches[0];
        }
      } else if (e instanceof MouseEvent) {
        targetInfo = e;
      }else{
        return null;
      }
      const topElementRect = this.topElement.getBoundingClientRect();
      const p = {
        identifier: targetInfo.identifier == undefined ? -1 : targetInfo.identifier,
        offsetX: targetInfo.offsetX,
        offsetY: targetInfo.offsetY,
        clientX: targetInfo.clientX,
        clientY: targetInfo.clientY,
        pageX: targetInfo.pageX,
        pageY: targetInfo.pageY,
        layerX: targetInfo.clientX - topElementRect.x,
        layerY: targetInfo.clientY - topElementRect.y,
        x: targetInfo.clientX,
        y: targetInfo.clientY,
        movementX: targetInfo.movementX,
        movementY: targetInfo.movementY,
      };

      // ペン用の座標計算

      // スケール
      const scale = this.toolbarInfo.scale;

      // 100%表示時の当コンポーネントの実画面サイズ
      const fullRealSize = {
        w: this.imgWidth * this.baseScale,
        h: this.imgHeight * this.baseScale,
      };

      // 現在の表示サイズでの当コンポーネントの実画面サイズ
      const scaledRealSize = {
        w: fullRealSize.w * scale,
        h: fullRealSize.h * scale,
      };

      // 100%スケール表示からの位置補正分
      // ※HomeWorkNoteImage親要素の横幅に合わした初期サイズが
      // 
      const offset = {
        // x: fullRealSize.w * (scale - 1) / 2,
        // y: fullRealSize.h * (scale - 1) / 2,
        x: 0,
        y: 0,
      };

      p.x = this.penViewBoxWidth * ((p.layerX + offset.x) / scaledRealSize.w);
      p.y = this.penViewBoxHeight * ((p.layerY + offset.y) / scaledRealSize.h);

      return p;
    },
    getDragTarget(e){
      let targetInfo;
      if (e instanceof TouchEvent) {
        const touches = e.touches && e.touches.length > 1 ? e.touches : e.changedTouches || e.touches;
        if(this.dragInfo && this.dragInfo.startPoint){
          targetInfo = Object.values(touches).find(t=>t.identifier === this.dragInfo.startPoint.identifier);
        }else{
          targetInfo = touches[0];
        }
      } else if (e instanceof MouseEvent) {
        targetInfo = e;
      }else{
        return null;
      }
      return targetInfo.target;
    },
    existsPointerElement: function (p) {
      // 指定座標にpointerElementが存在するか確認
      return document.elementsFromPoint(p.pageX, p.pageY).includes(this.pointerElement);
    },
    eraseAll: function(){
      let isCleared = false;
      if(this.pens.length > 0){
        this.pens.splice(0, this.pens.length);
        isCleared = true;
      }
      if(this.texts.length > 0){
        this.texts.splice(0, this.texts.length);
        isCleared = true;
      }
      if(this.stamps.length > 0){
        this.stamps.splice(0, this.stamps.length);
        isCleared = true;
      }
      if(isCleared){
        // 履歴保存
        this.saveHistory();
      }
    },
    /**
     * 新しくペン情報を作成する
     * @param {*} p 座標
     */
    createPen(p){
      const penInfo = this.toolbarInfo.penInfo;
      const pen = {
        penInfo: penInfo,
        type: penInfo.type,
        size: penInfo.size,
        color: penInfo.color,
        opacity: penInfo.opacity,
        zIndex: this.lastZIndex + 1,
      }
      switch(penInfo.type){
        case "path":
          pen.points = [p];
          pen.path = '';
          this.createPenPath(pen);
          break;
        case "line":
        case "vartHori":
          pen.x1 = this.roundDecimalPlaces(p.x);
          pen.y1 = this.roundDecimalPlaces(p.y);
          pen.x2 = this.roundDecimalPlaces(p.x);
          pen.y2 = this.roundDecimalPlaces(p.y);
          break;
        case "rect":
          pen.x = this.roundDecimalPlaces(p.x);
          pen.y = this.roundDecimalPlaces(p.y);
          pen.width = 0;
          pen.height = 0;
          break;
        case "ellipse":
          pen.cx = this.roundDecimalPlaces(p.x);
          pen.cy = this.roundDecimalPlaces(p.y);
          pen.rx = 0;
          pen.ry = 0;
          break;
      }
      this.pens.push(pen);
      this.currentPen = pen;
    },
    /**
     * ペンの移動
     * @param {*} p 座標
     */
    movePen(p, isUp = false) {
      if (!this.currentPen) {
        // ペン作成
        this.createPen(this.dragInfo.startPoint);
      }

      if (!this.currentPen) {
        return;
      }

      this.dragInfo.isDrawn = true;

      const penInfo = this.toolbarInfo.penInfo;
      const pen = this.currentPen;
      switch (penInfo.type) {
        case "path":
          {
            pen.points.push(p);
            this.createPenPath(pen, isUp);
          }
          break;
        case "line":
          pen.x2 = this.roundDecimalPlaces(p.x);
          pen.y2 = this.roundDecimalPlaces(p.y);
          break;
        case "vartHori":
          {
            pen.x2 = this.roundDecimalPlaces(p.x);
            pen.y2 = this.roundDecimalPlaces(p.y);
            const rect = this.getRect(this.dragInfo.startPoint, p);
            if (rect.width > rect.height) {
              // 矩形が横長の場合はy2は開始点と同じにする
              pen.y2 = pen.y1;
            } else {
              // 矩形が縦長の場合はx2は開始点と同じにする
              pen.x2 = pen.x1;
            }
          }
          break;
        case "rect":
          {
            const rect = this.getRect(this.dragInfo.startPoint, p);
            pen.x = rect.x;
            pen.y = rect.y;
            pen.width = rect.width;
            pen.height = rect.height;
          }
          break;
        case "ellipse":
          {
            // 楕円の中心点は矩形の中央
            const startP = this.dragInfo.startPoint;
            const w = p.x - startP.x;
            const h = p.y - startP.y;
            pen.cx = this.roundDecimalPlaces(startP.x + w / 2);
            pen.cy = this.roundDecimalPlaces(startP.y + h / 2);
            pen.rx = this.roundDecimalPlaces(Math.abs(w) / 2);
            pen.ry = this.roundDecimalPlaces(Math.abs(h) / 2);
          }
          break;
      }
    },
    /**
     * ペンのパスを生成する
     */
    createPenPath(pen, isUp = false){
      // SVGCatmullRomSplineで扱える座標形式にする
      const points = pen.points.map(p=>[p.x, p.y]);
      // 滑らかさ
      const tolerance = 1;
      // 最高品質
      const highestQuality = true;
      
      let path = window.SVGCatmullRomSpline.toPath(points, tolerance, highestQuality);
      // --------------------------------------------------------
      // <iPad対応>
      // パスの終盤の座標が同じだと角丸にならない現象が発生するため、
      // ペンアップ時に終盤の同じ座標の要素を削除する
      // --------------------------------------------------------
      if (isUp) {
        var atts = path.split("C");
        while (atts.length > 1) {
          try {
            var a = atts[atts.length -1].split(" ");
            var b = atts[atts.length -2].split(" ");
            if (a[2] === b[2]) {
              atts.pop();
            } else {
              break;
            }	
          } catch (e) {
            console.log(e);
            break;
          }
        }
        path = atts.join("C");
      }
      // 座標の小数点以下を丸める
      pen.path = path.replaceAll(/[1-9][0-9]*\.[0-9]+/g,(match)=>{
        return this.roundDecimalPlaces(parseFloat(match)).toString();
      });
    },
    createText(p){

      const rect = this.getRect(this.dragInfo.startPoint, p, 'layer', true, 1 / (this.baseScale * this.scale));
      const dist = Math.sqrt(rect.width * rect.width + rect.height * rect.height);
      rect.display = dist > 3;

      this.creatingTextRect = rect;
    },
    resizeText(p){
      const rect = this.getRect(this.dragInfo.startPoint, p, 'client', false, 1 / (this.baseScale * this.scale));
      const org = this.resizeTargetTextarea.original;
      const ref = this.resizeTargetTextarea.ref;
      ref.width = this.roundDecimalPlaces(org.width + rect.width);
      ref.height = this.roundDecimalPlaces(org.height + rect.height);
      if(ref.width < 0){
        ref.x = org.x + ref.width;
        ref.width *= -1;
      }
      if(ref.height< 0){
        ref.y = org.y + ref.height;
        ref.height *= -1;
      }
    },
    moveText(p){
      const rect = this.getRect(this.dragInfo.startPoint, p, 'client', false, 1 / (this.baseScale * this.scale));
      const text = this.dragInfo.movingText;
      const startPoint = this.dragInfo.movingTextPoint;
      text.x = startPoint.x + rect.width;
      text.y = startPoint.y + rect.height;
    },
    commitTextRect(){
      if(!this.creatingTextRect){
        return;
      }
      const text = this.cloneObject(this.creatingTextRect);
      const textInfo = this.toolbarInfo.textInfo;
      text.text = '';
      text.textColor = textInfo.color;
      text.textBackColor = textInfo.backColor;
      text.textSize = textInfo.size;
      text.textFont = textInfo.font;
      text.zIndex = this.lastZIndex + 1;
      
      // 小さい矩形サイズの場合は規定値のサイズを適用
      text.width = Math.max(text.width, 200);
      text.height = Math.max(text.height, 100);

      this.texts.push(text);

      // DOMが生成されるの待ってtextareaにフォーカス
      this.$nextTick(()=>{
        this.$refs.textarea[this.$refs.textarea.length-1].readOnly = false;
        this.$refs.textarea[this.$refs.textarea.length-1].focus();
      });

      this.creatingTextRect = null;
    },
    getRect(p1, p2, prop='', normalize=true, rate=1, offsetX=0, offsetY=0){
      const xProp = prop ? `${prop}X` : 'x';
      const yProp = prop ? `${prop}Y` : 'y';
      const pt1 = {
        x: p1[xProp],
        y: p1[yProp],
      };
      const pt2 = {
        x: p2[xProp],
        y: p2[yProp],
      };
      const ret = {
        x: pt1.x,
        y: pt1.y,
        width: pt2.x - pt1.x,
        height: pt2.y - pt1.y,
      }
      if(normalize){
        if(ret.width < 0){
          ret.x = pt1.x + ret.width;
          ret.width *= -1;
        }
        if(ret.height < 0){
          ret.y = pt1.y + ret.height;
          ret.height *= -1;
        }
      }
      ret.x = this.roundDecimalPlaces((ret.x + offsetX) * rate);
      ret.y = this.roundDecimalPlaces((ret.y + offsetY) * rate);
      ret.width = this.roundDecimalPlaces(ret.width * rate);
      ret.height = this.roundDecimalPlaces(ret.height * rate);

      return ret;
    },
    /**
     * 小数点以下の桁数を丸めるメソッド
     * @param {Number} value 
     * @returns 小数点以下の桁数を丸めた数値
     */
     roundDecimalPlaces(value){
      return parseFloat(value.toFixed(3));
    },
    /**
     * 指定座標の書き込みを消す
     * @param {*} p 
     */
    erasePoint(p){
      let elements = [];
      if(this.dragInfo.previousPoint){
        // ポインターを速く動かすと線が消せない場合がるので前回座標がある場合は
        // 前回座標と今回座標の直線上で対象オブジェクトを探す
        // 前回座標と今回座標の距離
        const rect = this.getRect(this.dragInfo.previousPoint, p, 'client');
        const distance = Math.floor(Math.sqrt(Math.pow(rect.width, 2) + Math.pow(rect.height, 2)));
        if(distance > 0){
          // 2点間の中間座標を計算して対象オブジェクトを探す
          for(let i = 0; i <= distance; i++){
            const rate = i / distance;
            const x = rect.x + rect.width * rate;
            const y = rect.y + rect.height * rate;
            elements = elements.concat(document.elementsFromPoint(x, y));
          }
        }
      }else{
        elements = document.elementsFromPoint(p.clientX, p.clientY);
      }
      elements.forEach(elm=>{
        const writeInfo = elm.closest('.write-info');
        if(writeInfo !== this.topElement){
          // 別のコンポーネントのエレメントは削除しない
          return;
        }
        if(elm.classList.contains('write-info_pen-detail-erase')){
          // ペン系の場合は親エレメントを取得する
          elm = elm.parentElement;
        }else if(elm.classList.contains('write-info_text-input-area')){
          // テキストエリアの場合は親エレメントを取得する
          elm = elm.parentElement;
        }else if(elm.classList.contains('write-info_text-instance')){
          ;
        }else if(elm.classList.contains('write-info_stamp-instance')){
          ;
        }else{
          // 上記に合致しない場合は対象外
          return;
        }
        // 対象エレメントのzIndexを取得しzIndexで削除対象を見つける
        const zIndex = parseInt(elm.style.zIndex);
        const eraseFunc = (item, idx, array)=>{
          if(item.zIndex === zIndex){
            array.splice(idx, 1);
            // 履歴保存
            this.saveHistory();
            return true;
          }
          return false;
        };

        this.pens.some(eraseFunc);
        this.texts.some(eraseFunc);
        this.stamps.some(eraseFunc);
      });
    },
    /** テキストエリアクリック時 */
    onTextareaClick(idx){
      // ドラッグ移動していない場合はテキストエリアを編集可能にする
      if (!this.isDragMoved) {
        this.$refs.textarea[idx].readOnly = false;
      }
    },
    /** iPadかどうか */
    isIPad(){
      const ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("ipad") > -1 || (ua.indexOf("macintosh") > -1 && "ontouchend" in document);
    },
    /** テキストエリアフォーカス時 */
    onTextareaFocus(idx){
      this.isEditing = true;
      const currentText = this.texts[idx];
      this.lastFocusTextareaZIndex = currentText.zIndex;
      this.focusTextareaZIndex = currentText.zIndex;
    },
    /** テキストエリアフォーカス喪失時 */
    onTextareaBlur(idx){
      this.isEditing = false;
      this.focusTextareaZIndex = NaN;

      // 入力中のテキスト情報
      const currentText = this.texts[idx];

      // 履歴のテキスト情報
      const historyInfo = this.writeInfo.historyInfo;
      const currentIdex = historyInfo.currentIndex;
      const historyText = historyInfo.histories[currentIdex].texts.find(t=>t.zIndex === currentText.zIndex);

      if(!historyText || currentText.text !== historyText.text){
        // 履歴テキストと異なる場合は履歴保存
        this.saveHistory();
      }
      if (!this.isIPad()) {
        this.$refs.textarea[idx].readOnly = true;
        window.getSelection().removeAllRanges();
      }
    },
    /** 行動履歴に登録する */
    saveHistory(){
      if(this.getWriteInfoSize() > this.limitWriteInfoSize){
        // 書き込みサイズがオーバー
        // 最後の書き込みを巻き戻し、行動履歴の登録はスキップ
        this.loadCurrentHistory();
        // ※警告ポップアップはModalStudentHandedData.vueで表示
        this.$emit("showConfirmForOverWriteInfoSize");
        return;
      }
      if(!this.writeInfo.historyInfo){
        this.writeInfo.historyInfo = {
          currentIndex: -1,
          histories: [],
        }
      }
      const historyInfo = this.writeInfo.historyInfo;
      const history = {
        pens: this.cloneObject(this.pens),
        texts: this.cloneObject(this.texts),
        stamps: this.cloneObject(this.stamps),
      }
      // 現在の表示している行動履歴より後は破棄する
      historyInfo.histories.splice(historyInfo.currentIndex + 1);
      // 現在の書き込み情報を追加
      historyInfo.histories.push(history);

      // 履歴最大保持数チェック(保持手順数+1)
      const MAX_HISTORY = 10 + 1;
      if(historyInfo.histories.length > MAX_HISTORY){
        // 最大数を超える場合は最大数までにするため、先頭から削除
        historyInfo.histories.splice(0, historyInfo.histories.length - MAX_HISTORY);
        // 履歴の保持数を超えたフラグを立てる
        historyInfo.isOverHistory = true;
      }

      // 最後の履歴を現在の履歴にする
      historyInfo.currentIndex = historyInfo.histories.length - 1;
      // 書き込みありチェック
      this.writeInfo.isEdited = historyInfo.isOverHistory || historyInfo.currentIndex !== 0;

      // ツールバー設定
      this.setToolbarUndoRedo();
    },
    /** 行動履歴をアンドゥする */
    undoHistory(){
      if(!this.writeInfo.historyInfo){
        return;
      }

      const historyInfo = this.writeInfo.historyInfo;

      if(historyInfo.currentIndex > 0){
        // 履歴を１つ前に戻す
        historyInfo.currentIndex--;
        // 現在の履歴を画面に反映
        this.loadCurrentHistory();

        // 書き込みありチェック
        this.writeInfo.isEdited = historyInfo.isOverHistory || historyInfo.currentIndex !== 0;

        // ツールバー設定
        this.setToolbarUndoRedo();
      }
    },
    /** 行動履歴をリドゥする */
    redoHistory(){
      if(!this.writeInfo.historyInfo){
        return;
      }

      const historyInfo = this.writeInfo.historyInfo;

      if(historyInfo.currentIndex < historyInfo.histories.length - 1){
        // 履歴を１つ後に進める
        historyInfo.currentIndex++;
        // 現在の履歴を画面に反映
        this.loadCurrentHistory();

        // ツールバー設定
        this.setToolbarUndoRedo();
      }
    },
    /** 現在の行動履歴から画面表示する */
    loadCurrentHistory(){
      const historyInfo = this.writeInfo.historyInfo;
      const writeInfo = historyInfo.histories[historyInfo.currentIndex];
      const pens = this.cloneObject(writeInfo.pens);
      const texts = this.cloneObject(writeInfo.texts);
      const stamps = this.cloneObject(writeInfo.stamps);

      // 画面表示用オブジェクトに書き戻す
      this.pens.splice(0, Number.MAX_SAFE_INTEGER, ...pens);
      this.texts.splice(0, Number.MAX_SAFE_INTEGER, ...texts);
      this.stamps.splice(0, Number.MAX_SAFE_INTEGER, ...stamps);

      // 書き込みありチェック
      this.writeInfo.isEdited = historyInfo.isOverHistory || historyInfo.currentIndex !== 0;
    },
    /** ツールバーのアンドゥリドゥの有効無効を設定 */
    setToolbarUndoRedo(){
      const historyInfo = this.writeInfo.historyInfo;
      const currentIndex = historyInfo.currentIndex;
      const histories = historyInfo.histories;
      const toolbarInfo = this.cloneObject(this.toolbarInfo);
      toolbarInfo.isUndoOff = currentIndex === 0;
      toolbarInfo.isRedoOff = currentIndex === histories.length - 1;
      this.setToolbarInfoOfWriteInfo(toolbarInfo);
    },
    getWriteInfoSize(){
    if(!this.editable){
        return 0;
      }
      if(!this.writeInfoObjectToString){
        return 0;
      }
      const s = this.writeInfoObjectToString(this.writeInfo);
      const size = encodeURI(s).replace(/%[0-9A-F]{2}/g, "*").length;
      console.debug(`writeinfo size:${size}`);
      return size;
    },
    /**
     * 対象オブジェクトのクローン(別インスタンス)を取得する
     * @param {*} value クローン対象
     * @returns クローンインスタンス
     */
    cloneObject(value){
      return JSON.parse(JSON.stringify(value));
    },
  }
}
</script>

<style lang="scss" scoped>
.write-info {
    position: relative;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;

    .write-info_ui-area{
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .write-info_pen-canvas,
    .write-info_text-canvas,
    .write-info_stamp-canvas {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .write-info_pen-canvas,
    .write-info_stamp-canvas {
      pointer-events: none;
    }
    
    .write-info_pen-instance {
      svg {
        top: 0px;
        left: 0px;
        width: 100%; 
        height: 100%;
        position: absolute;
        overflow: visible;

        .write-info_pen-detail{
          pointer-events: none;
        }
        .write-info_pen-detail-erase{
          pointer-events: visibleStroke;
        }
      }
    }

    .write-info_stamp-instance {
      position: absolute;

      // v-html で挿入したタグにスタイルを適用したい場合に
      // scopedがついたスタイルではそのままでは適用されないので
      // ディープセレクタを使用する
      // ※バージョンにより書き方がいろいろあるみたいで
      //   今のバージョンでは::v-deepが機能するようだ
      //   他の書き方では下記のようなものがあるようだ
      //     >>> svg
      //     /deep/ svg
      //     :deep(svg)
      ::v-deep svg {
        width: 100%;
        height: 100%;
      }
    }

    .text-create-rect{
      position: absolute;
      border: solid 1px blue;
      opacity: 0.3;
      // pointer-events: none;
    }
}


.write-info_text-instance {
  position: absolute;

  .write-info_text-input-area {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden auto;
    resize: none;
    padding: 5px;
    line-height: 1.2em;
    border-radius: 0;
    border: 2px solid #808080;
    appearance: none;
    box-sizing: border-box;
    cursor: unset;
    outline: none;
    pointer-events: all;
    font-family: "ＭＳ Ｐゴシック", "MS PGothic", "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", "HiraKakuProN-W3","ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Arial", "Helvetica", "Osaka", "sans-serif";
  }

  .write-info_font_serif {
    font-family: "ＭＳ Ｐ明朝", "MS PMincho", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", "HiraMinProN-W3", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "serif";
  }

  .resize-handle {
    position: absolute;
    user-select: none;
    -webkit-user-drag: none;
    width: 26px;
    height: 26px;
    cursor: nwse-resize;
    pointer-events: all;
  }
}

.peN{
  pointer-events: none;
}

</style>