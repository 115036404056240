<template>
  <div class="button-group-reload">
    <button
      type="button"
      class="btn btn-black btn-sm"
      :style="buttonStyle"
    >
      <IconReload
        width="16px"
        height="16px"
        class="d-inline"
      />
      {{ $t("buttons.refleshGroupList") }}
    </button>
  </div>
</template>

<script>
import IconReload from "@/components/atoms/icons/IconReload.vue"

export default {
  name: "ButtonGroupReload",
  components: {
    IconReload,
  },
  computed: {
    buttonStyle: function () {
      return {
        color: "black",
        border: "solid 1px #000000",
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.button-group-reload {
  display: inline;

  .btn-sm {
    padding: 0rem 0.5rem;
    border-radius: 2rem;
  }
}
</style>