<template>
  <div
    class="modal-student-handed-data"
  >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container-outer">
          <ButtonClose
            class="modal-default-button"
            @click.native="showCheckCloseModal"
          />
          <div
            class="modal-container px-3 py-4"
            style="overflow: auto; max-height: 80vh; width: 75vw"
          >
            <div class="container-fluid">
              <div class="col text-center m-auto show-student-name">
                <h6
                  v-if="showStudentName && studentData[studentIndex]"
                  class="m-0"
                >
                  {{ studentData[studentIndex].accountName }}の提出データ
                </h6>
                <h6
                  v-if="!showStudentName && studentData[studentIndex]"
                  class="m-0"
                >
                  {{
                    studentData[studentIndex].anonymousAccountName
                  }}の提出データ
                </h6>
              </div>

              <div class="col m-auto handed-data-area filing-date">
                <p class="m-0 small">
                  {{
                    $t("labels.handedDateReplaceWords")
                  }}
                  <span style="white-space: nowrap;">
                    {{ !studentData[studentIndex] ? "" : toYMDHMDate(studentData[studentIndex].lastSubmitAddDate) }}
                  </span>
                </p>
              </div>
              <div class="col m-auto handed-data-area display-flag">
                <CheckBox
                  label="生徒名を表示"
                  class="small"
                  :initial-checked="showStudentName"
                  @input="$emit('on-change-show-student-name', $event)"
                />
              </div>
            </div>
            <div class="w-100 modal-contents-area">
              <div
                class="d-flex justify-content-around modal-contents-area-inner"
              >
                <div class="w-25">
                  <!-- 解答状況 -->
                  <div class="col modal-contents-area-left">
                    <ul class="list-inline text-start">
                      <li class="list-inline-item">
                        <IconAnswerStatus
                          width="25px"
                          height="25px"
                        />
                      </li>
                      <li class="list-inline-item">
                        <h6>{{ $t("labels.answerStatus") }}</h6>
                      </li>
                    </ul>
                    <StudentAnswerStatus :list="answerStatusList" />
                  </div>
                </div>
                <!-- 写真 -->
                <div class="modal-right-content">
                  <div class="col modal-right-content-photo">
                    <div class="icon-camera-wrapper">
                      <IconCamera
                        width="25px"
                        height="25px"
                      />
                      <span>{{ $t("labels.note") }}</span>
                    </div>
                    <div class="card photo-area">
                      <div class="p-0 photo-area-wrapper">
                        <template v-for="(d, dIdx) in noteItemDateList">
                          <div
                            v-if="noteItemsByDate[d]"
                            :key="`date-index-${dIdx}`"
                            class="m-0 p-2 ps-3 text-start photo-area-date"
                          >
                            {{ toYMDHMDate(noteItemsByDate[d].lastSubmitAddDate) }} 提出
                          </div>
                          <ul
                            v-if="studentData[studentIndex] && noteItemsByDate[d]"
                            :key="`notes-index-${dIdx}`"
                            class="row row-cols-3 m-0 p-1 photo-area-list"
                          >
                            <template
                              v-for="(note, childIndex) in noteItemsByDate[d].noteItems"
                            >
                              <li
                                v-if="note.showThumbnail"
                                :key="childIndex"
                                class="photo-area-list-item"
                              >
                                <div
                                  class="justify-content-center notebook"
                                >
                                  <div class="notebook-wrapper">
                                    <div class="note-header">
                                      <div
                                        v-if="note.noteType === 1"
                                        class="lesson-no"
                                      >
                                        No.{{ note.lessonNo }}
                                      </div>
                                      <IconEdit
                                        v-if="showIconEdit(note)"
                                        width="15"
                                        height="15"
                                      />
                                    </div>
                                    <div class="note-content">
                                      <HomeworkNoteImage
                                        :content_scale="1"
                                        :img.sync="note"
                                        :teacher-padding="false"
                                        :draggable="false"
                                        :editable="false"
                                        :scale="1"
                                        :config-page-teacher.sync="configPageTeacher"
                                        :cond-teacher.sync="condTeacher"
                                        @click.native="openImageDetail(d, childIndex)"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </template>
                          </ul>
                        </template>
                      </div>
                    </div>
                  </div>
                  <!-- コメント -->
                  <div class="col">
                    <ul class="list-inline text-start">
                      <li class="list-inline-item">
                        <IconComment
                          width="25px"
                          height="25px"
                        />
                      </li>
                      <li class="list-inline-item">
                        <h6>{{ $t("labels.comment") }}</h6>
                      </li>
                    </ul>
                    <ChatArea
                      v-if="studentData[studentIndex]"
                      :stamps="stamps"
                      :teacher-comment="teacherComment"
                      :last-submit-add-date="studentData[studentIndex].lastSubmitAddDate"
                      :haishin-start-date="haishinStartDate"
                      :submit-date="studentData[studentIndex].handedDate + studentData[studentIndex].handedTime"
                      :student-comment="
                        studentData[studentIndex].studentComment
                      "
                      :comments="studentData[studentIndex].comments"
                      :return-comment="studentData[studentIndex].teacherComment"
                      :selected-stamp="stamp"
                      :init-stamp-code="initStampCode"
                      :init-message="initMessage"
                      :student-index="studentIndex"
                      :status="studentData[studentIndex].status"
                      :return-date="studentData[studentIndex].returnDate"
                      :submiting="submiting"
                      v-on="{
                        'on-change-stamp': onChangeStamp,
                        'on-change-message': onChangeMessage,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-end"
                style="margin-top: -12px;"
              >
                <div class="text-end return-annotation-text" v-if="false">
                  {{ $t("labels.requiredV290") }}
                </div>
              </div>
              <div class="row gx-2 justify-content-center mt-4 mb-3 pb-3">
                <div class="modal-student-handed-buttons">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.cancel')"
                    label-color="#ff7f27"
                    border-color="#ff7f27"
                    width="180px"
                    padding="0"
                    @click.native="onClickCancel"
                  />
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.sendBack')"
                    color="send-back"
                    label-color="#ff7f27"
                    border-color="#ff7f27"
                    width="180px"
                    padding="0"
                    @click.native="onClickSendBack"
                  />
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.returnOf')"
                    color="layout-theme-light"
                    label-color="#ff7f27"
                    border-color="#ff7f27"
                    width="180px"
                    padding="0"
                    @click.native="onClickReturnOf"
                  />
                </div>
              </div>
            </div>
            <ul class="prev-area">
              <li
                v-if="isSubmitPrevData(studentIndex)"
                class="prev"
                @click="onClickDataTransferArea(isPrevData)"
              />
            </ul>
            <ul class="next-area">
              <li
                v-if="isSubmitNextData(studentIndex)"
                class="next"
                @click="onClickDataTransferArea(!isPrevData)"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ModalHandedImageDetail
      v-if="showImageDetailModal"
      :student-data="studentData[studentIndex]"
      :selected-note-index="selectedNoteIndex"
      :show-student-name="showStudentName"
      :selected-note-item.sync="noteItemsByDate[selectedNoteSubmitDate]"
      :homework-details.sync="homeworkDetails"
      :config-page-teacher.sync="configPageTeacher"
      :cond-teacher.sync="condTeacher"
      :editable="imageDetailEditable"
      :write-info-object-to-string="writeInfoObjectToString"
      v-on="{
        'selected-node-transfer': transferImageDetailModal,
        showConfirmForOverWriteInfoSize: ()=>showOverWriteSize = true,
      }"
      @close="showImageDetailModal = false"
    />
    <!-- 返却メッセージ用 -->
    <ModalConfirmOkOnly
      v-if="showReturnMessage"
      :message="returnMessage"
      @close-confirm-modal-ok-only="onClickForReturnMessage"
    />
    <!-- やり直しメッセージ用 -->
    <ModalConfirmOkOnly
      v-if="showSendBackMessage"
      :message="sendBackMessage"
      @close-confirm-modal-ok-only="onClickForSendBackMessage"
    />
    <!-- 保存前遷移時用 -->
    <ModalConfirm
      v-if="showCautionCloseMessage"
      :message="$t('messages.confirm.notSavedChanges')"
      v-on="{
        'ok-confirm-modal': onClickOkForCautionCloseMessage,
        'close-confirm-modal': onClickCloseForCautionCloseMessage,
      }"
    />
    <!-- 保存前遷移時用 -->
    <ModalConfirm
      v-if="showCautionTransferMessage"
      :message="$t('messages.confirm.notSavedChanges')"
      v-on="{
        'ok-confirm-modal': onClickOkForCautionTransferMessage,
        'close-confirm-modal': onClickCloseForCautionTransferMessage,
      }"
    />
    <!-- 再提出解除確認用 -->
    <ModalConfirm
      v-if="showCancelSendBackMessage"
      :message="$t('messages.confirm.cancelSendBack')"
      v-on="{
        'ok-confirm-modal': onClickOkForCancelSendBackMessage,
        'close-confirm-modal': (onClickCloseForCancelSendBackMessage),
      }"
    />
    <!-- 書き込みオーバー用警告ダイアログ -->
    <ModalConfirmOkOnly
      v-if="showOverWriteSize"
      :message="$t('messages.error.overWriteInfoSize')"
      @close-confirm-modal-ok-only="()=>showOverWriteSize = false"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex"
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import CheckBox from "@/components/atoms/CheckBox.vue"
import IconAnswerStatus from "@/components/atoms/icons/IconAnswerStatus.vue"
import IconComment from "@/components/atoms/icons/IconComment.vue"
import IconCamera from "@/components/atoms/icons/IconCamera.vue"
import StudentAnswerStatus from "@/components/organisms/StudentAnswerStatus.vue"
import ChatArea from "@/components/organisms/ChatArea.vue"
import ModalHandedImageDetail from "@/components/organisms/modal/ModalHandedImageDetail.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import convertValue from "@/repositories/convert-value"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import { handedStatus, homeworkTypeCode, noteType } from "@/constant/homework"
import HomeworkNoteImage from "@/components/atoms/HomeworkNoteImage.vue"
import IconEdit from "@/components/atoms/icons/IconEdit.vue"

export default {
  name: "ModalStudentHandedData",
  components: {
    ButtonClose,
    IconAnswerStatus,
    IconCamera,
    IconComment,
    StudentAnswerStatus,
    CheckBox,
    ChatArea,
    ButtonBorderCircleMedium,
    ModalHandedImageDetail,
    ModalConfirm,
    ModalConfirmOkOnly,
    HomeworkNoteImage,
    IconEdit,
},
  props: {
    studentData: {
      type: Array,
      default: function () {
        return []
      },
    },
    stamps: {
      type: Array,
      default: function () {
        return []
      },
    },
    studentNum: {
      type: Number,
      default: function () {
        return 0
      },
    },
    submitIndexList: {
      type: Array,
      default: function () {
        return []
      },
    },
    showStudentName: {
      type: Boolean,
    },
    teacherComment: {
      type: String,
      default: function () {
        return ""
      },
    },
    haishinStartDate: {
      type: String,
      default: function () {
        return ""
      },
    },
    homeworkDetails: {
      type: Object,
      default: function () {
        return {}
      },
    },
    quesImageList: {
      type: Array,
      default: function () {
        return {}
      },
    },
    configPageTeacher: {
      type: Object,
      default: function () {
        return {}
      },
    },
    condTeacher: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  data: function () {
    return {
      colorLayoutTheme: "#ff7f27",
      studentIndex: this.studentNum,
      answerStatusList: [],
      noteItemsByDate: [],
      noteItemDateList: [],
      comment: "",
      stampCode: this.stamps.length,
      isPrevData: true,
      selectedNoteIndex: 0,
      selectedNoteSubmitDate: "",
      imageDetailEditable: false,
      showImageDetailModal: false,
      showReturnMessage: false,
      showCancelSendBackMessage: false,
      returnMessage: "",
      showSendBackMessage: false,
      sendBackMessage: "",
      // スタンプコード初期値
      defaultStampCode: this.stamps.length,
      showCautionCloseMessage: false,
      showCautionTransferMessage: false,
      submiting: false,
      showOverWriteSize: false,
    }
  },

  computed: {
    stamp() {
      if (!this.stamps[this.stampCode - 1]) {
        return ""
      }
      return this.stamps[this.stampCode - 1].stamp
    },
    initStampCode() {
      return !this.studentData || this.studentData.length === 0 || !this.studentData[this.studentIndex].stamp
        ? this.stamps[this.defaultStampCode - 1].code
        : this.stamps[this.studentData[this.studentIndex].stamp - 1].code
    },
    initMessage () {
      if (!this.studentData || this.studentData.length === 0) {
        return ""
      }
      return this.getInitMessage()
    },
    lastNoteDate(){
      return Object.keys(this.noteItemsByDate).reduce((a,b)=>a>b?a:b,'');
    },
  },
  watch: {
    showImageDetailModal(newValue, oldValue){
      if(oldValue && !newValue){
        // 拡大画面を閉じた時、先生書き込みをサムネイルに反映させるため描画更新
        this.noteItemsByDate = this.cloneObject(this.noteItemsByDate);
      }
    },
    async studentNum() {
      this.convertToAnswerStatusList()
      this.comment = this.getInitMessage()
      this.stampCode = this.studentData[this.studentIndex].stamp
      await this.resetNote()
    },
    async studentData() {
      if (this.studentData && this.studentData.length > 0) {
        this.convertToAnswerStatusList()
        this.comment = this.getInitMessage()
        this.stampCode = this.studentData[this.studentIndex].stamp
      }
      await this.resetNote()
    }
  },

  async mounted() {
    this.convertToAnswerStatusList()
    this.comment = this.initMessage
    this.stampCode = this.studentData[this.studentIndex].stamp
    await this.resetNote()
  },

  methods: {
    ...mapMutations("homework", [
      "deleteCheckedStudentProgress",
    ]),
    // スタンプを親画面に返す
    onChangeStamp(stampCode) {
      this.stampCode = stampCode
    },
    // コメントを親画面に返す
    onChangeMessage(stampCode, message) {
      this.stampCode = stampCode
      this.comment = message
    },
    // 返却完了メッセージOK押下時
    onClickForReturnMessage() {
      this.submiting = false
      this.showReturnMessage = false
      this.noteItemsByDate = {}
      this.$emit("on-reload")
    },
    // 返却ボタン押下時
    onClickReturnOf(ev, confirmed) {
      if (!confirmed && (this.studentData[this.studentIndex].status == handedStatus.sendBack || this.studentData[this.studentIndex].status == handedStatus.sendBackCheck)) {
        // 再提出指示中の場合は確認ダイアログ表示
        this.showCancelSendBackMessage = true
        return
      }
      this.submiting = true
      this.$emit(
        "on-click-return-of",
        this.studentIndex,
        this.stampCode || this.defaultStampCode, // スタンプコードが設定されていない場合はデフォルト値を渡す
        this.comment,
        this.getNoteWriteInfoList(),
        (result) => {
          if (result) {
            // 返却処理成功時
            if (this.showStudentName) {
              this.returnMessage = this.$t("messages.success.return", {
                studentName: this.studentData[this.studentIndex].accountName,
              })
            } else {
              this.returnMessage = this.$t("messages.success.return", {
                studentName: this.studentData[this.studentIndex].anonymousAccountName,
              })
            }
            this.showReturnMessage = true
          } else {
            // 返却処理失敗時
            this.returnMessage = this.$t("messages.error.exlusiveUpdateHomework")
            this.showReturnMessage = true
          }
        },
      )
      // this.$emit("close")
    },
    // やり直し完了メッセージOK押下時
    onClickForSendBackMessage() {
      this.showSendBackMessage = false
      this.noteItemsByDate = {}
      this.$emit("on-reload")
    },
    // やり直しボタン押下時
    onClickSendBack() {
      // this.showSendBackMessage = !this.showSendBackMessage
      this.$emit(
        "on-click-send-back",
        this.studentIndex,
        this.stampCode || this.defaultStampCode, // スタンプコードが設定されていない場合はデフォルト値を渡す
        this.comment,
        this.getNoteWriteInfoList(),
        (result) => {
          if (result) {
            // やり直し処理成功時
            if (this.showStudentName) {
              this.sendBackMessage = this.$t("messages.success.sendBack", {
                studentName: this.studentData[this.studentIndex].accountName,
              })
            } else {
              this.sendBackMessage = this.$t("messages.success.sendBack", {
                studentName: this.studentData[this.studentIndex].anonymousAccountName,
              })
            }
            // やり直したら進捗確認済みから外す（再提出時にnewになるように）
            this.deleteCheckedStudentProgress({
              homeworkKey: this.studentData[this.studentIndex].homeworkKey,
              studentId: this.studentData[this.studentIndex].accountId,
            })
            this.showSendBackMessage = true
          } else {
            // やり直し処理失敗時
            this.sendBackMessage = this.$t("messages.error.exlusiveUpdateHomework")
            this.showSendBackMessage = true
          }
        },
      )
      // this.$emit("close")
    },
    onClickOkForCancelSendBackMessage(ev) {
      this.onClickReturnOf(ev, true)
    },
    onClickCloseForCancelSendBackMessage() {
      this.showCancelSendBackMessage = false
    },
    openImageDetail(d, childIndex) {
      this.selectedNoteSubmitDate = d
      this.selectedNoteIndex = childIndex
      this.imageDetailEditable = d == this.lastNoteDate
      this.showImageDetailModal = true
    },
    convertToAnswerStatusList() {
      this.answerStatusList = []
      this.studentData[this.studentIndex].results.forEach((results) => {
        let answers = []
        let title = ""
        results.forEach((result) => {
          answers.push(result.result)
          title = result.title
        })
        this.answerStatusList.push({
          homeworkName: title,
          childrenQuestion: answers,
        })
      })
    },
    onClickCancel() {
      this.$emit("close")
    },
    toSlashDate(dateString) {
      return convertValue.toDateStringSeparator(dateString)
    },
    toYMDHMDate(dateString) {
      if (!dateString) {
        return ""
      }
      return convertValue.toDateTimeStringSeparator(dateString)
    },
    isSubmitPrevData(index) {
      return this.submitIndexList.filter((item) => item < index).length > 0
    },
    isSubmitNextData(index) {
      return this.submitIndexList.filter((item) => item > index).length > 0
    },
    onClickDataTransferArea(isPrev) {
      this.isPrev = isPrev
      this.showCheckTransferModal()
    },
    transferImageDetailModal(index) {
      this.selectedNoteIndex = index
    },
    showCheckCloseModal() {
      if (this.isChangeData()) {
        this.showCautionCloseMessage = true
      } else {
        this.$emit("close")
      }
    },
    isChangeComment() {
      return this.initMessage != this.comment
    },
    isChangeData() {
      let isChanged = false;
      if(!isChanged){
        // isChanged
        isChanged = this.isChangeComment();
      }
      if(!isChanged){
        // スタンプチェック
        isChanged = this.stampCode != this.studentData[this.studentIndex].stamp;
      }
      if(!isChanged){
        // 先生書き込み有無
        if(this.lastNoteDate){
          isChanged = this.noteItemsByDate[this.lastNoteDate].noteItems.some(note=>note.writeInfoTeacherObject.writeInfo.isEdited);
        }
      }
      return isChanged;
    },
    onClickOkForCautionCloseMessage() {
      this.showCautionCloseMessage = false
      this.$emit("close")
    },
    onClickCloseForCautionCloseMessage() {
      this.showCautionCloseMessage = false
    },
    showCheckTransferModal() {
      if (this.isChangeData()) {
        this.showCautionTransferMessage = true
      } else {
        if (this.isPrev) {
          this.comment = ""
          this.studentIndex =
            this.submitIndexList[
              this.submitIndexList.indexOf(this.studentIndex) - 1
            ]
        } else {
          this.comment = ""
          this.studentIndex =
            this.submitIndexList[
              this.submitIndexList.indexOf(this.studentIndex) + 1
            ]
        }
        this.$emit("on-click-student-data-transfer", this.studentIndex)
      }
    },
    onClickOkForCautionTransferMessage() {
      this.showCautionTransferMessage = false
      if (this.isPrev) {
        this.comment = ""
        this.studentIndex =
          this.submitIndexList[
            this.submitIndexList.indexOf(this.studentIndex) - 1
          ]
      } else {
        this.comment = ""
        this.studentIndex =
          this.submitIndexList[
            this.submitIndexList.indexOf(this.studentIndex) + 1
          ]
      }
      this.$emit("on-click-student-data-transfer", this.studentIndex)
    },
    onClickCloseForCautionTransferMessage() {
      this.showCautionTransferMessage = false
    },
    getInitMessage() {
      const item = this.studentData[this.studentIndex]
      const status = item.status
      if (status === handedStatus.returned || status === handedStatus.returnChecked) {
        return item.teacherComment
      }
      if (status === handedStatus.sendBack || status === handedStatus.sendBackCheck) {
        if (item.comments.length > 0) {
          return item.comments[item.comments.length - 1].teacher_comment || ""
        }
      }
      return ""
    },
    async resetNote() {
      const noteItemsByDate = {}

      if(this.studentData && this.studentData.length > this.studentIndex){
        for(const note of this.studentData[this.studentIndex].noteItems){
          note.writeInfoStudentObject = null;
          if(note.noteType === noteType.writeInfo){
            // 書き込み情報の場合
            // 問題画像取得
            const quesImg = this.quesImageList.find(quesImg=>{
              if(quesImg.homeworkEdaNo !== note.homeworkEdaNo){
                return false;
              }
              switch(quesImg.kind){
                case homeworkTypeCode.textbook:   return true;
                case homeworkTypeCode.pdf:        return quesImg.pageNo === note.pageNo;
                case homeworkTypeCode.stdbLayout: return quesImg.pageNo === note.pageNo;
                case homeworkTypeCode.stdb:       return quesImg.pageNo === note.pageNo;
                default:  return false;
              }
            });
            if(quesImg){
              // サムネイルに表示するかどうか
              switch(quesImg.kind){
                case homeworkTypeCode.textbook:
                  note.showThumbnail = true;
                  break;
                case homeworkTypeCode.pdf:
                case homeworkTypeCode.stdbLayout:
                case homeworkTypeCode.stdb:
                  note.showThumbnail = note.pageNo === 1;
                  break;
              }
              note.lessonNo = quesImg.lessonNo;
              note.taskName = quesImg.taskName;
              if(quesImg.pageNo > 0){
                note.pageNo = quesImg.pageNo;
                note.maxPage = quesImg.maxPage;
              }
              note.noteFilePath = quesImg.filePath;
              note.writeInfoStudentObject = this.writeInfoStringToObject(note.writeInfoStudent, quesImg.configPage, quesImg.cond);
              note.imageWidth = quesImg.width;
              note.imageHeight = quesImg.height;
            }
          }else{
            // ノート写真の場合
            note.showThumbnail = true;
            const img = await this.loadImage(note.noteFilePath);
            note.imageWidth = img.naturalWidth;
            note.imageHeight = img.naturalHeight;
          }

          note.writeInfoTeacherObject = this.writeInfoStringToObject(note.writeInfoTeacher, this.configPageTeacher, this.condTeacher);

          if (!noteItemsByDate[note.submitDate]) {
            noteItemsByDate[note.submitDate] = {
              lastSubmitAddDate: "",
              noteItems: [],
            }
          }
          var item = noteItemsByDate[note.submitDate];
          if (item.lastSubmitAddDate < note.lastSubmitAddDate) {
            item.lastSubmitAddDate = note.lastSubmitAddDate
          }
          item.noteItems.push(note)
        }
      }
      
      // 日付の降順にソート
      this.noteItemDateList = Object.keys(noteItemsByDate).sort((a, b) => a > b ? -1 : 1)

      this.noteItemsByDate = noteItemsByDate
    },
    /**
     * 画像の読み込み
     * @param {*} src 
     */
    loadImage(src){
      return new Promise((resolve, reject)=>{
        const img = new Image();
        img.onload = ()=>{
          resolve(img);
        };
        img.onerror = e=>reject(e);
        img.src = src;
      });
    },
    /**
     * 書き込み情報(文字列)をオブジェク情報に変換
     * @param {String} value 書き込み情報(文字列) 
     * @param {*} configPage ブックコンフィグ
     * @param {*} cond 書き込み定義
     */
    writeInfoStringToObject(value, configPage, cond) {
      const penTypes = ['path', 'line', 'vartHori', 'rect', 'ellipse'];
      const textEscapeMap = this.getTextEscapeMap();

      const pens = [];
      const texts = [];
      const stamps = [];

      // 返却用オブジェクト
      const returnObj = {
        writeInfo: {
          cond: cond,
          configPage: configPage,
          pens: pens,
          texts: texts,
          stamps: stamps,
        },
        write_info: ''
      }

      if (!value) {
        return returnObj;
      }

      const newWriteList = [];
      // データ圧縮の為、座標情報や大きさの値を小数第3位までにしてテキスト量を圧縮する
      value.split(',').forEach(item => {
        if (!item) {
          return;
        }
        const arr = item.split(':');
        const writeType = arr[0] ? arr[0] : '';
        const writeVal = arr[1] ? arr[1] : '';
        const writeVals = writeVal.split('<>');
        switch (writeType) {
          case 'pen': // ペン
            {
              const penType = penTypes[writeVals[0]];
              const penInfo = {
                type: penType,
                size: parseInt(writeVals[1]),
                color: parseInt(writeVals[2]),
                opacity: parseInt(writeVals[3]),
                zIndex: parseInt(writeVals[4]),
              }
              const paramStartIdx = 5;
              switch (penType) {
                case 'path':    // パス
                  {
                    const svgPath = writeVals[paramStartIdx];
                    const pattern = /( *[MLHVCSQTAZ ])( *[0-9]+(\.[0-9]+)?)( *X *)( *[0-9]+(\.[0-9]+)?)/g;
                    let vals = [];
                    for (const match of svgPath.matchAll(pattern)) {
                      // [0] ヒットした全体
                      // [1] ( *[MLHVCSQTAZ ])の部分
                      // [2] 1つ目の( *[0-9]+(\.[0-9]+)?)の部分  変換対象 桁詰め
                      // [3] [2]内の(\.[0-9]+)?の部分
                      // [4] ( *X *)の部分                      変換対象 X前後のスペースは不要(svgではx,y座標を区切るカンマ)
                      // [5] 2つ目の( *[0-9]+(\.[0-9]+)?)の部分  変換対象 桁詰め
                      // [6] [5]内の(\.[0-9]+)?の部分
                      const rep = match[1] + this.roundDecimalPlaces(match[2]) + 'X' + this.roundDecimalPlaces(match[5]);
                      vals.push(rep);
                    }
                    writeVals[paramStartIdx] = vals.join('');
                    penInfo.path = writeVals[paramStartIdx].replaceAll('X', ',');    // 詳細画面で使用するパスはカンマに戻しておく
                  }
                  break;
                case 'line':        // 直線
                case 'vartHori':    // 水平線、垂直線
                case 'rect':        // 矩形
                case 'ellipse':     // 円、楕円
                  for (let i = 0; i < 4; i++) {
                    writeVals[paramStartIdx + i] = this.roundDecimalPlaces(writeVals[paramStartIdx + i]);
                  }
                  switch (penType) {
                    case 'line':        // 直線
                    case 'vartHori':    // 水平線、垂直線
                      penInfo.x1 = writeVals[paramStartIdx + 0];
                      penInfo.y1 = writeVals[paramStartIdx + 1];
                      penInfo.x2 = writeVals[paramStartIdx + 2];
                      penInfo.y2 = writeVals[paramStartIdx + 3];
                      if (penInfo.x1 === penInfo.x2 && penInfo.y1 === penInfo.y2) {
                        // 直線の開始点と終了点が同じの場合は表示されないので圧縮のためにも除外する
                        return;
                      }
                      break;
                    case 'rect':        // 矩形
                      penInfo.x = writeVals[paramStartIdx + 0];
                      penInfo.y = writeVals[paramStartIdx + 1];
                      penInfo.width = writeVals[paramStartIdx + 2];
                      penInfo.height = writeVals[paramStartIdx + 3];
                      if (penInfo.width === 0 || penInfo.height === 0) {
                        // 矩形の幅、高さがゼロだと表示されないので圧縮のためにも除外する
                        return;
                      }
                      break;
                    case 'ellipse':     // 円、楕円
                      penInfo.cx = writeVals[paramStartIdx + 0];
                      penInfo.cy = writeVals[paramStartIdx + 1];
                      penInfo.rx = writeVals[paramStartIdx + 2];
                      penInfo.ry = writeVals[paramStartIdx + 3];
                      if (penInfo.rx === 0 || penInfo.ry === 0) {
                        // 楕円の半径(x or y)がゼロだと表示されないので圧縮のためにも除外する
                        return;
                      }
                      break;
                  }
                  break;
                default:
                  // 上記以外のペンの種類は対象外
                  return;
              }
              pens.push(penInfo);
            }
            break;
          case 'stamp':
            {
              for (const i of [1, 2]) {
                writeVals[i] = this.roundDecimalPlaces(writeVals[i]);
              }
              const stampInfo = {
                stampIdx: parseInt(writeVals[0]),
                x: writeVals[1],
                y: writeVals[2],
                color: parseInt(writeVals[3]),
                size: parseInt(writeVals[4]),
                zIndex: parseInt(writeVals[5]),
              }
              stamps.push(stampInfo);
            }
            break;
          case 'text':
            {
              for (const i of [4, 5, 6, 7]) {
                writeVals[i] = this.roundDecimalPlaces(writeVals[i]);
              }
              let text = writeVals[8] ? writeVals[8] : '';
              if (text) {
                // エスケープ文字列を元の文字に戻す
                text = text.replaceAll(/=&([^=]+)=/g, (match, p1) => {
                  for(const dic of textEscapeMap){
                    if(p1 === dic[1]){
                      return dic[0];
                    }
                  }
                  return match;
                });
              }
              const textInfo = {
                textColor: parseInt(writeVals[0]),
                textSize: parseInt(writeVals[1]),
                textBackColor: parseInt(writeVals[2]),
                textFont: parseInt(writeVals[3]),
                x: writeVals[4],
                y: writeVals[5],
                width: writeVals[6],
                height: writeVals[7],
                text: text,
                blind: parseInt(writeVals[9]),
                zIndex: parseInt(writeVals[10]),
              }
              texts.push(textInfo);
            }
            break;
          default:
            // タブへの書き込みは対象外
            return;
        }
        newWriteList.push(`${writeType}:${writeVals.join('<>')}`);
      });

      // 提出時に送信する書き込み文字列
      returnObj.write_info = newWriteList.join(',');

      return returnObj;
    },
    /** 返却API用の書き込み情報リストを作成して返す */
    getNoteWriteInfoList(){
      const result =[];

      if(this.lastNoteDate){
        const notes = this.noteItemsByDate[this.lastNoteDate].noteItems;
        if(notes){
          notes.forEach(note=>{
            result.push({
              homework_note_no: note.homeworkNoteNo,
              write_info_teacher: this.writeInfoObjectToString(note.writeInfoTeacherObject.writeInfo),
            });
          });
        }
      }

      return result;
    },
    /**
     * 書き込み情報の表示用オブジェクトをAPI送受信用の文字列に変換する
     * @param {*} writeInfoObject 書き込み情報の表示用オブジェクト
     * @returns API送受信用の文字列
     */
    writeInfoObjectToString(writeInfoObject) {
      if (!writeInfoObject) {
        return '';
      }

      const pens = writeInfoObject.pens;
      const texts = writeInfoObject.texts;
      const stamps = writeInfoObject.stamps;

      const penTypes = ['path', 'line', 'vartHori', 'rect', 'ellipse'];

      let results = [];

      // ペン変換
      pens.forEach(pen => {
        const penParams = [
          penTypes.indexOf(pen.type),
          pen.size,
          pen.color,
          pen.opacity,
          pen.zIndex
        ];

        switch (pen.type) {
          case 'path':
            penParams.push(pen.path.replaceAll(',', 'X'));
            break;
          case 'line':
          case 'vartHori':
            if (pen.x1 !== pen.x2 || pen.y1 !== pen.y2) {
              // 開始点と終了点が異なる場合のみ対象
              penParams.push(
                pen.x1,
                pen.y1,
                pen.x2,
                pen.y2
              );
            }
            break;
          case 'rect':
            if (pen.width > 0 && pen.height > 0) {
              // 縦横の幅がある場合のみ対象
              penParams.push(
                pen.x,
                pen.y,
                pen.width,
                pen.height
              );
            }
            break;
          case 'ellipse':
            if (pen.rx > 0 && pen.ry > 0) {
              // 縦横の幅がある場合のみ対象
              penParams.push(
                pen.cx,
                pen.cy,
                pen.rx,
                pen.ry
              );
            }
            break;
          default:
            return;
        }
        results.push(`pen:${penParams.join('<>')}`);
      });

      // スタンプ変換
      stamps.forEach(stamp => {
        const stampParams = [
          stamp.stampIdx,
          stamp.x,
          stamp.y,
          stamp.size,
          stamp.zIndex,
        ];
        results.push(`stamp:${stampParams.join('<>')}`);
      });

      // テキスト変換
      const textEscapeeMap = this.getTextEscapeMap()
      texts.forEach(text => {
        if(text.text === ''){
          // テキスト文字なしはスキップ
          return;
        }
        if(text.width === 0 || text.height === 0){
          // 大きさなしはスキップ
          return;
        }
        // テキスト文字のエスケープ処理
        let txt = text.text;
        textEscapeeMap.forEach(dic => {
          txt = txt.replaceAll(dic[0], `=&${dic[1]}=`);
        });
        const textParams = [
          text.textColor,
          text.textSize,
          text.textBackColor,
          text.textFont,
          text.x,
          text.y,
          text.width,
          text.height,
          txt,
          0,
          text.zIndex,
        ];
        results.push(`text:${textParams.join('<>')}`);
      });

      return results.join(',');
    },
    /** テキストの置換対象文字列定義 */
    getTextEscapeMap() {
      // [0]:置き換え対象文字
      // [1]:置き換え後エスケープ文字 実際は =& をプレフィクス = をサフィクスとしてはさむ
      //     例 abc,def -> abc=&comm=def
      // プレフィクスおよびサフィクスで使用している文字列をエスケープしていないので
      // テキストにエスケープ後文字列を入力すると次回表示時にエスケープ解除してしまう
      //   画面入力で abc=&lt=defと入力すると 次回表示時に abc<def と表示する
      return [
        [',', 'comm'],
        ['<', 'lt;'],
        ['>', 'gt;'],
        [':', 'colo'],
        ["'", 'squt'],
        ['"', 'dqut'],
        ['\\n', 'yen'],
        ['\n', 'ent'],
        ['\\', 'sl']
      ];
    },
    /**
     * 小数点以下3桁にした数値を返す
     * @param {*} value 
     */
    roundDecimalPlaces(value) {
      return parseFloat(parseFloat(value).toFixed(3));
    },
    /**
     * 対象オブジェクトのクローン(別インスタンス)を取得する
     * @param {*} value クローン対象
     * @returns クローンインスタンス
     */
     cloneObject(value){
      return JSON.parse(JSON.stringify(value));
    },
    showIconEdit(note){
      if(note.noteType === noteType.picture){
        return this.isWriteInfoTeacher(note);
      } else {
        return this.noteItemsByDate[note.submitDate].noteItems.some(note2=>note2.homeworkEdaNo === note.homeworkEdaNo && this.isWriteInfoTeacher(note2));
      }
    },
    isWriteInfoTeacher(note){
      if(note.writeInfoTeacherObject){
        const writeInfo = note.writeInfoTeacherObject.writeInfo;
        if(writeInfo){
          if(writeInfo.pens && writeInfo.pens.length > 0){
            return true;
          }
          if(writeInfo.texts && writeInfo.texts.length > 0){
            return true;
          }
          if(writeInfo.stamps && writeInfo.stamps.length > 0){
            return true;
          }
        }
      }
      return false;
    },
  },
}

</script>

<style lang="scss" scoped>
ul {
  li {
    list-style: none;
    padding: 0.4em;
  }
  margin: 0 auto;
}
.modal-mask {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      .modal-default-button {
        width: 14%;
        margin-left: auto;
      }

      .modal-container {
        // md
        @media (max-width: 768px) {
          height: 84vh;
        }
        @media (min-width: 1020px) {
          max-width: 75%;
        }
        @media (min-height: 1080px) {
          height: 73vh;
        }
        // lg
        position: relative;
        max-width: 85%;
        height: 88vh;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 30px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        text-align: center;
        .modal-right-content {
          width: 60%;
          height: 100%;
          .modal-right-content-photo {
            height: 320px;
          }
        }
        .modal-contents-area {
          @media (min-width: 1280px) {
            max-width: 90%;
            margin: 0 auto;
          }
          height: 90%;
          .modal-contents-area-left {
            height: 655px;
          }
        }
        .modal-contents-area::-webkit-scrollbar {
          display: none;
        }
      }
      .return-annotation-text {
        font-size: 70%;
        padding: 0 4% 4px 4%;
      }

      .container-fluid {
        @media (min-width: 1280px) {
          max-width: 90%;
        }
        position: relative;
        margin-bottom: 2rem;
        ::v-deep .btn {
          padding: 0.3em 1em 0.3em;
          font-size: 14px;
        }

        .show-student-name {
          width: 70%;
          @media (min-width: 1020px) {
            width: 60%;
          }
        }

        .check-everyones-handed-image-area {
          position: absolute;
          top: -6px;
          left: 0;
        }

        .handed-data-area {
          position: absolute;
          width: 16vw;
          top: 0;
        }
        .filing-date {
          @media (max-width: 767px) {
            width: 23vw;
            left: -28px;
          }
          @media (min-width: 768px) {
            left: 0px;
          }
          @media (min-width: 1020px) {
            left: 0;
          }
        }
        .display-flag {
          @media (max-width: 767px) {
            right: -15px;
          }
          @media (min-width: 768px) {
            right: -15px;
          }
          @media (min-width: 1020px) {
            right: 0;
          }
        }
      }

      .photo-area-list {
        // background-color: #ccc;
      }

      .notebook {
        height: 100%;
        .notebook-wrapper {
          height: 100%;

          div {
            height: 100%;
            ::v-deep .image {
              display: flex;
              height: 100%;
            }
            ::v-deep img {
              display: block;
              width: 100%;
              height: auto;
              padding: 0;
              margin: auto;
            }
          }

          .note-header {
            width: 100%;
            height: 24px;
            padding: 0;
            margin: 0;
            display: table;
            .lesson-no {
              display: table-cell;
              text-align: left;
              padding: 0;
              margin: 0;
              width: auto;
              height: 100%;
              font-weight: bold;
            }

            .icon-edit {
              display: table-cell;
              text-align: right;
              padding: 0;
              margin: 0;
              width: 15px;
              height: 15px;
            }
          }

          .note-content {
            width: 100%;
            height: calc(100% - 24px);
            border: 1px solid #ccc;
          }
        }
      }
      //ヘッダー以外のボタンスタイル
      ::v-deep .btn {
        padding: 0.5em 3em 0.5em;
        font-size: 14px;
      }
      .next-area {
        position: fixed;
        top: 50%;
        right: 14%;
      }
      .next {
        display: block;
        padding: 30px;
        cursor: pointer;
        width: 3%;
        height: auto;
        transform: translateY(-50%);

        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          background: #0070c0;
          width: 20px;
          height: 4px;
          transform: rotate(-45deg);
          top: 50%;
          right: 35%;
        }
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          background: #0070c0;
          width: 20px;
          height: 4px;

          transform: rotate(45deg);
          top: 30%;
          right: 35%;
        }
      }
      .prev-area {
        position: fixed;
        top: 50%;
        left: 10.5%;
      }
      .prev {
        display: block;
        padding: 30px;
        cursor: pointer;
        width: 3%;
        height: auto;
        transform: translateY(-50%);

        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          background: #0070c0;
          width: 20px;
          height: 4px;
          transform: rotate(-45deg);
          top: 30%;
          left: 35%;
        }
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          background: #0070c0;
          width: 20px;
          height: 4px;
          transform: rotate(45deg);
          top: 50%;
          left: 35%;
        }
      }
    }
  }
}
.icon-camera-wrapper {
  display: flex;
  margin: 0.4rem 0 0.6rem;
  span {
    margin: 0.1rem 0 0 1rem;
    font-weight: bold;
  }
}
.photo-area {
  height: 85%;
  overflow-y: scroll;
  padding: 0;
  &-wrapper {
    height: 100%;
  }
  &-list {
    // height: 100%;
    &-item {
      height: 10vw;
    }
  }
  &-date {
    background: #eee;
    display: flex;
    align-items: center;
  }
}

.modal-student-handed-buttons {
  display: flex;
  justify-content: center;
  .button-border-circle-medium {
    margin: 0 16px;
  }
  .button-border-circle-medium:first-child {
    margin-right: 60px;
  }
}

// トランジション（アニメーション）設定
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: opacity 0.8s;
}
</style>
<style>
.setting-preview-image {
  padding: 0%;
}
</style>
