<template>
  <div class="sidebar">
    <SideMenu :size="size" />
  </div>
</template>

<script>
import SideMenu from "@/components/molecules/common/SideMenu.vue"

export default {
  name: "Sidebar",
  components: {
    SideMenu,
  },
  props: {
    size: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  data: () => ({
    //
  }),
}
</script>
