/**
 * axios インスタンス
 */
import axios from "axios"
import vm from "@/main"
import { apiResponseCode } from "../constant/network"

// エラーハンドラ
function errorHandler(error) {
  // 共通で504エラーが発生した際に500エラーのページに遷移する
  if (error.code === "ECONNABORTED") {
    vm.$router.push({
      name: "APIError",
      params: { status: apiResponseCode.timeout },
    })
  } else {
    throw error.response
  }
}

/**
 * アカウント基盤サーバ API インスタンス
 */
const axiosAccountBase = axios.create({
  baseURL: process.env.VUE_APP_ACCOUNT_API_BASE_URL,
  timeout: 30000,
})

axiosAccountBase.interceptors.response.use(function (response) {
  if (response.data.status !== apiResponseCode.ok) {
    // HTTP Status を強制的に本来のコードに戻す
    // コンポーネントに catch 句があればエラーとなる
    response.status = response.data.status
  }
  return response
}, errorHandler)

/**
 * 配信サーバ API インスタンス
 * Bearer 認証のため、カスタムヘッダーを定義して通信する
 */
const axiosStreamBase = axios.create({
  baseURL: process.env.VUE_APP_STREAM_API_BASE_URL,
  // 配信サーバでは true にする必要がある
  withCredentials: true,
  timeout: 30000,
})

axiosStreamBase.interceptors.response.use(function (response) {
  return response
}, errorHandler)

/**
 * 配信サーバ API インスタンス
 * WithCredentialsを除外したリクエスト
 */
const axiosStreamBaseForExclusionCredentials = axios.create({
  baseURL: process.env.VUE_APP_STREAM_API_BASE_URL,
  timeout: 30000,
})

axiosStreamBaseForExclusionCredentials.interceptors.response.use(function (
  response
) {
  return response
},
errorHandler)

/**
 * 配信サーバ（LMS 用） API インスタンス
 */
const axiosStreamLmsApiBase = axios.create({
  baseURL: process.env.VUE_APP_STREAM_LMS_API_BASE_URL,
  withCredentials: true,
  timeout: 30000,
})

axiosStreamLmsApiBase.interceptors.response.use(function (response) {
  return response
}, errorHandler)

/**
 * LMS サーバ API インスタンス
 */
const axiosLmsBase = axios.create({
  baseURL: process.env.VUE_APP_LMS_API_BASE_URL,
  timeout: 30000,
})

axiosLmsBase.interceptors.response.use(function (response) {
  return response
}, errorHandler)

/**
 * S-Viewer API インスタンス
 */
const axiosSviewerBase = axios.create({
  baseURL: process.env.VUE_APP_SVIEWER_BASE_URL,
  withCredentials: true,
  timeout: 30000,
})

axiosSviewerBase.interceptors.response.use(function (response) {
  return response
}, errorHandler)

/**
 * コンテンツ URL インスタンス
 */
const axiosAwsS3Base = axios.create({
  baseURL: process.env.VUE_APP_LMS_AWS_S3_BASE_URL,
})

axiosAwsS3Base.interceptors.response.use(function (response) {
  return response
}, errorHandler)

/**
 * 教材連携 URL インスタンス
 */
const axiosAwsS3LinkTextbookBase = axios.create({
  baseURL: process.env.VUE_APP_LMS_COOPERATION_AWS_S3_BASE_URL,
})

axiosAwsS3LinkTextbookBase.interceptors.response.use(function (response) {
  return response
}, errorHandler)

export default {
  axiosAccountBase,
  axiosStreamBase,
  axiosStreamBaseForExclusionCredentials,
  axiosStreamLmsApiBase,
  axiosLmsBase,
  axiosSviewerBase,
  axiosAwsS3Base,
  axiosAwsS3LinkTextbookBase,
}
